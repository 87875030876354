#vehicle-details .form-note {
    margin-top: 40px;
    line-height: 25px;
}
#vehicle-details .form-note.custom-form-note-1.with-remarks {
    margin: 30px 0;
}
#vehicle-details .form-note.custom-form-note-1:not(.with-remarks) {
    margin-top: 30px;
}
#vehicle-details-form .form-loader .form-loader-container { 
    margin-bottom: 90px;
}

@media screen and (max-width: 830px) {
	#vehicle-details-form .form-loader .form-loader-container { 
        margin: 75px 0 65px;
    }
}

@media screen and (max-width: 426px) {
    #vehicle-details .form-note {
        margin-top: 30px;
    }
}

#orcr-overlay {
    min-width: 400px;
    height: auto;
    min-height: 400px;
    width: auto;
}