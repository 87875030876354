#sub-otp #sub-otp-form .form-fields {
    margin-bottom: 20px;
}
#sub-otp #sub-otp-form .form-fields > h2 {
    margin-bottom: 10px;
}
#sub-otp #sub-otp-form .form-fields > p {
    margin-bottom: 30px;
    line-height: 22px;
    text-align: justify;
}
#sub-otp #sub-otp-form .form-fields .trouble {
    margin: 10px 0 0;
}
#sub-otp #sub-otp-form .form-fields .trouble a {
    cursor: pointer;
    color: #b11116;
    transition: color ease .3s;
}
#sub-otp #sub-otp-form .form-fields .trouble a:hover {
    color: #1B1464;
}
#sub-otp #sub-otp-form .form-fields .otp-options {
    min-height: 115px;
}
#sub-otp #sub-otp-form .form-fields .otp-options .option {
    display: flex;
    cursor: pointer;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 3px;
    padding: 17px 15px;
    margin-bottom: 10px;
    transition: background-color ease .3s;
}
#sub-otp #sub-otp-form .form-fields .otp-options .option:hover {
    background-color: #e5e5e5;
}
#sub-otp #sub-otp-form .form-fields .otp-options .option p {
    min-width: 55px;
}
#sub-otp #sub-otp-form .col-case {
    display: flex;
    justify-content: center;
    text-align: center;
}
#sub-otp .otpFormMsg {
    min-height: 40px;
    align-items: center;
    display: flex;
    color: #b11116;
    font-size: 14px;
    justify-content: center;
    line-height: 18px;
    text-align: unset;
}
#sub-otp .form-btns {
    text-align: center;
    margin-top: 20px;
}
#sub-otp .form-btns button:nth-of-type(1) {
    width: 48% !important;
    max-width: unset !important;
}
#sub-otp .form-btns button:nth-of-type(2) {
    margin-right: 0;
    margin-left: 3px;
    width: 48% !important;
    max-width: unset !important;
}
#sub-otp .form-btns button:nth-of-type(3) {
    margin-top: 10px;
    margin-left: 0;
    width: 100% !important;
    max-width: unset !important;
}
#sub-otp .trustBrowserQ {
    font-size: 17px;
}
#sub-otp .trustBrowserM {
    margin-bottom: 95px;
}
#sub-otp .verify-btns {
    display: flex;
    flex-wrap: wrap;
}

@media screen and (max-width: 325px) {
    #sub-otp .verify-btns {
        flex-direction: column;
    }
    #sub-otp .form-btns button:first-of-type,
    #sub-otp .form-btns button:nth-of-type(2) {
        margin-right: 0 !important;
    }
    #sub-otp .form-btns button:first-of-type,
    #sub-otp .form-btns button:nth-of-type(2) {
        width: 100% !important;
    }
    #sub-otp .form-btns button:nth-of-type(2) {
        margin-top: 13px !important;
    }
}