#login .forgot-password {
    font-size: 13px;
    margin-top: 12px;
}
#login .forgot-password a {
    color: #b11116;
    text-decoration: none;
    font-weight: 600;
}
#login #sub-login .form-btns {
    display: flex;
    justify-content: right;
    align-items: center;
}
#login #sub-login .mat-checkbox .mat-checkbox-inner-container {
    margin-right: 10px;
}
#login #sub-login .form-btns .btn,
#login .registration-btn .btn {
    min-width: 120px;
    width: 120px;
}
#login .registration-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
}
#login .registration-btn .note {
    font-size: 14px;
    width: 140px;
    line-height: 18px;
}
#login .registration-btn .btn {
    border: 2px solid #1b1464;
    color: #1b1464;
    font-family: "ProximaNova", "Arial", "Calibri", sans-serif;
}
#login .registration-btn .btn:hover {
    background-color: #f0f0f0;
}

@media screen and (max-width: 426px) {
    #login .registration-btn .btn:hover {
        background-color: #fff;
    }
}

@media screen and (max-width: 366px) {
    #login #sub-login .mat-checkbox .mat-checkbox-inner-container {
        margin-right: 8px;
    }
    #login #sub-login .mat-checkbox {
        font-size: 13px;
    }
}

@media screen and (max-width: 350px) {
    #login .registration-btn .note {
        width: 110px;
    }
}