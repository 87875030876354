#login-register {
    padding: 60px 0;
    box-sizing: border-box;
}
#login-register form h2 {
    color: #1B1464;
}
#login-register:not(.solo) {
    padding-top: 0;
}
#login-register .container {
    overflow: hidden;
    position: relative;
}
#login-register .container.solo {
    display: flex;
    justify-content: center;
}
#login-register .case {
    float: left;
    max-width: 464px;
    width: 49.2%;
    margin-right: 1.6%;
    border-radius: 5px;
    padding: 30px 47px;
    box-sizing: border-box;
    position: relative;
    border: 1px solid #E0E0E0;
    border-top: none;
    min-height: 407px;
    display: flex;
    justify-content: center;
    align-items: center;
}
#login-register .container.solo .case {
    background-color: #fff;
}
#login-register .container.solo .case .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: #F2F2F2;
}
#login-register .hide {
    display: none !important;
}
#login-register .container:not(.solo) .buttons.hide ~ .case .form-loader-container {
    margin-top: -10px;
}
registration,
login {
    width: 100%;
}
#login-register .solo .case {
    margin-right: 0;
    max-width: unset;
    width: 100%;
    max-width: 400px;
}
#login-register .case:nth-child(odd) {
    margin-right: 0;
}
#login-register .case h2 {   
    font-size: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 28px;
    margin: 10px 0 25px;
}
#login-register .case .form-btns .btn {
    padding: 14px 10px;
    font-size: 16px;
    width: 43%;
    min-width: 110px;
    max-width: 144px;
    font-weight: 500;
    letter-spacing: 1px;
    -webkit-appearance: none;
    border: 0;
}
#login-register .case.login-case .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: #F2F2F2;
}
#login-register .form {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
}
#login-register .form-btns {
    margin: 30px 0 10px;
    text-align: center;
}
#login-register .form .col-case {
    margin-bottom: 16px;
}
#login-register .form .col-case:last-of-type {
    margin-bottom: 0;
}
#login-register .form .col-case .field {
    width: 100%;
}
#login-register .container .buttons {
    display: none;
}
#login-register .container .buttons.hide {
    display: none !important;
}
#login-register .container .buttons button:focus {
    outline: none;
}
#login-register .form .col-case .middle {
    width: 75%;
}
#login-register .form .col-case .suffix {
    width: 20%;
    padding-left: 3%;
}
@media screen and (max-width: 874px) {
    #login-register:not(.solo) {
        margin-top: 48px;
    }
}

@media screen and (max-width: 840px) {
    #login-register:not(.solo) .case h2 {
        text-align: center;
    }
}

@media screen and (max-width: 796px) {
    #login-register:not(.solo) .case {
        padding: 30px 15px;
    }
}

@media screen and (max-width: 680px) {
    #login-register .container:not(.solo) {
        display: flex;
        justify-content: center;
    }
    #login-register:not(.solo) .case {
        margin-right: 0;
        width: 100%;
        max-width: unset;
        padding: 110px 45px 35px;
        background-color: #fff;
        display: none;
    }
    #login-register:not(.solo) .case.show {
        display: block;
    }
    #login-register:not(.solo) .buttons {
        display: flex;
        justify-content: space-evenly;
        position: absolute;
        top: 35px;
        width: 88%;
        height: 40px;
        z-index: 1;
        transition: all ease .3s;
    }
    #login-register:not(.solo) .buttons button {
        width: 45%;
        border-radius: 5px;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        font-family: "ProximaNova", "Arial", "Calibri", sans-serif;
    }
    #login-register:not(.solo) .buttons button#btn-register {
        background-color: #1b1464;
        border: 1px solid #1b1464;
    }
    #login-register:not(.solo) .buttons button#btn-login {
        background-color: #b11114;
        border: 1px solid #b11114;
    }
    #login-register:not(.solo) .case.reg-case .mat-form-field-appearance-fill .mat-form-field-flex {
        background-color: #F2F2F2;
    }
    #login-register:not(.solo) .case h2 {
        font-size: 22px;
    }
}

@media screen and (max-width: 520px) {
    #login-register:not(.solo) .case {
        padding: 110px 30px 35px !important;
    }
}

@media screen and (max-width: 456px) {
    #login-register .case {
        padding: 30px 25px;
    }
}

@media screen and (max-width: 376px) {
    #login-register:not(.solo) .case {
        padding: 110px 20px 35px !important;
    }
    #login-register:not(.solo) .buttons {
        width: 95%;
    }
}