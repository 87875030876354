#stepper-dtl {
    padding: 48px 0;
}
#stepper-dtl.plan-form {
    padding: 48px 0 18px;
}
#stepper-dtl .container {
    display: flex;
    justify-content: space-between;
}
#stepper-dtl h2 {
    display: flex;
    align-items: center;
    margin-right: 30px;
    min-height: 47.33px;
}
#stepper-dtl h2 .step-num {
    background: #1B1464;
    min-height: 32px;
    min-width: 32px;
    padding: 4px 2px;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    font-size: 20px;
    box-sizing: border-box;
    margin-right: 20px;
}
#stepper-dtl h2 .step-title {
    letter-spacing: 0.04em;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 600;
    padding: 2px 0 1px;
}
#stepper-dtl .btn-wrap {
    display: flex;
    align-items: center;
}
#stepper-dtl .btn-wrap .btn:first-of-type {
    margin-left: 0;
}
#stepper-dtl .btn-wrap .btn:last-of-type {
    margin-right: 0;
}

@media screen and (max-width: 875px) {
    #stepper-dtl {
        margin-bottom: 25px;
        padding-bottom: 0;
    }
    #stepper-dtl.plan-form {
        margin-bottom: 0;
    }
    #stepper-dtl .container {
        display: block;
    }
    #stepper-dtl .btn-wrap {
        justify-content: center;
        align-items: unset;
        padding: 40px 0;
        position: relative;
    }
    #stepper-dtl .btn-wrap::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        z-index: -1;
    }
}

@media screen and (max-width: 686px) {
    #stepper-dtl.summary .btn-wrap {
        flex-wrap: wrap;
    }
    #stepper-dtl.summary .btn-wrap button {
        width: 48%;
        margin-right: 3%;
        margin-left: 0;
    }
    #stepper-dtl.summary .btn-wrap button:nth-of-type(even) {
        margin-right: 0;
    }
    #stepper-dtl.summary .btn-wrap button:nth-of-type(3),
    #stepper-dtl.summary .btn-wrap button:nth-of-type(4) {
        margin-top: 20px;
    }
    #stepper-dtl.summary .btn-wrap.odd-btn button:nth-of-type(3) {
        width: 100%;
        margin-right: 0;
    }
}

@media screen and (max-width: 640px) {
    #stepper-dtl .btn-wrap {
        padding-bottom: 30px;
    }
    #stepper-dtl h2 .step-num {
        min-height: 30px;
        min-width: 30px;
        font-size: 18px;
    }
    #stepper-dtl .btn-wrap .btn {
        min-width: 120px;
        padding: 11px 11px;
        font-size: 15px;
    }
}

@media screen and (max-width: 476px) {
    #stepper-dtl {
        padding: 35px 0 0;
    }
    #stepper-dtl .btn-wrap {
        flex-wrap: wrap;
        padding: 25px 0;
    }
    #stepper-dtl .btn-wrap button {
        width: 100%;
        margin-top: 13px !important;
        margin-left: 0 !important;
        padding: 13px 11px !important;
    }
    #stepper-dtl .btn-wrap button:nth-of-type(1),
    #stepper-dtl .btn-wrap button:nth-of-type(2) {
        width: 48%;
        margin-right: 4%;
        margin-top: 0;
    }
    #stepper-dtl .btn-wrap button:nth-of-type(2) {
        margin-right: 0;
    }
}