#message-box > #overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9998;
    background-color: rgba(0, 0, 0, .5);
}
#message-box > #content {
    background-color: #f8f8f8;
    border-radius: 5px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    max-width: 500px;
    width: 93%;
}
#message-box > #content > h2 {
    background-color: #b11116;
    color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 8px 10px;
    margin-bottom: 15px;
}
#message-box > #content > .message {
    padding: 0 10px;
    line-height: 20px;
}
#message-box > #content > .btns {
    padding: 25px 15px 15px;
    text-align: right;
}
#message-box > #content > .btns button {
    min-width: 100px;
    padding: 7px;
    font-weight: normal;
    font-size: 15px;
    margin-right: 12px;
}
#message-box > #content > .btns button:last-of-type {
    margin-right: 0;
}

@media screen and (max-width: 426px) {
    #message-box > #content > .btns {
        display: flex;
        flex-direction: column;
    }
    #message-box > #content > .btns button {
        margin-right: 0;
        margin-bottom: 10px;
    }
    #message-box > #content > .btns button:last-of-type {
        margin-bottom: 0;
    }
}
