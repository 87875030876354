#profile .name .field:nth-of-type(3) {
    width: 19.5%;
    margin-right: 2.3%;
}
#profile .name .field:nth-of-type(4) {
    width: 10%;
}

@media screen and (max-width: 767px) {
    #profile .name .field:nth-of-type(3) {
        width: 49%;
        margin-right: 2%;
    }
    #profile .name .field:nth-of-type(4) {
        width: 49%;
    }
}

@media screen and (max-width: 480px) {
    #profile .name .field:nth-of-type(3) {
        width: 66%;
        margin-bottom: 0 !important;
        margin-right: 4% !important;
    }
    #profile .name .field:nth-of-type(4) {
        width: 30%;
    }
}

@media screen and (max-width: 426px) {
    #profile .chk-same-address {
        margin-bottom: 7%;
    }
}