#property-assessment .intro-big-title {
    font-weight: 600;
    font-size: 24px;
    text-transform: uppercase;
    color: #1b1464;
    margin: 30px 0 30px;
    letter-spacing: 1px;
    transition: all ease 0.3s;
}
#property-assessment .intro-box {
    position: relative;
    display: flex;
    background-color: #fff;
    box-shadow: 0px 1px 2px rgba(27, 20, 100, 0.24);
    border-radius: 3px;
    margin-bottom: 25px;
    overflow: hidden;
    justify-content: flex-end;
}
#property-assessment .intro-pic {
    position: absolute;
    width: 435px;
    height: 100%;
    top: 0;
    left: 0;
}
#property-assessment .intro-pic img {
    /* height: 146%; */
    width: 100%;
    position: relative;
}
/* #property-assessment .intro-pic .image.imageNo1,
#property-assessment .intro-pic .image.imageNo2,
#property-assessment .intro-pic .image.imageNo3,
#property-assessment .intro-pic .image.imageNo4 {
    max-height: 539px;
} */
#property-assessment .intro-content {
    box-sizing: border-box;
    padding: 12px 35px;
    width: 510px;
    background-color: #fff;
    z-index: 1;
    min-height: 450px;
}
#property-assessment .intro-content .main-question {
    font-weight: 600;
    font-size: 20px;
    color: #000;
    position: relative;
    box-sizing: border-box;
    padding: 16px 0 17px;
}
#property-assessment .intro-content .not-insurable {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
}
#property-assessment .intro-content .not-insurable > p {
    font-weight: 600;
    font-size: 20px;
    color: #000;
    text-align: center;
    vertical-align: middle;
    /*margin: auto; */
}
#property-assessment .intro-content .supp-doc-question {
    font-weight: 600;
    font-size: 20px;
    color: #000;
    position: relative;
    box-sizing: border-box;
    padding: 10px 0 1px;
    margin-bottom: 1px;
}
#property-assessment .intro-content .supp-doc-question2 {
    font-weight: 600;
    font-size: 16px;
    color: #000;
    position: relative;
    box-sizing: border-box;
    padding: 2px 0;
}
#property-assessment .intro-content .intro-text {
    overflow-y: auto;
    text-align: justify;
}
#property-assessment .prop-note {
    border: 2px solid #d5b527;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 12px 8px;
    margin-bottom: 10px 10px;
}
#property-assessment .prop-note p {
    text-align: left;
    padding: 8px 5px;
}
#property-assessment .prop-note .sentence1 {
    padding-bottom: 35px;
}
#property-assessment .prop-note .sentence2 {
    padding-top: 35px;
    font-size: 14px;
}
#property-assessment .form-btns mat-checkbox {
    font-family: "ProximaNova", "Arial", "Calibri", sans-serif;
    margin-right: 40px;
}

#property-assessment .checkbox.hazard-hunter .mat-checkbox-inner-container {
    margin-top: 1%;
}
#property-assessment .form {
    padding-bottom: 0px;
    padding-top: 5px;
    vertical-align: top;
    border-bottom: 0px;
}
#property-assessment .form .field {
    padding-top: 10px;
}

#property-assessment
    .mat-form-field-appearance-fill.mat-form-field-disabled
    .mat-form-field-flex {
    background-color: rgba(0, 0, 0, 0.1);
}

#property-assessment .mat-form-field-appearance-fill .mat-form-field-flex {
    background: rgba(0, 0, 0, 0.01);
}

#property-assessment .mat-form-field-wrapper > .mat-form-field-flex {
    border: 2px solid #eee;
}

#property-assessment .form-note {
    border: 2px solid #d5b527;
    box-sizing: border-box;
    border-radius: 24px;
    padding: 10px 20px;
    margin-top: 20px;
}

#property-assessment .form-note p {
    text-align: justify;
}

/* #property-assessment .form .field mat-form-field:disabled {
    background-color: #e4e4e4 !important;
    color: #414344;
    border: 10px 0px solid #e4e4e4 !important;
}*/
#property-assessment .form .field.question5 {
    padding-top: 10px;
    padding-bottom: 20px;
}
#property-assessment .form .radio-btn-slot {
    display: inline-block !important;
    width: 46%;
    /*border: 2px solid #D5B527;
    border-radius: 10px;*/
    box-sizing: border-box;
    word-wrap: break-word;
    margin-left: 2%;
    margin-right: 2%;
}
#property-assessment .form .radio-btn-slot .radio-lbl {
    display: block;
    word-wrap: break-word;
    white-space: normal;
    line-height: 1.5;
}
#property-assessment .form .col-case .checkbox {
    text-align: justify;
}
#property-assessment .radio-button.wall,
#property-assessment .radio-button.roof {
    display: block;
}
#property-assessment .radio-button .radio-lbl-wall,
#property-assessment .radio-button .radio-lbl-roof {
    font-weight: 400;
    white-space: normal;
}
#property-assessment .province {
    width: 100%;
    display: inline-block !important;
}
#property-assessment .city {
    width: 100%;
    display: inline-block !important;
    /*padding-left: 2%;*/
}
#property-assessment .num-year {
    width: 45%;
}
#property-assessment .supporting-docs {
    padding-bottom: 50px;
}
#property-assessment .btn-avail {
    height: 44px;
    min-width: 120px;
    padding: 11px;
    margin-left: 24%;
}
#property-assessment input[type="number"].noArrow::-webkit-outer-spin-button,
#property-assessment input[type="number"].noArrow::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
#property-assessment input[type="number"].noArrow {
    -moz-appearance: textfield;
}

#property-assessment .bold {
    font-weight: 600;
}

#property-assessment .bottom-space {
    padding-bottom: 5%;
}

#property-assessment .checkbox-confirm {
    padding-top: 2em;
    padding-bottom: 2em;
}

@media screen and (max-width: 906px) {
    #property-assessment .intro-pic {
        left: -80px;
    }
}

@media screen and (max-width: 874px) {
    #property-assessment .form-btns {
        display: block;
        text-align: center;
    }
    #property-assessment .form-btns mat-checkbox {
        display: block;
        margin-right: 0;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 801px) {
    #property-assessment .intro-pic img {
        height: 130%;
    }
    #property-assessment .intro-content {
        padding: 24px 35px;
        width: 357px;
    }
}

@media screen and (max-width: 680px) {
    #property-assessment .intro-pic {
        left: -160px;
    }
}

@media screen and (max-width: 616px) {
    #property-assessment .intro-box {
        justify-content: unset;
        flex-direction: column;
    }
    #property-assessment .intro-pic {
        left: 0;
        position: relative;
        width: 100%;
        height: 100%;
        max-height: 270px;
    }
    #property-assessment .intro-pic img {
        height: 175%;
        width: 100%;
        top: -145px;
    }
    #property-assessment .intro-content {
        width: 100%;
    }
    #property-assessment .form .radio-btn-slot {
        display: block;
        width: 98%;
        margin-left: 1%;
        margin-right: 1%;
    }
    #property-assessment .province {
        width: 100%;
        display: block;
    }
    #property-assessment .city {
        width: 100%;
        display: block;
        padding-left: 0%;
    }
}

@media screen and (max-width: 540px) {
    #property-assessment .intro-pic img {
        top: -110px;
    }
    #property-assessment .intro-content {
        padding: 24px 24px;
    }
}

@media screen and (max-width: 516px) {
    /* #property-assessment .intro-pic {
        max-height: 200px;
    } */
    #property-assessment .intro-pic img {
        top: -120px;
    }
}

@media screen and (max-width: 426px) {
    #property-assessment .intro-pic img {
        top: -95px;
    }
}

@media screen and (max-width: 376px) {
    #property-assessment .intro-big-title {
        font-size: 21px;
    }
    #property-assessment .intro-pic img {
        top: -75px;
    }
    #property-assessment .intro-content .intro-title {
        font-size: 18px;
    }
}

@media screen and (max-width: 321px) {
    #property-assessment .intro-big-title {
        margin: 40px 0 30px;
    }
    #property-assessment .intro-pic img {
        top: -70px;
    }
}
