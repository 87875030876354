#introduction .intro-big-title {
    font-weight: 600;
    font-size: 24px;
    text-transform: uppercase;
    color: #1B1464;
    margin: 50px 0 30px;
    letter-spacing: 1px;
    transition: all ease .3s;
}
#introduction .intro-box {
    position: relative;
    display: flex;
    background-color: #fff;
    box-shadow: 0px 1px 2px rgba(27, 20, 100, 0.24);
    border-radius: 3px;
    margin-bottom: 25px;
    overflow: hidden;
    justify-content: flex-end;
}
#introduction .intro-pic {
    overflow: hidden;
    position: absolute;
    width: 472px;
    height: 100%;
    top: 0;
    left: 0;
}
#introduction .intro-pic img {
    height: 146%;
    width: 100%;
    position: relative;
    top: -90px;
}
#introduction .intro-content {
    box-sizing: border-box;
    padding: 24px 48px;
    width: 472px;
    background-color: #fff;
    z-index: 1;
}
#introduction .intro-content .intro-title {
    font-weight: 600;
    font-size: 20px;
    color: #000;
    position: relative;
    box-sizing: border-box;
    padding: 20px 0 17px;
}
#introduction .intro-content .intro-text {
    overflow-y: auto;
    text-align: justify;
}
#introduction .form .radio-btn-slot{
    display:inline-block !important;
    /*border: 2px solid #D5B527;
    border-radius: 10px;*/
    box-sizing: border-box;
    word-wrap: break-word;
    margin-left: 1%;
    margin-right: 1%;
}
#introduction .form .radio-btn-slot.en{
    width: 38%;
}
#introduction .form .radio-btn-slot.fl{
    width: 28%;
}
#introduction .form .radio-btn-slot.tl{
    width: 28%;
}
#introduction .form .radio-btn-slot .radio-lbl {
    white-space: normal;
    font-size: 16px;
}
#introduction .form-btns mat-checkbox {
    font-family: "ProximaNova", "Arial", "Calibri", sans-serif;
    margin-right: 40px;
}
#introduction .form {
    padding-bottom: 0px;
    padding-top: 30px;
}

@media screen and (max-width: 906px) {
    #introduction .intro-pic {
        left: -80px;
    }
}

@media screen and (max-width: 874px) {
    #introduction .form-btns {
        display: block;
        text-align: center;
    }
    #introduction .form-btns mat-checkbox {
        display: block;
        margin-right: 0;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 801px) {
    #introduction .intro-pic img {
        height: 130%;
    }
    #introduction .intro-content {
        padding: 24px 35px;
        width: 357px;
    }
}

@media screen and (max-width: 680px) {
    #introduction .intro-pic {
        left: -160px;
    }
}

@media screen and (max-width: 616px) {
    #introduction .intro-box {
        justify-content: unset;
        flex-direction: column;
    }
    #introduction .intro-pic {
        left: 0;
        position: relative;
        width: 100%;
        height: 100%;
        max-height: 270px;
    }
    #introduction .intro-pic img {
        height: 175%;
        width: 100%;
        top: -145px;
    }
    #introduction .intro-content {
        width: 100%;
    }
    #introduction .form .radio-btn-slot.en{
        width: 32%;
    }
    #introduction .form .radio-btn-slot.fl,
    #introduction .form .radio-btn-slot.tl{
        width: 31%;
    }
}

#introduction .form.bottom-border {
    border-bottom: 0px;
}

@media screen and (max-width: 540px) {
    #introduction .intro-pic img {
        top: -110px;
    }
    #introduction .intro-content {
        padding: 24px 24px;
    }
}

@media screen and (max-width: 516px) {
    #introduction .intro-pic {
        max-height: 200px;
    }
    #introduction .intro-pic img {
        top: -120px;
    }
}

@media screen and (max-width: 426px) {
    #introduction .intro-pic img {
        top: -95px;
    }
}

@media screen and (max-width: 376px) {
    #introduction .intro-big-title {
        font-size: 21px;
    }
    #introduction .intro-pic img {
        top: -75px;
    }
    #introduction .intro-content .intro-title {
        font-size: 18px;
    }
}

@media screen and (max-width: 321px) {
    #introduction .intro-big-title {
        margin: 40px 0 30px;
    }
    #introduction .intro-pic img {
        top: -70px;
    }
}