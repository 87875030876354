.product-details .quote-policy-details {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 35px;
}
.product-details .quote-policy-details .detail {
    margin-right: 3%;
    width: 31.33%;
    margin-top: 25px;
}
.product-details .quote-policy-details .detail:nth-of-type(3n) {
    margin-right: 0;
}
.product-details .quote-policy-details .detail:nth-of-type(1),
.product-details .quote-policy-details .detail:nth-of-type(2),
.product-details .quote-policy-details .detail:nth-of-type(3) {
    margin-top: 0;
}
.product-details .quote-policy-details .detail:nth-of-type(6) .value {
    color: #b11116;
}
.product-details .summary .box-1 .detail .label {
    margin-top: 25px;
}
.product-details .summary .box-1 .detail .label:first-of-type {
    margin-top: 0;
}
.product-details .quote-policy-details .detail .value,
.product-details .summary .box-1 .detail .value,
.product-details .summary .box-2 .detail .value {
    margin-top: 7px;
    font-weight: 600;
}
.product-details .quote-policy-details .detail .value,
.product-details .summary .box-1 .detail .value {
    color: #000;
}
.product-details .summary .box-2 .detail div {
    color: #fff;
}
.product-details .form-note {
    margin: 50px 0 35px;
}
.product-details .quote-policy-summary {
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    padding: 35px 0;
    margin-bottom: 30px;
}
.product-details .summary {
    margin-top: 40px;
}
.product-details .summary:first-of-type {
    margin-top: 0;
}
.product-details .summary h2,
.product-details form .form-title h2 {
    font-family: "Arial", "Calibri", sans-serif;
    font-size: 22px;
    letter-spacing: .04em;
    color: #000;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.product-details .summary .box-1,
.product-details .summary .box-2 {
    padding: 25px 3%;
    box-shadow: 0px 1px 2px rgba(27, 20, 100, 0.24);
    border-radius: 5px;
}
.product-details .summary .box-1 {
    font-family: "Montserrat", "Arial", "Calibri", sans-serif;
    color: #000;
    background-color: #fff;
}
.product-details .summary .box-2 {
    font-family: "Arial", "Calibri", sans-serif;
    color: #fff;
    background-color: #1b1464;
    margin-bottom: 15px;
}
.product-details .summary.premium-charges div:last-of-type {
    margin-bottom: 0;
}
.product-details .summary .box-holder {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    padding-bottom: 25px;
}
.product-details .summary .box-holder:last-of-type {
    margin-bottom: 0;
    border-bottom: none;
    padding-bottom: 0;
}
.product-details .summary .box-1 .box-holder.style-2 .detail {
    margin-right: 2.5%;
    width: 48.7%;
}
.product-details .summary .box-1 .box-holder.style-3 .detail {
    margin-right: 2%;
    width: 32%;
}
.product-details .summary .box-1 .box-holder.style-4 .detail,
.product-details .summary.vehicle-information .box-1 .box-holder.custom-style-1 .detail,
.product-details .summary.vehicle-information .box-1 .box-holder.custom-style-2 .detail {
    margin-right: 2%;
    width: 23.5%;
}
.product-details .summary.vehicle-information .box-1 .box-holder.custom-style-1 .detail:first-of-type,
.product-details .summary.vehicle-information .box-1 .box-holder.custom-style-2 .detail:last-of-type {
    width: 49%;
}
.product-details .summary.premium-charges .box-1 .box-holder.style-2 .detail {
    width: 25%;
}
.product-details .summary.premium-charges .box-2 .box-holder > .detail {
    margin-right: 2%;
    width: 13%;
}
.product-details .summary.premium-charges .box-2 .box-holder > .details {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    margin-right: 2%;
    padding: 0 2%;
    box-sizing: border-box;
    border-left: 1px solid #ffffff54;
    border-right: 1px solid #ffffff54;
}
.product-details .summary.premium-charges .box-2 .box-holder > .details .detail {
    width: 30.66%;
    margin-top: 25px;
    margin-right: 4%;
}
.product-details .summary.premium-charges .box-2 .box-holder > .details .detail:nth-of-type(1),
.product-details .summary.premium-charges .box-2 .box-holder > .details .detail:nth-of-type(2),
.product-details .summary.premium-charges .box-2 .box-holder > .details .detail:nth-of-type(3) {
    margin-top: 0;
}
.product-details .summary .box-1 .box-holder.style-2 .detail:last-of-type,
.product-details .summary .box-1 .box-holder.style-3 .detail:last-of-type,
.product-details .summary .box-1 .box-holder.style-4 .detail:last-of-type,
.product-details .summary.vehicle-information .box-1 .box-holder.custom-style-1 .detail:last-of-type,
.product-details .summary.vehicle-information .box-1 .box-holder.custom-style-2 .detail:last-of-type,
.product-details .summary.premium-charges .box-2 .box-holder > .detail:last-of-type,
.product-details .summary.premium-charges .box-2 .box-holder > .details .detail:nth-of-type(3n) {
    margin-right: 0;
}
.product-details .summary.premium-charges .detail.total-amt div {
    color: #d5b527;
}
.product-details .summary.premium-charges .box-1 .box-holder.style-4 .detail,
.product-details .summary.premium-charges .box-1 .box-holder.style-5 .detail {
    margin-right: 2%;
}
.product-details .summary.premium-charges .box-1 .box-holder.style-4 .detail:last-of-type,
.product-details .summary.premium-charges .box-1 .box-holder.style-5 .detail:last-of-type {
    margin-right: 0;
}
.product-details .summary.premium-charges .box-1 .box-holder.style-3 .detail,
.product-details .summary.premium-charges .box-1 .box-holder.style-4 .detail,
.product-details .summary.premium-charges .box-1 .box-holder.style-5 .detail {
    margin-right: 0;
}

/* PREMIUM CHARGES STYLE-3 COLUMN WIDTH - START */
.product-details .summary.premium-charges .box-1 .box-holder.style-3 .detail:nth-of-type(1) {
    width: 36%;
}
.product-details .summary.premium-charges .box-1 .box-holder.style-3 .detail:nth-of-type(2) {
    width: 32%;
}
.product-details .summary.premium-charges .box-1 .box-holder.style-3 .detail:nth-of-type(3) {
    width: 32%;
}
/* PREMIUM CHARGES STYLE-3 COLUMN WIDTH - END */

/* PREMIUM CHARGES STYLE-4 COLUMN WIDTH - START */
.product-details .summary.premium-charges .box-1 .box-holder.style-4 .detail:nth-of-type(1) {
    width: 24%;
}
.product-details .summary.premium-charges .box-1 .box-holder.style-4 .detail:nth-of-type(2) {
    width: 27%;
}
.product-details .summary.premium-charges .box-1 .box-holder.style-4 .detail:nth-of-type(3) {
    width: 26%;
}
.product-details .summary.premium-charges .box-1 .box-holder.style-4 .detail:nth-of-type(4) {
    width: 17%;
}
/* PREMIUM CHARGES STYLE-4 COLUMN WIDTH - END */

/* PREMIUM CHARGES STYLE-5 COLUMN WIDTH - START */
.product-details .summary.premium-charges .box-1 .box-holder.style-5 .detail:nth-of-type(1) {
    width: 21%;
}
.product-details .summary.premium-charges .box-1 .box-holder.style-5 .detail:nth-of-type(2) {
    width: 14%;
}
.product-details .summary.premium-charges .box-1 .box-holder.style-5 .detail:nth-of-type(3) {
    width: 24%;
}
.product-details .summary.premium-charges .box-1 .box-holder.style-5 .detail:nth-of-type(4) {
    width: 22%;
}
.product-details .summary.premium-charges .box-1 .box-holder.style-5 .detail:nth-of-type(5) {
    width: 11%;
}
/* PREMIUM CHARGES STYLE-5 COLUMN WIDTH - END */

.product-details form .form-title {
    display: flex;
    justify-content: space-between;
}
.product-details form .form-title h2 {
    margin-bottom: 30px;
}
.product-details form .form-title .form-title-button button {
    cursor: pointer;
    font-size: 16px;
    border: none;
    color: #b11116;
    font-weight: bold;
    margin-top: 3px;
    background-color: unset;
}
.product-details .form-fields form .form.terms .col-case {
    margin-bottom: 15px;
    font-family: "Arial", "Calibri", sans-serif;
    font-weight: 600;
    display: flex;
}
.product-details .form-fields form .form.terms .col-case:last-of-type {
    margin-bottom: 0;
}
.product-details .form-fields form .form.terms .col-case mat-checkbox {
    font-family: "Arial", "Calibri", sans-serif;
    font-weight: 600;
}
.product-details .form-fields form .form.terms .col-case mat-checkbox a {
    font-weight: bold;
    cursor: pointer;
    color: #1B1464;
    text-decoration: underline;
}
.product-details .form-fields form .form.terms .col-case a {
    font-weight: bold;
    cursor: pointer;
    color: #1B1464;
    text-decoration: underline;
}
.product-details .form-fields form .form.terms .col-case .terms-text {
    line-height: 24px;
}

@media screen and (max-width: 966px) {
    .product-details .summary.premium-charges .box-1 .box-holder.style-5 .detail:nth-of-type(1) {
        width: 32%;
    }
    .product-details .summary.premium-charges .box-1 .box-holder.style-5 .detail:nth-of-type(2) {
        width: 25%;
    }
    .product-details .summary.premium-charges .box-1 .box-holder.style-5 .detail:nth-of-type(3) {
        width: 35%;
        margin-right: 0;
    }
    .product-details .summary.premium-charges .box-1 .box-holder.style-5 .detail:nth-of-type(4) {
        width: 32%;
        margin-top: 25px;
    }
    .product-details .summary.premium-charges .box-1 .box-holder.style-5 .detail:nth-of-type(5) {
        width: 25%;
        margin-top: 25px;
    }
}

@media screen and (max-width: 911px) {
    .product-details .summary.premium-charges .box-2 .box-holder > .details .detail {
        width: 46%;
        margin-top: 25px !important;
    }
    .product-details .summary.premium-charges .box-2 .box-holder > .details .detail:nth-of-type(1),
    .product-details .summary.premium-charges .box-2 .box-holder > .details .detail:nth-of-type(2) {
        margin-top: 0 !important;
    }
    .product-details .summary.premium-charges .box-2 .box-holder > .details .detail:nth-of-type(odd) {
        margin-right: 4%;
    }
    .product-details .summary.premium-charges .box-2 .box-holder > .details .detail:nth-of-type(even) {
        margin-right: 0;
    }
}

@media screen and (max-width: 831px) {
    .product-details .summary .box-1 .box-holder.style-4 .detail,
    .product-details .summary.vehicle-information .box-1 .box-holder.custom-style-1 .detail {
        width: 48% !important;
        margin-top: 25px;
        margin-right: 4% !important;
    }
    .product-details .summary.vehicle-information .box-1 .box-holder.custom-style-2 .detail {
        width: 48% !important;
        margin-top: 25px;
        margin-right: 4% !important;
    }
    .product-details .summary .box-1 .box-holder.style-4 .detail:nth-of-type(1),
    .product-details .summary .box-1 .box-holder.style-4 .detail:nth-of-type(2),
    .product-details .summary.vehicle-information .box-1 .box-holder.custom-style-2 .detail:nth-of-type(1),
    .product-details .summary.vehicle-information .box-1 .box-holder.custom-style-2 .detail:nth-of-type(2) {
        margin-top: 0;
    }
    .product-details .summary .box-1 .box-holder.style-4 .detail:nth-of-type(even),
    .product-details .summary.vehicle-information .box-1 .box-holder.custom-style-1 .detail:last-of-type,
    .product-details .summary.vehicle-information .box-1 .box-holder.custom-style-2 .detail:nth-of-type(2),
    .product-details .summary.vehicle-information .box-1 .box-holder.custom-style-2 .detail:last-of-type {
        margin-right: 0 !important;
    }
    .product-details .summary.vehicle-information .box-1 .box-holder.custom-style-1 .detail:first-of-type {
        width: 100% !important;
        margin: 0 !important;
    }
    .product-details .summary.vehicle-information .box-1 .box-holder.custom-style-2 .detail:last-of-type {
        width: 100% !important;
    }
    .product-details .summary.premium-charges .box-1 .box-holder.style-5 .detail:nth-of-type(1),
    .product-details .summary.premium-charges .box-1 .box-holder.style-5 .detail:nth-of-type(4) {
        width: 37%;
    }
    .product-details .summary.premium-charges .box-1 .box-holder.style-5 .detail:nth-of-type(2),
    .product-details .summary.premium-charges .box-1 .box-holder.style-5 .detail:nth-of-type(5) {
        width: 27%;
    }
    .product-details .summary.premium-charges .box-1 .box-holder.style-5 .detail:nth-of-type(3) {
        width: 32%;
    }
    .product-details .summary .box-1 .box-holder.style-2 .detail {
        margin-right: 4%;
        width: 48%;
    }
    .product-details .summary.premium-charges .box-1 .box-holder.style-2 .detail {
        width: 31%;
    }
}

@media screen and (max-width: 801px) {
    .product-details .summary .box-holder {
        padding-bottom: 0;
        border-bottom: unset;
    }
    .product-details .summary.premium-charges .box-2 .box-holder > .detail {
        width: 16%;
    }
    .product-details .summary.premium-charges .box-2 .box-holder > .details {
        width: 64%;
    }
}

@media screen and (max-width: 731px) {
    .product-details .summary .box-1 .box-holder.style-3 .detail {
        margin-right: 4%;
        width: 48%;
    }
    .product-details .summary .box-1 .box-holder.style-3 .detail:nth-of-type(2) {
        margin-right: 0;
    }
    .product-details .summary .box-1 .box-holder.style-3 .detail:last-of-type {
        width: 100%;
        margin-top: 25px;
    }
    .product-details .summary .box-1 .box-holder.style-3 .detail:first-of-type {
        margin-top: 0;
    }
}

@media screen and (max-width: 711px) {
    .product-details .summary.premium-charges .box-1 .box-holder.style-5 .detail {
        width: 48% !important;
        margin-right: 4% !important;
    }
    .product-details .summary.premium-charges .box-1 .box-holder.style-5 .detail:nth-of-type(odd) {
        margin-top: 25px !important;
    }
    .product-details .summary.premium-charges .box-1 .box-holder.style-5 .detail:first-of-type {
        margin-top: 0 !important;
    }
    .product-details .summary.premium-charges .box-1 .box-holder.style-5 .detail:nth-of-type(even) {
        margin-right: 0 !important;
    }
}

@media screen and (max-width: 701px) {
    .product-details .summary.premium-charges .box-1 .box-holder.style-3 .detail {
        width: 100% !important;
        margin-top: 20px;
    }
    .product-details .summary.premium-charges .box-1 .box-holder.style-3 .detail:first-of-type {
        margin-top: 0;
    }
}

@media screen and (max-width: 660px) {
    .product-details .quote-policy-details {
        margin-bottom: 10px;
    }
    .product-details .quote-policy-details .detail {
        margin: 0 4% 25px 0 !important;
        width: 48%;
    }
    .product-details .quote-policy-details .detail:nth-of-type(even) {
        margin-right: 0 !important;
    }
    .product-details .form-note {
        margin: 15px 0 35px;
    }
    .product-details .summary.premium-charges .box-2 .box-holder > .detail {
        width: 19%;
    }
    .product-details .summary.premium-charges .box-2 .box-holder > .details {
        display: block;
        width: 58%;
    }
    .product-details .summary.premium-charges .box-2 .box-holder > .details .detail {
        width: 100%;
        padding-right: 0 !important;
        display: flex;
        justify-content: space-between;
    }
    .product-details .summary.premium-charges .box-2 .box-holder > .details .detail,
    .product-details .summary.premium-charges .box-2 .box-holder > .details .detail:nth-of-type(2) {
        margin-top: 10px !important;
    }
    .product-details .summary.premium-charges .box-2 .box-holder > .details .detail:first-of-type,
    .product-details .summary.premium-charges .box-2 .box-holder > .details .detail .value {
        margin-top: 0 !important;
    }
    .product-details .summary .box-1 .box-holder.style-2 .detail,
    .product-details .summary.premium-charges .box-1 .box-holder.style-2 .detail {
        width: 48%;
    }
}

@media screen and (max-width: 561px) {
    .product-details .summary .box-2 {
        padding: 4% 4%;
    }
    .product-details .summary.premium-charges .box-2 > div {
        flex-direction: column;
    }
    .product-details .summary.premium-charges .box-2 > div > div {
        width: 100% !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin: 0 0 15px !important;
    }
    .product-details .summary.premium-charges .box-2 > div > div:last-of-type {
        margin-bottom: 0 !important;
    }
    .product-details .summary.premium-charges .box-2 .box-holder > .detail {
        display: flex;
        justify-content: space-between;
    }
    .product-details .summary.premium-charges .box-2 .box-holder > .detail .value {
        margin-top: 0;
    }
    .product-details .summary.premium-charges .box-2 .box-holder > .details {
        border-left: unset;
        border-right: unset;
        border-top: 1px solid #ffffff54;
        border-bottom: 1px solid #ffffff54;
        padding: 15px 0;
    }
    .product-details form .form-title {
        display: block;
    }
    .product-details form .form-title h2,
    .product-details form .form-title .form-title-button button {
        margin-top: 0;
        margin-bottom: 30px;
    }
    .product-details form .form-title .form-title-button {
        display: flex;
        justify-content: center;
    }
    .product-details form .form-title .form-title-button button {
        width: 60%;
        background-color: #b11116;
        color: #fff;
        border-radius: 5px;
        height: 40px;
    }
}

@media screen and (max-width: 480px) {
    .product-details .summary.premium-charges .box-1 .box-holder.style-5 .detail {
        width: 100% !important;
        margin-right: 0 !important;
        margin-top: 20px !important;
    }
    .product-details .summary.premium-charges .box-1 .box-holder.style-5 .detail:first-of-type {
        margin-top: 0 !important;
    }
}

@media screen and (max-width: 426px) {
    .product-details .quote-policy-details .detail,
    .product-details .summary .box-1 .detail {
        width: 100%;
        margin: 0 0 10px !important;
        display: flex;
        justify-content: space-between;
    }
    .product-details .quote-policy-details .detail .label,
    .product-details .summary .box-1 .detail .label {
        min-width: 147px;
        max-width: 147px;
        margin-right: 20px;
    }
    .product-details .summary .box-1 .box-holder.style-4 .detail,
    .product-details .summary.vehicle-information .box-1 .box-holder.custom-style-1 .detail,
    .product-details .summary.vehicle-information .box-1 .box-holder.custom-style-2 .detail {
        margin-right: 0 !important;
    }
    .product-details .quote-policy-details .detail .value,
    .product-details .summary .box-1 .detail .value,
    .product-details .summary .box-2 .detail .value {
        margin-top: 0;
        text-align: right;
    }
    .product-details .left-aligned .detail .value {
        text-align: left !important;
        width: 100%;
    }
    .product-details .summary h2 {
        font-size: 20px;
        margin-bottom: 15px;
    }
    .product-details form .form-title h2 {
        font-size: 20px;
    }
    .product-details .summary .box-1 .box-holder.style-2 > .detail,
    .product-details .summary .box-1 .box-holder.style-3 > .detail,
    .product-details .summary .box-1 .box-holder.style-4 > .detail,
    .product-details .summary.vehicle-information .box-1 .box-holder.custom-style-1 .detail:first-of-type,
    .product-details .summary.vehicle-information .box-1 .box-holder.custom-style-1 .detail,
    .product-details .summary.vehicle-information .box-1 .box-holder.custom-style-2 .detail {
        width: 100% !important;
    }
    .product-details .summary .box-holder {
        margin-bottom: 10px;
    }
    .product-details .summary .box-1 .box-holder.style-2 > .detail:last-of-type,
    .product-details .summary .box-1 .box-holder.style-3 > .detail:last-of-type,
    .product-details .summary .box-1 .box-holder.style-4 > .detail:last-of-type,
    .product-details .summary.vehicle-information .box-holder:last-of-type,
    .product-details .summary.vehicle-information .box-1 .box-holder.custom-style-1 .detail:last-of-type,
    .product-details .summary.vehicle-information .box-1 .box-holder.custom-style-2 .detail:last-of-type {
        margin-bottom: 0 !important;
    }
    .product-details .summary .box-1,
    .product-details .summary .box-2 {
        padding: 15px 3%;
    }
    .product-details .summary {
        margin-top: 30px;
    }
}

@media screen and (max-width: 376px) {
    .product-details .summary .box-1 {
        padding: 20px 3%;
    }
    .product-details .summary h2 {
        font-size: 18px;
        margin-bottom: 10px;
    }
    .product-details form .form-title h2 {
        font-size: 18px;
    }
    .product-details .quote-policy-details .detail .label,
    .product-details .summary .box-1 .detail .label {
        min-width: 132px;
        max-width: 132px;
    }
}

@media screen and (max-width: 336px) {
    .product-details .summary h2,
    .product-details form .form-title h2 {
        font-size: 17px;
    }
}