#rev-prem-charges {
    min-height: 285px;
}

#rev-prem-charges .form-btns {
    flex-wrap: nowrap;
    justify-content: space-between;
    display: flex;
    align-items: center;
}

#rev-prem-charges .cancel-note {
    margin: 0 25px 0 0;
}

#rev-prem-charges .form-btns .buttons {
    min-width: 304px;
}

#rev-prem-charges.product-details .summary.coverage {
    margin-top: 12px;
}

/* SET CUSTOM-TABLE COLUMNS WIDTH - START */

#rev-prem-charges .summary.coverage .custom-table-2>ul>li {
    min-width: 480px;
}

#rev-prem-charges .summary.coverage .custom-table-2>ul>li>div>p:nth-of-type(1) {
    min-width: 72%;
}

#rev-prem-charges .summary.coverage .custom-table-2>ul>li>div>p:nth-of-type(2) {
    min-width: 25%;
}

#rev-prem-charges .help-icon {
    color:#1B1464;
    display: inline-block;
}
#rev-prem-charges .word-break {
    word-break: break-all;
}
.mat-tooltip{
    white-space: pre-line;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 12px;
    background: white;
    color: black !important;
}

#rev-prem-charges .blue {
    background: #1B1464;
    border-color: #1B1464;
}

#rev-prem-charges .reason-text {
    display: flex;
}

#rev-prem-charges .deductible-text{
    white-space: pre-wrap;
    text-align: justify;
    font-weight: normal;
}

/* SET CUSTOM-TABLE COLUMNS WIDTH - END */

/* SET CUSTOM-TABLE COLUMNS WIDTH - START - BENEFITS AND COVERAGE - FPA*/
#rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li > p:nth-of-type(1),
#rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li > div:nth-of-type(1) {
    min-width: 40%;
}
#rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li > p:nth-of-type(2),
#rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li > div:nth-of-type(2) {
    min-width: 15%;
}
#rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li > p:nth-of-type(3),
#rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li > div:nth-of-type(3) {
    min-width: 20%;
}
#rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li > p:nth-of-type(4),
#rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li > div:nth-of-type(4) {
    min-width: 15%;
}
/* SET CUSTOM-TABLE COLUMNS WIDTH - END */

#rev-prem-charges.product-details .summary.benCovFPA .box-1 {
    padding: 10px 3%;
}
#rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li > p.centerAlign,
#rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li > div > p.centerAlign {
    text-align: center;
}
#rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li.header > p {
    user-select: none;
}
#rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li.header > p::after {
    background-image: none;
}
#rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li.body > div > div {
    display: none;
    margin-top: 20px;
}
#rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li.body > div > div > p:first-of-type {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
}

/* SET CUSTOM-TABLE COLUMNS WIDTH - START - BENEFITS AND COVERAGE - IND*/
#rev-prem-charges.product-details .summary.benCovIND .custom-table > ul > li > p:nth-of-type(1),
#rev-prem-charges.product-details .summary.benCovIND .custom-table > ul > li > div:nth-of-type(1) {
    min-width: 49%;
}
#rev-prem-charges.product-details .summary.benCovIND .custom-table > ul > li > p:nth-of-type(2),
#rev-prem-charges.product-details .summary.benCovIND .custom-table > ul > li > div:nth-of-type(2) {
    min-width: 45%;
}
/* SET CUSTOM-TABLE COLUMNS WIDTH - END */

#rev-prem-charges.product-details .summary.benCovIND .box-1 {
    padding: 10px 3%;
}
#rev-prem-charges.product-details .summary.benCovIND .custom-table > ul > li > div > p.prem-total-1 {
    display: none;
}
#rev-prem-charges.product-details .summary.benCovIND .custom-table > ul > li.header > p {
    user-select: none;
}
#rev-prem-charges.product-details .summary.benCovIND .custom-table > ul > li.header > p::after {
    background-image: none;
}
#rev-prem-charges.product-details .summary.benCovIND .custom-table > ul > li.body > div > div {
    display: none;
    margin-top: 20px;
}
#rev-prem-charges.product-details .summary.benCovIND .custom-table > ul > li.body > div > div > p:first-of-type {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
}

/* SET CUSTOM-TABLE COLUMNS WIDTH - START - BENEFITS AND COVERAGE - CI*/
#rev-prem-charges.product-details .summary.benCovCI .custom-table > ul > li > p:nth-of-type(1),
#rev-prem-charges.product-details .summary.benCovCI .custom-table > ul > li > div:nth-of-type(1) {
    min-width: 28%;
}
#rev-prem-charges.product-details .summary.benCovCI .custom-table > ul > li > p:nth-of-type(2),
#rev-prem-charges.product-details .summary.benCovCI .custom-table > ul > li > div:nth-of-type(2) {
    min-width: 40%;
}
#rev-prem-charges.product-details .summary.benCovCI .custom-table > ul > li > p:nth-of-type(3),
#rev-prem-charges.product-details .summary.benCovCI .custom-table > ul > li > div:nth-of-type(3) {
    min-width: 25%;
}
/* SET CUSTOM-TABLE COLUMNS WIDTH - END */

#rev-prem-charges.product-details .summary.benCovCI .box-1 {
    padding: 10px 3%;
}
#rev-prem-charges.product-details .summary.benCovCI .custom-table > ul > li > p.value,
#rev-prem-charges.product-details .summary.benCovCI .custom-table > ul > li > div > p.value {
    font-weight: bold;
}
#rev-prem-charges.product-details .summary.benCovCI .custom-table > ul > li.header > p {
    user-select: none;
}
#rev-prem-charges.product-details .summary.benCovCI .custom-table > ul > li.header > p::after {
    background-image: none;
}
#rev-prem-charges.product-details .summary.benCovCI .custom-table > ul > li.body > div > div {
    display: none;
    margin-top: 20px;
}
#rev-prem-charges.product-details .summary.benCovCI .custom-table > ul > li.body > div > div > p:first-of-type {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
}

/*  UPDATED CSS FOR TABLE - START  */

#rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > p:nth-of-type(1),
#rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(1) {
    min-width: 25%;
}
#rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > p:nth-of-type(2),
#rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(2) {
    min-width: 14%;
}
#rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > p:nth-of-type(3),
#rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(3) {
    min-width: 21%;
}
#rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > p:nth-of-type(4),
#rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(4) {
    min-width: 10%;
}
#rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > p:nth-of-type(5),
#rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(5) {
    min-width: 11%;
}
#rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > p:nth-of-type(6),
#rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(6) {
    min-width: 5%;
}
#rev-prem-charges.product-details .summary.dependents .box-1 {
    padding: 10px 1%;
}
#rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > div > p.prem-total-1 {
    display: none;
}
#rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > p.centerAlign,
#rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > div > p.centerAlign {
    text-align: center;
}
#rev-prem-charges.product-details .summary.dependents .custom-table > ul > li.header > p {
    user-select: none;
}
#rev-prem-charges.product-details .summary.dependents .custom-table > ul > li.body > div > div {
    display: none;
    margin-top: 20px;
}
#rev-prem-charges.product-details .summary.dependents .custom-table > ul > li.body > div > div > p:first-of-type {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
}

/* FIRE CHECKBOX - START */
#rev-prem-charges.product-details .checkbox {
    margin-top: 20px;
    cursor: default;
    padding-right: 2%;
    /* color: gray; */
    font-family: "Montserrat", "Arial", "Calibri", sans-serif;
}

#rev-prem-charges.product-details .currency {
    margin-left: 5px;
    padding-right: 10px;
    cursor: text;
    border-right: 2px solid lightgray;
    display: inline-block;
    font-family: "Montserrat", "Arial", "Calibri", sans-serif;
    font-weight: 600;
    height: 30px;
    padding-top: 7px;
    /* color: gray; */
}

#rev-prem-charges.product-details .amount {
    margin-left: 5px;
    padding-left: 5px;
    cursor: text;
    display: inline-block;
    font-family: "Montserrat", "Arial", "Calibri", sans-serif;
    font-weight: 600;
}

#rev-prem-charges.product-details .insurance-amount {
    border: none;
    margin-bottom: 0%;
    padding-bottom: 0%;
}

#rev-prem-charges.product-details .currency-amount-container {
    display: inline-block;
}
/* FIRE CHECKBOX - END */

/*  UPDATED CSS FOR TABLE - END  */

@media screen and (max-width: 875px) {
    #rev-prem-charges .form-btns {
        display: block;
    }
    #rev-prem-charges .cancel-note {
        margin: 0 0 40px;
    }
    #rev-prem-charges .form-btns .buttons {
        min-width: unset;
        text-align: center;
    }
}

#verifyEmailRevPrem {
    max-width: 80vw;
    width: 450px;
    padding: 30px 47px;
}

#verifyEmailRevPrem h2 {
    font-size: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 28px;
    margin: 10px 0 25px;
    color: #1b1464;
}

@media screen and (max-width: 801px) {
    #rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(1),
    #rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(2),
    #rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(3),
    #rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(4) {
        display: block;
    }

    #rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li > div > p,
    #rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li.body > div.sp-1 > p,
    #rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li > p:nth-of-type(2),
    #rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li > div:nth-of-type(2),
    #rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li > p:nth-of-type(3),
    #rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li > div:nth-of-type(3),
    #rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li > p:nth-of-type(4),
    #rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li > div:nth-of-type(4) {
        display: none;
    }

    #rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li > p:nth-of-type(1),
    #rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li > div:nth-of-type(1) {
        min-width: 94%;
    }

    #rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li.header > p::after {
        background-image: none;
    }

    #rev-prem-charges.product-details .summary.benCovFPA .custom-table > ul > li:nth-of-type(even) {
        background-color: #eaeaea;
    }

    #rev-prem-charges.product-details .summary.benCovIND .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(1),
    #rev-prem-charges.product-details .summary.benCovIND .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(2) {
        display: block;
    }

    #rev-prem-charges.product-details .summary.benCovIND .custom-table > ul > li > div > p,
    #rev-prem-charges.product-details .summary.benCovIND .custom-table > ul > li.body > div.sp-1 > p,
    #rev-prem-charges.product-details .summary.benCovIND .custom-table > ul > li > p:nth-of-type(2),
    #rev-prem-charges.product-details .summary.benCovIND .custom-table > ul > li > div:nth-of-type(2) {
        display: none;
    }

    #rev-prem-charges.product-details .summary.benCovIND .custom-table > ul > li.header > p::after {
        background-image: none;
    }

    #rev-prem-charges.product-details .summary.benCovIND .custom-table > ul > li > p:nth-of-type(1),
    #rev-prem-charges.product-details .summary.benCovIND .custom-table > ul > li > div:nth-of-type(1) {
        min-width: 94%;
    }

    #rev-prem-charges.product-details .summary.benCovIND .custom-table > ul > li:nth-of-type(even) {
        background-color: #eaeaea;
    }

    #rev-prem-charges.product-details .summary.benCovCI .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(1),
    #rev-prem-charges.product-details .summary.benCovCI .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(2),
    #rev-prem-charges.product-details .summary.benCovCI .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(3) {
        display: block;
    }

    #rev-prem-charges.product-details .summary.benCovCI .custom-table > ul > li.body2,
    #rev-prem-charges.product-details .summary.benCovCI .custom-table > ul > li > div > p,
    #rev-prem-charges.product-details .summary.benCovCI .custom-table > ul > li.body > div.sp-1 > p,
    #rev-prem-charges.product-details .summary.benCovCI .custom-table > ul > li > p:nth-of-type(2),
    #rev-prem-charges.product-details .summary.benCovCI .custom-table > ul > li > div:nth-of-type(2) {
        display: none;
    }

    #rev-prem-charges.product-details .summary.benCovCI .custom-table > ul > li.header > p::after {
        background-image: none;
    }

    #rev-prem-charges.product-details .summary.benCovCI .custom-table > ul > li > p:nth-of-type(1),
    #rev-prem-charges.product-details .summary.benCovCI .custom-table > ul > li > div:nth-of-type(1) {
        min-width: 94%;
    }

    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(1),
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li.body > div.sp-6 > div:nth-of-type(2),
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(3),
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li.body > div.sp-6 > div:nth-of-type(4),
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(5),
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li.body > div.sp-6 > div:nth-of-type(6),
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > div > p.prem-total-1 {
        display: block;
    }
    
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > p,
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > div > p,
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li.body > div.sp-1 > p,
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li.body > div.sp-6 > p,
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > p:nth-of-type(2),
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(2),
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > p:nth-of-type(3),
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(3),
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > p:nth-of-type(4),
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(4),
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > p:nth-of-type(5),
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(5) {
        display: none;
    }
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > p:nth-of-type(2),
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(2) {
        margin-right: 0;
    }
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > p:nth-of-type(1),
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(1) {
        min-width: 47%;
    }
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > p:nth-of-type(6),
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(6) {
        min-width: 47%;
    }
}

@media screen and (max-width: 580px) {
    #rev-prem-charges .summary.coverage .custom-table-2::before {
        display: block;
    }
    #verifyEmailRevPrem {
        padding: 30px 15px;
    }
}

@media screen and (max-width: 561px) {
    #policy-header-details-form .form-loader .form-loader-container {
        margin-top: 90px;
    }
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li.body > div > div:nth-of-type(1),
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li.body > div > div:nth-of-type(2),
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li.body > div > div:nth-of-type(4),
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li.body > div > div:nth-of-type(6) {
        display: block;
    }
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li.body > div > div:first-of-type {
        margin-top: 0;
    }
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li.body > div > div:first-of-type > p {
        font-weight: bold;
    }
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > p:nth-of-type(6),
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(6) {
        display: none;
    }
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(1) {
        min-width: 100%;
    }
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(1) {
        margin-right: 0;
    }
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li.footer {
        display: none;
    }
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li {
        padding: 0 16px;
    }
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li:nth-of-type(even) {
        background-color: #eaeaea;
    }
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li > div {
        padding: 20px 0;
    }
}

@media screen and (max-width: 426px) {
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li.body > div > div {
        display: flex !important;
        justify-content: space-between;
        margin-top: 10px;
    }
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li.body > div > div > p:first-of-type {
        min-width: 165px;
        max-width: 165px;
        margin-right: 20px;
        margin-bottom: 0;
    }
    #rev-prem-charges.product-details .summary.dependents .custom-table > ul > li.body > div > div > p:last-of-type {
        text-align: right;
    }

    #rev-prem-charges.product-details .currency {
        margin-left: 0px;
        padding-right: 3px;
        cursor: text;
        border-right: 2px solid lightgray;
        display: inline-block;
        font-family: "Montserrat", "Arial", "Calibri", sans-serif;
        font-weight: 600;
        height: 30px;
        padding-top: 7px;
        /* color: gray; */
    }

    #rev-prem-charges.product-details .amount {
        margin-left: 0px;
        padding-left: 3px;
        cursor: text;
        display: inline-block;
        font-family: "Montserrat", "Arial", "Calibri", sans-serif;
        font-weight: 600;
        /* color: gray; */
    }
    
    #rev-prem-charges.product-details .checkbox {
        margin-top: 0%;
        padding-right: 0%;
    }

    #rev-prem-charges.product-details .currency-amount-container {
        /* margin-left: 17%; */
        float: right;
        margin-top: -2%;
    }
}

@media screen and (max-width: 350px) {

    #rev-prem-charges.product-details .currency-amount-container {
        float: right;
        margin-top: -2%;
    }

}