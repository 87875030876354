#dashboard .card-options .non-sp {
    display: flex;
    overflow-x: auto;
    padding-bottom: 20px;
    padding-top: 20px;
}
#dashboard .card-options .sp {
    display: none;
}
#dashboard .card-options .sp > ul {
    background-color: #fff;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 10%);
    border-radius: 3px;
    cursor: pointer;
}
#dashboard .card-options .sp > ul > li:not(.options) {
    padding: 15px 45px 15px 10px;
    border-radius: 3px;
    position: relative;
    font-weight: 600;
}
#dashboard .card-options .sp > ul > li:not(.options)::before {
    content: '';
    position: absolute;
    right: 17px;
    width: 20px;
    height: 20px;
    background-color: #D5B527;
    border-radius: 50%;
    background-image: url(../../../assets/images/common/icon-check-white.png);
    background-size: 13px;
    background-repeat: no-repeat;
    background-position: center;
}
#dashboard .card-options .sp > ul > li.options {
    background-color: #f0f0f0;
}
#dashboard .card-options .sp > ul > li.options > ul > li {
    padding: 12px 0;
    border-top: 1px solid #d6d6d6;
    margin: 0 10px;
    color: #888;
    font-weight: 600;
}
#dashboard .card-options .sp > ul > li.options > ul > li:first-of-type {
    border-top: unset;
}
#dashboard .card-options .sp > ul > li p {
    display: inline-block;
    width: 40px;
    text-align: center;
    border-right: 1px solid #9a9a9a;
    margin-right: 10px;
    padding-right: 5px;
}
#dashboard .card-options .non-sp .card {
    position: relative;
    padding: 20px 14px;
    box-sizing: border-box;
    margin-right: 16px;
    background-color: #fff;
    min-width: 176px;
    cursor: pointer;
    border: 1.5px solid #00000014;
    border-radius: 5px;
}
#dashboard .card-options .non-sp .card:last-of-type {
    margin-right: 0;
}
#dashboard .card-options .non-sp .card.top-border::before {
    height: 3px;
}
#dashboard .card-options .non-sp .card.selected::after {
    content: '';
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    background-color: #D5B527;
    border-radius: 50%;
    background-image: url(../../../assets/images/common/icon-check-white.png);
    background-size: 13px;
    background-repeat: no-repeat;
    background-position: center;
}
#dashboard .card-options .non-sp .card h3 {
    font-size: 14px;
}
#dashboard .card-options .non-sp .card p {
    font-size: 36px;
    font-weight: 600;
    margin-top: 10px;
    color: #B11116;
}
#dashboard .card-options .non-sp .card:first-of-type p {
    color: #1B1464;
}
#dashboard .card-options .non-sp .card:last-of-type p {
    color: #219653;
}
#dashboard .red {
    color: #B11116 !important;
}
#dashboard .card-options .pad-bot{
    padding-bottom: 5px;
}
#dashboard .card-options .pad-top{
    padding-top: 5px;
}
#dashboard .section-sub-title {
    margin-top: 30px;
}
#dashboard .applications .search-bar,
#dashboard .applications .paginator {
    background-color: #fff;
    width: 100%;
    padding: 8px 15px;
    box-sizing: border-box;
    color: #000;
    display: flex;
    border: 1.5px solid #00000014;
}
#dashboard .applications .search-bar {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-transform: capitalize;
}
#dashboard .applications .paginator {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: 20px;
    justify-content: flex-end;
}
#dashboard .applications .search-bar .table-menu-icon {
    min-width: 40px;
    background-color: #1B1464;
    margin-left: 15px;
    border-radius: 5px;
    background-image: url(../../../assets/images/common/icon-table-menu-white.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 23px;
    cursor: pointer;
    display: none;
}
#dashboard .applications .search-bar .search-menu,
#dashboard .applications .search-bar .table-menu {
    display: flex;
}
#dashboard .applications .search-bar .search-menu {
    width: 100%;
}
#dashboard .applications .search-bar .search {
    background-color: #f2f2f2;
    border-radius: 5px;
    display: flex;
    width: 100%;
}
#dashboard .applications .search-bar.no-sort .search {
    margin-right: 25px;
}
#dashboard .applications .search-bar.no-sort .mat-form-field {
    width: 170px;
}
#dashboard .applications .search-bar .mat-form-field-infix {
    width: 100%;
}
#dashboard .applications .search-bar > div {
    margin-right: 15px;
}
#dashboard .applications .search-bar > div:last-of-type {
    margin-right: 0;
}
#dashboard .applications .search-bar .search .icon {
    width: 50px;
    background-image: url(../../../assets/images/common/icon-search-blue.png);
    background-repeat: no-repeat;
    background-size: 23px;
    background-position: center;
}
#dashboard .applications .search-bar .search .mat-form-field {
    width: 100%;
}
#dashboard .applications .search-bar .search input::placeholder {
    color: #a0a0a0 !important;
}
#dashboard .applications .search-bar .filter,
#dashboard .applications .paginator > div {
    display: flex;
    align-items: center;
    min-width: 250px;
}
#dashboard .applications .paginator {
    justify-content: space-between;
}
#dashboard .applications .search-bar .sort {
    display: flex;
    align-items: center;
    max-width: 315px;
}
#dashboard .applications .search-bar .sort label {
    width: 76px;
}
#dashboard .applications .search-bar .sort .fields {
    display: flex;
    width: 100%;
}
#dashboard .applications .search-bar .sort .mat-form-field:last-of-type {
    max-width: 95px;
}
#dashboard .applications .search-bar label {
    margin-right: 10px;
}
#dashboard .applications .search-bar .mat-form-field {
    width: 150px;
}
#dashboard .applications .paginator .mat-form-field {
    width: 80px;
    margin: 0 10px;
}
#dashboard .applications .search-bar .mat-form-field > .mat-form-field-wrapper > .mat-form-field-flex,
#dashboard .applications .paginator .mat-form-field > .mat-form-field-wrapper > .mat-form-field-flex {
    height: 40px;
}
#dashboard .applications .search-bar .mat-form-field-appearance-fill .mat-form-field-infix,
#dashboard .applications .paginator .mat-form-field-appearance-fill .mat-form-field-infix {
    padding: 0;
    border-top: 5.5px solid transparent;
}
#dashboard .applications .search-bar .mat-form-field-appearance-fill .mat-select-arrow-wrapper,
#dashboard .applications .paginator .mat-form-field-appearance-fill .mat-select-arrow-wrapper {
    transform: translateY(0) !important;
}
#dashboard .applications .search-bar .mat-select-value-text, .mat-select-panel,
#dashboard .applications .paginator .mat-select-value-text, .mat-select-panel {
    color: #1B1464;
}
#dashboard .applications .search-bar .search .mat-form-field-wrapper > .mat-form-field-flex,
#dashboard .applications .paginator .mat-form-field-wrapper > .mat-form-field-flex {
    border: 2px solid #f2f2f2;
    background-color: #f2f2f2;
}
#dashboard .applications .paginator .left,
#dashboard .applications .paginator .right {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 7px;
    cursor: pointer;
    border-radius: 50%;
    transition: all ease .3s;
}
#dashboard .applications .paginator .left:hover,
#dashboard .applications .paginator .right:hover {
    background-color: #f2f2f2;
}
#dashboard .applications .paginator .left {
    margin-right: 13px;
    background-image: url(../../../assets/images/common/icon-arrow-left-gray.png);
}
#dashboard .applications .paginator .right {
    margin-left: 13px;
    background-image: url(../../../assets/images/common/icon-arrow-right-gray.png);
}
#dashboard .applications .records {
    overflow-x: auto;
    color: #000;
}
#dashboard .applications .records .cards {
    display: flex;
    margin: 15px 0;
}
#dashboard .applications .records .cards .card {
    background-color: #fff;
    border-radius: 5px;
    margin-right: 16px;
    padding: 19px 24px;
    box-sizing: border-box;
    border: 1.5px solid #00000014;
    font-size: 13px;
    line-height: 23px;
    width: 100%;
    min-width: 304px;
    max-width: 304px;
}
#dashboard .applications .records .cards .card:last-of-type {
    margin-right: 0;
}
#dashboard .applications .records .card.no-record {
    max-width: unset;
    height: 353.2px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
}
#dashboard .applications .records .cards .card p:nth-of-type(3) {
    font-size: 20px;
    color: #1B1464;
    font-weight: 700;
    margin-top: 10px;
    text-transform: uppercase;
    min-height: 46px;
}
#dashboard .applications .records .cards .card p:nth-of-type(4) {
    line-height: 21px;
    margin-top: 5px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    min-height: 41px;
}
#dashboard .applications .records .cards .card .date {
    margin-top: 10px;
}
#dashboard .applications .records .cards .card .status {
    margin-top: 10px;
    line-height: 21px;
}
#dashboard .applications .records .cards .card .status p:nth-of-type(2) {
    color: #219653;
    font-weight: 600;
}
#dashboard .applications .records .cards .card .status.pending p:nth-of-type(2) {
    color: #B11116;
}
#dashboard .applications .records .cards .card .btn {
    margin-top: 20px;
    font-size: 12px;
    font-family: "Montserrat", "Arial", "Calibri", sans-serif;
    text-align: left;
    padding: 10px 30px 10px 15px;
    min-width: 140px;
    position: relative;
}
#dashboard .applications .records .cards .card .btn::before {
    content: '';
    position: absolute;
    top: 11px;
    right: 0;
    width: 20px;
    height: 18px;
    background-image: url(../../../assets/images/common/icon-arrow-right-white.png);
    background-size: 7px;
    background-repeat: no-repeat;
}
#dashboard .applications .records .table {
    border-left: 1px solid #00000014;
    border-right: 1px solid #00000014;
    background-color: #fff;
    padding: 10px 15px 0;
    box-sizing: border-box;
}
#dashboard .applications .records .table .custom-table-2 > ul {
    padding-bottom: 10px;
    font-family: "ProximaNova", "Arial", "Calibri", sans-serif;
    min-height: 363.2px;
}
#dashboard .applications .records .table .custom-table-2 > ul.loading {
    overflow-x: clip;
}
#dashboard .applications .records .table .custom-table-2 > ul > li {
    min-width: 880px;
}
#dashboard .applications .records .table .custom-table-2 > ul > li.body {
    font-size: 14px;
    line-height: 20px;
    padding: 8px 16px;
    box-sizing: border-box;
    height: 56px;
    display: flex;
}
#dashboard .applications .records .table .custom-table-2 > ul > li .btn {
    min-width: 85px;
    font-size: 14px;
    padding: 9px 11px;
    border-radius: 3px;
}
#dashboard .applications .records .table .custom-table-2 > ul > li .name {
    font-weight: bold;
    color: #1B1464;
}
#dashboard .applications .records .table .custom-table-2 > ul > li.body .status {
    font-weight: bold;
    color: #219653;
}
#dashboard .applications .records .table .custom-table-2 > ul > li.body > div.non-sp .status {
    text-align: center;
}
#dashboard .applications .records .table .custom-table-2 > ul > li.body .pending {
    color: #B11116;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp > p {
    margin-right: 20px;
    font-size: 13px;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp > p.no-ps {
    margin-right: 40px !important;
    font-size: 13px;
}
#dashboard .applications .records .table .custom-table-2 > ul > li.header > div > p {
    text-align: center;
    font-weight: bold;
}
#dashboard .applications .records .table .custom-table-2:not(.no-sort) > ul > li.header > div > p:first-of-type:after {
    right: 5px;
}
#dashboard .applications .records .table .custom-table-2:not(.no-sort) > ul > li.header > div > p:nth-of-type(2):after {
    right: -8px;
}
#dashboard .applications .records .table .custom-table-2:not(.no-sort) > ul > li.header > div.w-referror > p:nth-of-type(2):after {
    right: -15px;
}
#dashboard .applications .records .table .custom-table-2:not(.no-sort) > ul > li.header > div > p:nth-of-type(3):after {
    right: -17px;
}
#dashboard .applications .records .table .custom-table-2:not(.no-sort) > ul > li.header > div.w-referror > p:nth-of-type(3):after {
    right: -19px;
}
#dashboard .applications .records .table .custom-table-2:not(.no-sort) > ul > li.header > div > p:nth-of-type(4):after,
#dashboard .applications .records .table .custom-table-2:not(.no-sort) > ul > li.header > div > p:nth-of-type(5):after {
    right: -16px;
}
#dashboard .applications .records .table .custom-table-2:not(.no-sort) > ul > li.header > div.w-referror > p:nth-of-type(4):after,
#dashboard .applications .records .table .custom-table-2:not(.no-sort) > ul > li.header > div.w-referror > p:nth-of-type(5):after {
    right: -18px;
}
#dashboard .applications .records .table .custom-table-2:not(.no-sort) > ul > li.header > div > p:nth-of-type(6):after {
    right: -18px;
}
#dashboard .applications .records .table .custom-table-2:not(.no-sort) > ul > li.header > div.w-referror > p:nth-of-type(6):after {
    right: -17px;
}
#dashboard .applications .records .table .custom-table-2:not(.no-sort) > ul > li.header > div > p:nth-of-type(7):after {
    right: -18px;
}
#dashboard .applications .records .table .custom-table-2:not(.no-sort) > ul > li.header > div.w-referror > p:nth-of-type(7):after {
    right: -17px;
}
#dashboard .applications .records .table .custom-table-2:not(.no-sort) > ul > li.header > div > p:nth-of-type(8):after {
    right: -18px;
}
#dashboard .applications .records .table .custom-table-2:not(.no-sort) > ul > li.header > div.w-referror > p:nth-of-type(8):after {
    right: -17px;
}
#dashboard .applications .records .table .custom-table-2:not(.no-sort) > ul > li.header > div > p:nth-of-type(9):after {
    right: -18px;
}
#dashboard .applications .records .table .custom-table-2:not(.no-sort) > ul > li.header > div.w-referror > p:nth-of-type(9):after {
    right: -17px;
}
#dashboard .applications .records .table .custom-table-2:not(.no-sort) > ul > li.header > div > p:nth-of-type(10):after {
    right: -18px;
}
#dashboard .applications .records .table .custom-table-2:not(.no-sort) > ul > li.header > div.w-referror > p:nth-of-type(10):after {
    right: -17px;
}
#dashboard .applications .records .table .custom-table-2:not(.no-sort) > ul > li.header {
    padding-right: 16px;
    border-bottom: 1px solid #00000014;
    margin-bottom: 10px;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp {
    padding-left: 146px;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp.w-referror {
    padding-left: 121px;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.sp {
    display: none;
    flex-wrap: wrap;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.sp > div {
    width: 48%;
    margin-right: 4%;
    margin-top: 15px;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.sp > div:nth-of-type(1),
#dashboard .applications .records .table .custom-table-2 > ul > li > div.sp > div:nth-of-type(2) {
    margin-top: 0;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.sp > div:nth-of-type(even) {
    margin-right: 0;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.sp > div > p:first-of-type {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 5px;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.sp > div.button {
    display: flex;
    align-items: center;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.sp.w-referror > div.button {
    width: 100%;
    margin-right: 0;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.sp > div.button button {
    width: 100%;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp > p.button {
    padding: 0px 15px 0 16px;
    position: absolute;
    right: -20px;
    display: flex;
    align-items: center;
    z-index: 1;
    background-color: #f8f8f8;
    border-left: 1px solid;
    height: 40px;
}
#dashboard .applications .records .table .custom-table-2 > ul > li:nth-of-type(odd) > div.non-sp > p.button {
    background-color: #fff;
}
#dashboard .applications .records .table .custom-table-2 > ul > li.header > div.non-sp > p.button {
    min-width: 85px;
    border-left: unset;
}
#dashboard .applications .records .table .custom-table-2 > ul > li.header > div.non-sp > p.button::after {
    display: none;
}
#dashboard .applications .records .table .custom-table-2 > ul > li.header > div.non-sp > p:nth-of-type(1) {
    height: 32px;
    background-color: #fff;
}
#dashboard .applications .records .table .custom-table-2 > ul > li.body > div.non-sp > p:nth-of-type(1) {
    height: 40px;
    border-right: 1px solid;
    background-color: #f8f8f8;
}
#dashboard .applications .records .table .custom-table-2 > ul > li.body:nth-of-type(odd) > div.non-sp > p:nth-of-type(1) {
    background-color: #fff;
}

/* SET CUSTOM-TABLE COLUMNS WIDTH - START */
#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp > p:nth-of-type(1) {
    min-width: 125px;
    max-width: 125px;
    padding: 0px 10px 0 16px;
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    z-index: 1;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp > p.no-ps:nth-of-type(1) {
    min-width: 170px !important;
    max-width: 170px !important;
    padding: 0px 16px 0 20px !important;
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    z-index: 1;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp > p.no-ps:nth-of-type(2) {
    margin-left: 60px !important;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp.w-referror > p:nth-of-type(1) {
    min-width: 125px;
    max-width: 125px;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp > p:nth-of-type(2) {
    min-width: 100px;
    max-width: 100px;
    justify-content: center;
    text-align: center;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp.w-referror > p:nth-of-type(2) {
    min-width: 120px;
    max-width: 120px;
    justify-content: center;
    text-align: center;
    margin-left: 10px;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp > p:nth-of-type(3) {
    min-width: 85px;
    max-width: 85px;
    justify-content: center;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp.w-referror > p:nth-of-type(3) {
    min-width: 85px;
    max-width: 85px;
    justify-content: center;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp > p:nth-of-type(4) {
    min-width: 60px;
    max-width: 60px;
    justify-content: center;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp.w-referror > p:nth-of-type(4) {
    min-width: 60px;
    max-width: 60px;
    justify-content: center;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp > p:nth-of-type(5) {
    min-width: 75px;
    max-width: 75px;
    justify-content: center;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp.w-referror > p:nth-of-type(5) {
    min-width: 75px;
    max-width: 75px;
    justify-content: center;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp > p:nth-of-type(6) {
    min-width: 90px;
    max-width: 90px;
    justify-content: center;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp.w-referror > p:nth-of-type(6) {
    min-width: 90px;
    max-width: 90px;
    justify-content: center;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp > p:nth-of-type(8) {
    min-width: 90px;
    max-width: 90px;
    justify-content: center;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp.w-referror > p:nth-of-type(8) {
    min-width: 90px;
    max-width: 90px;
    justify-content: center;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp > p:nth-of-type(9) {
    min-width: 90px;
    max-width: 90px;
    justify-content: center;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp.w-referror > p:nth-of-type(9) {
    min-width: 90px;
    max-width: 90px;
    justify-content: center;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp > p:nth-of-type(10) {
    min-width: 90px;
    max-width: 90px;
    justify-content: center;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp.w-referror > p:nth-of-type(10) {
    min-width: 150px;
    max-width: 150px;
    justify-content: center;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp > p:nth-of-type(11) {
    width: 90px;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp.w-referror > p:nth-of-type(11) {
    width: 80px;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp > p.referror {
    min-width: 75px;
    max-width: 75px;
    justify-content: center;
    text-align: center;
}
#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp > p.status {
    min-width: 105px;
    max-width: 105px;
    justify-content: center;
}
#dashboard .no-left {
    border-left: 0px !important;
}

#dashboard .agent-mobile-view{
    margin-top: 15px;
}

#dashboard .agent-mobile-div{
    display:contents !important;
}

#dashboard .pad-cancel{
    padding: 2.5px !important;
}

#dashboard .applications .records .table .custom-table-2 > ul > li.body {
    display: inline-flex;
}

/* SET CUSTOM-TABLE COLUMNS WIDTH - END */

@media screen and (max-width: 955px) {
    #dashboard .applications .search-bar > div,
    #dashboard .applications .search-bar.no-sort .search {
        margin-right: 0;
    }
    #dashboard .applications .search-bar,
    #dashboard .applications .search-bar .table-menu-icon {
        display: block;
    }
    #dashboard .applications .search-bar .table-menu:not(.show) {
        display: none;
    }
    #dashboard .applications .search-bar .table-menu {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
    }
    #dashboard .applications .search-bar.no-sort .filter,
    #dashboard .applications .search-bar.no-sort .mat-form-field {
        width: 100%;
    }
    #dashboard .applications .search-bar:not(.no-sort) .filter {
        margin-right: 30px;
    }
    #dashboard .applications .search-bar:not(.no-sort) .mat-form-field:first-of-type {
        width: 100%;
        max-width: 175px;
    }
    #dashboard .applications .search-bar .sort .mat-form-field:first-of-type {
        width: 167px;
    }
    #dashboard .applications .search-bar:not(.no-sort) .search .mat-form-field:first-of-type {
        max-width: unset;
    }
    #dashboard .applications .search-bar .filter label {
        min-width: 85px;
    }
    #dashboard .applications .search-bar .sort {
        max-width: 344px;
    }
}

@media screen and (max-width: 875px) {
    #dashboard .section-title h2 {
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 736px) {
    #dashboard .applications .search-bar .table-menu {
        display: block;
    }
    #dashboard .applications .search-bar .table-menu > div {
        margin-right: 0 !important;
    }
    #dashboard .applications .search-bar .table-menu > div > label {
        min-width: 85px;
    }
    #dashboard .applications .search-bar .table-menu .filter .mat-form-field,
    #dashboard .applications .search-bar .table-menu .sort .mat-form-field:first-of-type {
        max-width: unset;
    }
    #dashboard .applications .search-bar .sort {
        max-width: unset;
    }
    #dashboard .applications .paginator {
        flex-direction: column;
        align-items: center;
    }
    #dashboard .applications .paginator > div {
        justify-content: center;
    }
    #dashboard .applications .paginator > div:first-of-type {
        margin: 10px 0 20px;
    }
    #dashboard .applications .search-bar .sort .mat-form-field:first-of-type {
        width: 100%;
    }
}

@media screen and (max-width: 471px) {
    #dashboard .applications .search-bar .sort {
        align-items: baseline;
    }
    #dashboard .applications .search-bar .sort .fields {
        display: block;
    }
}

@media screen and (max-width: 426px) {
    #dashboard .section-title h2 {
        margin-bottom: 15px;
    }
    #dashboard .card-options .non-sp {
        display: none;
    }
    #dashboard .card-options .sp {
        display: block;
    }
    #dashboard .applications .search-bar {
        padding: 8px;
    }
    #dashboard .applications .search-bar .table-menu-icon {
        margin-left: 8px;
    }
    #dashboard .applications .records .cards {
        flex-direction: column;
    }
    #dashboard .applications .records .cards {
        margin: 5px 0;
    }
    #dashboard .applications .records .cards .card {
        margin-right: 0;
        margin-bottom: 5px;
        font-size: 14px;
        max-width: unset;
        min-width: unset;
        padding: 19px;
    }
    #dashboard .applications .records .cards .card:last-of-type {
        margin-bottom: 0;
    }
    #dashboard .applications .records .cards .card p:nth-of-type(3),
    #dashboard .applications .records .cards .card p:nth-of-type(4) {
        min-height: unset;
    }
    #dashboard .applications .records .cards .card p:nth-of-type(4) {
        font-size: 13px;
    }
    #dashboard .applications .records .cards .card .date {
        margin-top: 15px;
    }
    #dashboard .applications .records .cards .card .btn {
        width: 100%;
        font-size: 14px;
    }
    #dashboard .applications .records .cards .card .btn::before {
        top: 12px;
        width: 25px;
    }
    #dashboard .applications .records .table {
        padding: 10px;
    }
    #dashboard .applications .records .table .custom-table-2 > ul > li {
        min-width: unset;
    }
    #dashboard .applications .records .table .custom-table-2 > ul > li.body {
        height: unset;
        font-size: 16px;
        padding: 20px 16px;
    }
    #dashboard .applications .records .table .custom-table-2 > ul > li:nth-of-type(odd) {
        background-color: #f8f8f8;
    }
    #dashboard .applications .records .table .custom-table-2 > ul > li:nth-of-type(even) {
        background-color: #fff;
    }
    #dashboard .applications .records .table .custom-table-2 > ul > li .btn {
        padding: 11px;
    }
    #dashboard .applications .records .table .custom-table-2 > ul > li.header,
    #dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp {
        display: none;
    }
    #dashboard .applications .records .table .custom-table-2 > ul > li > div.sp {
        display: flex;
    }
}

@media screen and (max-width: 376px) {
    #dashboard .applications .paginator {
        font-size: 14px;
    }
}

@media screen and (max-width: 361px) {
    #dashboard .applications .records .table .custom-table-2 > ul > li > div.sp > div > p:first-of-type {
        font-size: 14px;
    }
}

@media screen and (max-width: 331px) {
    #dashboard .applications .paginator .left,
    #dashboard .applications .paginator .right {
        width: 15px;
    }
}

#dashboard .mat-tab-group {
    margin-bottom: 24px;
}

#dashboard .mat-tab-label{
display: block;
font-size: 1.17em;
margin-left: 0;
margin-right: 0;
font-weight: bold!important;
justify-content: left !important;
} 

#dashboard .applications .records .table .custom-table-2 > ul > li > div.non-sp > p.psCheckbox {
    min-width: 50px;
    max-width: 50px;
    padding: 0px 10px 0 16px;
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    z-index: 1;
}

#dashboard .nameBorder:after {
    right: 5px !important;
}

#dashboard .smaller-text{
    font-size: smaller !important;
}

#dashboard #allCheck{
    margin-left: 20px;
}

#dashboard #button-icon{
    padding-right: 5px;
    display: inline !important;
    font-size: 20px;
    vertical-align: middle;
}
#dashboard .centered{
    text-align: center;
}
#dashboard .fixed-width{
    width: 176px;
}

#cancel-red-mark  {
    color: #B11116;
}
#cancel-text  {
    margin-bottom: 15px;
}