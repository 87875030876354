#app-message {
    position: fixed;
    top: 70px;
    width: 100%;
    z-index: 3;
}
#app-message .container,
#app-message .message-type .icon {
    position: relative
}
#app-message .message-container {
    position: absolute;
    top: -40px;
    right: 0;
    opacity: 0;
    background-color: #fff;
    min-width: 250px;
    max-width: 350px;
    box-shadow: 0px 1px 2px rgba(27, 20, 100, 0.24);
    transition: all ease .3s;
}
#app-message.show .message-container {
    top: 0;
    opacity: 1;
}
#app-message p {
    padding: 10px 10px 10px 55px;
    box-sizing: border-box;
    line-height: 22px;
    cursor: default;
}
#app-message .message-type {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 45px;
    display: flex;
    align-items: center;
}
#app-message .message-type.success {
    background-color: #219653;
}
#app-message .message-type.error {
    background-color: #b11116;
}
#app-message .message-type.info {
    background-color: #4a4a4a;
}
#app-message .message-type .icon::before,
#app-message .message-type .icon::after {
    content: '';
    position: absolute;
    border-radius: 3px;
    background-color: #fff;
}

/* SUCCESS ICON */
#app-message .message-type.success .icon::before {
    top: 1px;
    left: 12px;
    width: 9px;
    height: 2px;
    transform: translateY(0) rotate(45deg);
}
#app-message .message-type.success .icon::after {
    top: -2px;
    left: 16px;
    width: 19px;
    height: 2px;
    transform: translateY(0) rotate(-45deg);
}

/* ERROR ICON */
#app-message .message-type.error .icon::before {
    top: -1px;
    left: 12px;
    width: 20px;
    height: 2px;
    transform: translateY(0) rotate(45deg);
}
#app-message .message-type.error .icon::after {
    top: -1px;
    left: 12px;
    width: 20px;
    height: 2px;
    transform: translateY(0) rotate(-45deg);
}

/* INFO ICON */
#app-message .message-type.info .icon::before {
    top: -9px;
    left: 21px;
    width: 4px;
    height: 3px;
}
#app-message .message-type.info .icon::after {
    top: 2px;
    left: 17.5px;
    width: 11px;
    height: 3px;
    transform: translateY(0) rotate(90deg);
}