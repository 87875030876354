#header {
    position: fixed;
    top: 0;
    z-index: 999;
    min-width: 320px;
}
#header .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    position: relative;
}
#header .nav-logo {
    cursor: default;
    height: 100%;
    display: flex;
    align-items: center;
    transition: unset;
}
#header.style1 .nav-logo {
    width: 165px;
    margin-right: 7px;
}
#header.style2 .nav-logo {
    width: 155px;
    margin-right: 15px;
}
#header #chk-sp-menu-trigger,
#header #chk-sub-branches-trigger,
#header #chk-sub-user-trigger {
    display: none;
}
#header nav,
#header nav > div,
#header nav > div > ul,
#header nav > div > ul > li > a {
    display: flex;
    height: 100%;
}
#header nav > .nav-right > ul > li:not(.parent-menu) {
    display: flex;
    align-items: center;
}
#header nav {
    justify-content: space-between;
    width: 100%;
}
#header nav li:focus {
    outline: none;
}
#header nav > div > ul > li:last-child {
    margin-right: 0;
}
#header nav > div > ul > li > a {
    height: 100%;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    align-items: center;
    padding: 0 17px;
}
#header nav > div > ul > li.parent-menu {
    position: relative;
    user-select: none;
}
#header nav > div > ul > li.parent-menu > a {
    padding-right: 30px;
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 11px;
}
#header nav > div > ul > li.parent-menu > ul.sub-menu {
    position: absolute;
    min-width: 160px;
    z-index: 1001;
    background-color: #b11116;
    display: none;
}
#header nav > div > ul > li.parent-menu > ul.sub-menu.user-menu {
    right: 0;
}
#header nav > div > ul > li.parent-menu:hover > ul.sub-menu {
    display: block;
}
#header nav > div > ul > li.parent-menu > ul.sub-menu > li {
    position: relative;
    padding: 15px 10px;
    cursor: pointer;
}
#header nav > div > ul > li.parent-menu > ul.sub-menu > li::after {
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    bottom: -1px;
}
#header nav > div > ul > li.parent-menu > ul.sub-menu > li:last-child:after {
    border-bottom: none !important;
}
#header nav .nav-right .btn-reg {
    height: 36px;
    border-radius: 5px;
}
#header nav .nav-right .btn-login {
    height: 36px;
    min-width: 80px;
    padding-left: 18px;
}
#header nav > div.nav-right > ul > li {
    margin-right: 15px;
}
#header nav > div.nav-right > ul > li:last-child {
    margin-right: 0;
}
#header.style1 {
    background-color: #b11116;
    color: #fff;
}
#header.style1 nav > div > ul > li > a {
    color: #fff;
}
#header.style1 nav > div.nav-left > ul > li > a:hover {
    background-color: #8b0f13;
}
#header.style1 nav > div > ul > li.parent-menu > ul.sub-menu {
    background-color: #b11116;
}
#header.style1 nav > div > ul > li.parent-menu > ul.sub-menu > li::after {
    border-bottom: 2px solid #8b0f13;
}
#header.style1 nav > div > ul > li.parent-menu > ul.sub-menu > li:hover {
    background-color: #8b0f13;
}
#header.style1 nav > div > ul > li.parent-menu > ul.sub-menu > li > a {
    color: #fff;
}
#header.style1 nav .nav-right .btn-reg:hover {
    background-color: #8b0f13;
}
#header.style1 nav .nav-right .btn-login {
    color: #b11116;
}
#header.style1 nav > div > ul > li.parent-menu > a {
    background-image: url(../../../assets/images/common/icon-arrow-down-white.png);
}
#header.style2 {
    background-color: #fff;
    color: #b11116;
}
#header.style2 nav > div > ul > li > a {
    color: #b11116;
}
#header.style2 nav > div.nav-left > ul > li > a:hover {
    background-color: #f0f0f0;
}
#header.style2 nav > div > ul > li.parent-menu > ul.sub-menu {
    background-color: #fff;
    box-shadow: 0px 1px 2px rgba(27, 20, 100, 0.24);
}
#header.style2 nav > div > ul > li.parent-menu > ul.sub-menu > li::after {
    border-bottom: 2px solid #f0f0f0;
}
#header.style2 nav > div > ul > li.parent-menu > ul.sub-menu > li:hover {
    background-color: #f0f0f0;
}
#header.style2 nav > div > ul > li.parent-menu > ul.sub-menu > li > a {
    color: #b11116;
}
#header.style2 nav .nav-right .btn-reg:hover {
    background-color: #f0f0f0;
}
#header.style2 nav .nav-right .btn-login {
    color: #fff;
    background-color: #b11114;
    border: 1px solid #b11114;
}
#header.style2 nav .nav-right .btn-login:hover {
    background-color: #6c0d0f;
    border: 1px solid #6c0d0f;
}
#header.style2 nav > div > ul > li.parent-menu > a {
    background-image: url(../../../assets/images/common/icon-arrow-down-red.png);
}
#header nav > .nav-mobile {
    position: fixed;
    top: 0;
    left: 100%;
    right: 0;
    bottom: 0;
    z-index: 999;
    min-width: 320px;
    opacity: 0;
    display: block;
    box-sizing: border-box;
    padding-top: 60px;
    overflow-y: auto;
    overflow-x: hidden;
    transition: all ease .3s;
}
#header .sp-menu-container {
    display: none;
}

@media screen and (max-width: 951px) {
    #header.style1 .nav-logo {
        width: 130px;
    }
    #header.style2 .nav-logo {
        width: 118px;
    }
    #header nav {
        width: unset;
    }
    #header .sp-menu-container {
        height: 100%;
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
    }
    #header .sp-menu-container .btn-login {
        min-width: 110px;
        padding: 10px 11px;
        margin-right: 15px;
    }
    #header.style2 .sp-menu-container .btn-login {
        background-color: #1B1464;
        color: #FFF;
        border-color: #1B1464;
        transition: background-color ease .3s, color ease .3s, border-color ease .3s;
    }
    #header.style2 .sp-menu-container .btn-login:hover {
        background-color: #110d36;
	    border-color: #110d36;
    }
    #header .sp-menu-container .user-menu {
        position: relative;
        height: 100%;
        margin-right: 5px;
        z-index: 999;
        user-select: none;
    }
    #header .sp-menu-container .user-menu > a {
        font-weight: bold;
        text-transform: uppercase;
        padding: 0 30px 0 15px;
        background-position: right 10px center;
        background-repeat: no-repeat;
        background-size: 11px;
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    #header.style1 .sp-menu-container .user-menu > a {
        color: #fff;
        background-image: url(../../../assets/images/common/icon-arrow-down-white.png);
    }
    #header.style2 .sp-menu-container .user-menu > a {
        color: #1b1464;
        background-image: url(../../../assets/images/common/icon-arrow-down-blue.png);
    }
    #header .sp-menu-container .user-menu > ul {
        position: absolute;
        right: 0;
        box-shadow: 0px 1px 2px rgb(27 20 100 / 24%);
        min-width: 160px;
        display: none;
    }
    #header .sp-menu-container .user-menu:hover > ul.show {
        display: block;
    }
    #header.style1 .sp-menu-container .user-menu > ul {
        background-color: #b11116;
    }
    #header.style2 .sp-menu-container .user-menu > ul {
        background-color: #fff;
    }
    #header .sp-menu-container .user-menu > ul li {
        padding: 15px 10px;
        cursor: pointer;
    }
    #header.style1 .sp-menu-container .user-menu > ul li:hover {
        background-color: #8b0f13;
    }
    #header.style2 .sp-menu-container .user-menu > ul li:hover {
        background-color: #f0f0f0;
    }
    #header.style1 .sp-menu-container .user-menu > ul li a {
        color: #fff;
    }
    #header.style2 .sp-menu-container .user-menu > ul li a {
        color: #1b1464;
    }
    #header .sp-menu-container .sp-menu {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        z-index: 1000;
    }
    #header .sp-menu-container .sp-menu div {
        width: 75%;
        height: 3px;
        border-radius: 3px;
        position: relative;
    }
    #header .sp-menu-container .sp-menu div::before,
    #header .sp-menu-container .sp-menu div::after {
        content: '';
        position: absolute;
        width: 100%;
        height: inherit;
        border-radius: 3px;
        transition: transform ease .3s;
    }
    #header .sp-menu-container .sp-menu div::before {
        transform: translateY(-8px);
    }
    #header .sp-menu-container .sp-menu div::after {
        transform: translateY(8px);
    }
    #header.style1 .sp-menu-container .sp-menu div {
        background-color: #fff;
    }
    #header.style1 .sp-menu-container .sp-menu div::before,
    #header.style1 .sp-menu-container .sp-menu div::after {
        background-color: #fff;
    }
    #header.style1 nav > .nav-mobile {
        background-color: #58080b;
    }
    #header.style2 nav > .nav-mobile {
        background-color: #dbdbdb;
    }
    #header.style2 .sp-menu-container .sp-menu div,
    #header.style2 .sp-menu-container .sp-menu div::before,
    #header.style2 .sp-menu-container .sp-menu div::after {
        background-color: #1B1464;
    }
    #header.style2 nav > div > ul > li a {
        color: #1b1464 !important;
    }
    #header.style2 nav > div > ul > li.parent-menu > a {
        background-image: url(../../../assets/images/common/icon-arrow-down-blue.png);
    }
    #header #chk-sp-menu-trigger {
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 40px;
        height: 40px;
        z-index: 1001;
        cursor: pointer;
        opacity: 0;
    }
    #header #chk-sp-menu-trigger:checked ~ .sp-menu-container .sp-menu div {
        background-color: transparent;
    }
    #header #chk-sp-menu-trigger:checked ~ .sp-menu-container .sp-menu div::before {
        transform: translateY(0) rotate(-45deg);
    }
    #header #chk-sp-menu-trigger:checked ~ .sp-menu-container .sp-menu div::after {
        transform: translateY(0) rotate(45deg);
    }
    #header #chk-sp-menu-trigger:checked ~ nav > .nav-mobile {
        left: 0;
        opacity: 0.95;
    }
    #header nav > .nav-left,
    #header nav > .nav-right {
        display: none;
    }
    #header nav > .nav-mobile > ul,
    #header nav > .nav-mobile > ul > li > a {
        display: block;
        height: unset;
    }
    #header nav > .nav-mobile > ul > li > a {
        padding: 15px 25px;
    }
    #header nav > .nav-mobile > ul > li.parent-menu > a {
        background-position: right 38px center;
    }
    #header nav > .nav-mobile > ul > li.parent-menu > ul.sub-menu {
        position: relative;
    }
    #header nav > .nav-mobile > ul > li.parent-menu > ul.sub-menu > li {
        padding: 15px 40px;
    }
    #header #chk-sub-branches-trigger:checked ~ ul.sub-menu,
    #header #chk-sub-user-trigger:checked ~ ul.sub-menu {
        display: block;
    }
    #header #chk-sub-branches-trigger:not(:checked) ~ ul.sub-menu,
    #header #chk-sub-user-trigger:not(:checked) ~ ul.sub-menu {
        display: none;
    }
}

@media screen and (max-width: 426px) {
	#header.style2 .sp-menu-container .btn-login:hover {
		background-color: #1B1464;
		border-color: #1B1464;
	}
    #header .sp-menu-container .user-menu > ul {
        right: -45px;
        min-width: 190px;
    }
}

@media screen and (max-width: 380px) {
    #header .sp-menu-container .user-menu > a {
        font-size: 14px;
    }
    #header .sp-menu-container .user-menu > ul {
        min-width: 160px;
    }
}

@media screen and (max-width: 374px) {
    #header .sp-menu-container .btn-login {
        min-width: 85px;
        margin-right: 10px;
    }
}

@media screen and (max-width: 350px) {
    #header .sp-menu-container .user-menu {
        margin-right: 0;
    }
    #header .sp-menu-container .user-menu > a {
        font-size: 13px;
    }
    #header .sp-menu-container .user-menu > ul {
        min-width: 128px;
    }
}