#home .container {
    padding-top: 29px;
}
#home .bx-wrapper {
    border: unset;
    box-shadow: unset;
    margin-bottom: 50px;
    border-radius: 5px;
    background-color: #EEECE7;
}
#home .bx-wrapper .bx-viewport {
    height: 200px !important;
}
#home .bx-wrapper .bx-controls.bx-has-pager {
    position: relative;
    top: -45px;
}
#home .bx-wrapper img {
    float: right;
    width: 650px;
    height: 200px;
}
#home .bx-wrapper .banner-text {
    position: absolute;
    font-weight: 300;
    font-size: 32px;
    line-height: 48px;
    color: #1B1464;
    padding: 53px 0 53px 48px;
    z-index: 1;
}
#home .bx-wrapper .banner-text span {
    font-weight: 600;
}
#home .banner div::after {
    content: '';
    position: absolute;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #eeece7 100%);
    height: 100%;
    width: 340px;
    right: 310px;
}
#home .product-content {
    display: flex;
    min-height: 580px;
}
#home .choices {
    min-width: 248px;
    margin-right: 40px;
}
#home .choices > h2 {
    font-weight: 300;
    font-size: 32px;
    color: #4A4A4A;
}
#home .choices .purpose .title {
    margin: 26px 0 10px 0;
    font-weight: 300;
    font-size: 16px;
    color: #4A4A4A;
    text-transform: uppercase;
}
#home .choices .purpose .purpose-list {
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 10%);
    padding: 10px 0;
    margin-bottom: 34px;
}
#home .choices .purpose .purpose-list li {
    padding: 6px 15px;
    cursor: pointer;
    margin-bottom: 6px;
}
#home .choices .purpose .purpose-list li.selected a {
    color: #1B1464;
    font-weight: bold;
}
#home .choices .purpose .purpose-list li:last-of-type {
    margin-bottom: 0;
}
#home .choices .purpose .purpose-list li a {
    color: #888888;
    font-weight: 400;
    font-size: 16px;
}
#home .choices .products ul:not(.select-view) li {
    background: #FFFFFF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    margin-bottom: 8px;
    cursor: pointer;
    position: relative;
}
#home .choices .products ul:not(.select-view) li.selected::before,
#home .choices .products ul:not(.select-view) li:hover:not(.selected)::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 2px;
    border-radius: 3px;
    height: 100%;
    background: #B11116;
}
#home .choices .products ul:not(.select-view) li:hover:not(.selected)::before {
    background: #1B1464;
}
#home .choices .products ul:not(.select-view) li a {
    color: #888888;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    display: flex;
    align-items: center;
    padding: 10px 0px;
}
#home .choices .products ul:not(.select-view) li.selected a {
    color: #1B1464;
    background-position: right 17px center;
    background-repeat: no-repeat;
    background-size: 15px;
    background-image: url(../../../assets/images/common/icon-check-red.png);
    transition: none;
}
#home .choices .products ul:not(.select-view) li a img {
    width: 20px;
    height: 20px;
    margin: 0 13px 0 10px;
}
#home .choices .products ul:not(.select-view) li a img.hide {
    display: none;
}
#home .content {
    width: 100%;
}
#home .content .result {
    height: 48.67px;
    text-align: right;
    padding-top: 16px;
    box-sizing: border-box;
    margin-bottom: 26px;
}
#home .content .case-wrap .pack {
    background: #FFFFFF;
    margin-bottom: 30px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 10%);
}
#home .content .case-wrap .pack img {
    height: 100%;
    width: 240px;
    position: absolute;
    left: 0;
    top: 0;
}
#home .content .case-wrap .pack .item {
    width: 416px;
    padding: 18px 40px;
    box-sizing: border-box;
    z-index: 1;
    background: #fff;
}
#home .content .case-wrap .pack .item .description {
    min-height: 110px;
    margin-bottom: 20px;
}
#home .content .case-wrap .pack .item .description .tit-case {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #1B1464;
    margin-bottom: 15px;
}
#home .content .case-wrap .pack .item .description p {
    color: #4A4A4A;
    line-height: 20px;
    text-align: justify;
}
#home .content .case-wrap .pack .item .info-wrap {
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#home .content .case-wrap .pack .item .info-wrap.one {
    justify-content: right;
}
#home .content .case-wrap .pack .item .info-wrap .btn-avail {
    height: 44px;
    min-width: 120px;
    padding: 11px;
}
#home .content .case-wrap .pack .item .info-wrap .btn-link {
    font-weight: 600;
    color: #1B1464;
    letter-spacing: 1px;
    cursor: pointer;
}
#home .choices .products ul.select-view {
    background-color: #fff;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 10%);
    border-radius: 3px;
    cursor: pointer;
    display: none;
}
#home .choices .products ul.select-view #selected-product.renpayt a span {
    color: #888;
}
#home .choices .products ul.select-view #selected-product {
    padding: 15px 45px 15px 10px;
    border-radius: 3px;
    position: relative;
}
#home .choices .products ul.select-view #selected-product a {
    display: flex;
    align-items: center;
}
#home .choices .products ul.select-view #selected-product a img {
    height: 20px;
    width: 20px;
    margin-left: 5px;
    margin-top: 2px;
    margin-bottom: 2px;
}
#home .choices .products ul.select-view #selected-product a span {
    font-size: 17px;
}
#home .choices .products ul.select-view #selected-product::before {
    content: '';
    position: absolute;
    right: 17px;
    width: 15px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 12px;
    background-image: url(../../../assets/images/common/icon-arrow-down-red.png);
}
#home .choices .products ul.select-view #selected-product-2 {
    padding: 15px 45px 15px 10px;
    border-radius: 3px;
    position: relative;
}
#home .choices .products ul.select-view #selected-product-2 a {
    display: flex;
    align-items: center;
}
#home .choices .products ul.select-view #selected-product-2 a img {
    height: 20px;
    width: 20px;
    margin-left: 5px;
    margin-top: 2px;
    margin-bottom: 2px;
}
#home .choices .products ul.select-view #selected-product-2 a span {
    font-size: 17px;
}
#home .choices .products ul.select-view #selected-product-2::before {
    content: '';
    position: absolute;
    right: 17px;
    width: 15px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 12px;
    /* background-image: url(../../../assets/images/common/icon-arrow-down-red.png); */
}
#home .choices .products ul.select-view #selected-product-2.selected a span {
    color: #1B1464;
}
#home .choices .products ul.select-view .select-product-list {
    background-color: #f0f0f0;
}
#home .choices .products ul.select-view .select-product-list li {
    padding: 12px 0;
    border-top: 1px solid #d6d6d6;
    margin: 0 10px;
}
#home .choices .products ul.select-view .select-product-list li:first-of-type {
    border-top: unset;
}
#home .choices .products ul.select-view li a img {
    height: 20px;
    width: 20px;
    margin-right: 13px;
    margin-left: 5px;
}
#home .choices .products ul.select-view li a span {
    font-size: 16px;
    color: #888;
    font-weight: bold;
}
#home .choices .products ul.select-view #selected-product a span {
    color: #1B1464;
}
#home .choices .products .payt-renewal {
    margin-top: 34px;
}

@media screen and (max-width: 931px) {
    #home .content .case-wrap .pack .item {
        width: 356px;
        padding: 23px 26px;
    }
}

@media screen and (max-width: 871px) {
    #home .content .case-wrap .pack .item {
        width: 298px;
        padding: 18px 20px;
    }
    #home .content .case-wrap .pack .item .description .tit-case {
        font-size: 22px;
        margin-bottom: 13px;
    }
    #home .content .case-wrap .pack .item .info-wrap .btn-avail {
        height: 45px;
        min-width: 125px;
        font-size: 16px;
    }
    #home .content .case-wrap .pack .item .info-wrap .btn-link {
        font-size: 16px;
        letter-spacing: unset;
    }
}

@media screen and (max-width: 801px) {
    #home .choices {
        min-width: 240px;
        margin-right: 25px;
    }
    #home .choices > h2 {
        font-size: 35px;
    }
    #home .choices .products ul:not(.select-view) li a img {
        width: 18px;
        height: 18px;
        margin: 0 13px 0 13px;
    }
    #home .choices .purpose .title,
    #home .choices .purpose .purpose-list li a,
    #home .choices .products ul:not(.select-view) li a {
        font-size: 15px;
    }
    #home .content .result {
        height: 40px;
        padding-top: 20px;
        font-size: 15px;
    }
    #home .content .case-wrap .pack img {
        width: 230px;
    }
    #home .content .case-wrap .pack .item {
        width: 280px;
        padding: 14px 18px 18px;
    }
    #home .content .case-wrap .pack .item .description .tit-case {
        font-size: 21px;
        margin-bottom: 12px;
    }
}

@media screen and (max-width: 769px) {
    /* DONE */
    #home .banner div::after {
        width: 490px;
        right: 160px;
    }
}

@media screen and (max-width: 701px) {
    #home .content .case-wrap .pack {
        overflow: hidden;
        min-height: 390px;
    }
    #home .content .case-wrap .pack img {
        width: 100%;
        position: relative;
        top: -20px;
    }
    #home .content .case-wrap .pack.COM img {
        top: -145px;
    }
    #home .content .case-wrap .pack .item {
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}

@media screen and (max-width: 700px) {
    #home .bx-wrapper .bx-controls.bx-has-pager {
        top: -200px;
    }
    #home .banner div::after {
        background: linear-gradient(195deg, rgba(255, 255, 255, 0) 0%, #eeece7 100%);
        height: 200px;
        right: unset;
        width: 100%;
        bottom: 0;
    }
    #home .bx-wrapper .banner-text {
        font-size: 25px;
        padding: 120px 0 10px 20px;
        line-height: 1.3;
    }
}

@media screen and (max-width: 590px) {
    #home .bx-wrapper {
        margin-bottom: 25px;
    }
    #home .product-content {
        display: block;
    }
    #home .choices {
        margin-right: 0;
    }
    #home .choices .purpose .purpose-list {
        margin-bottom: 10px;
    }
    #home .content .case-wrap .pack {
        min-height: unset;
        margin-bottom: 20px;
    }
    #home .content .case-wrap .pack img,
    #home .content .case-wrap .pack.COM img {
        position: absolute;
        top: 0;
        width: 220px;
    }
    #home .content .case-wrap .pack .item {
        position: relative;
        width: 313px;
    }
    #home .choices .purpose .title,
    #home .choices .purpose .purpose-list li a,
    #home .choices .products ul:not(.select-view) li a,
    #home .content .case-wrap .pack .item .description p {
        font-size: 16px;
        line-height: 22px;
    }
    #home .content .case-wrap .pack .item .description .tit-case {
        font-size: 23px;
    }
    #home .choices .products ul:not(.select-view) {
        display: none;
    }
    #home .choices .products ul.select-view {
        display: block;
    }
    #home .content .result {
        padding-top: 25px;
        margin-bottom: 32px;
        text-align: left;
    }
}

@media screen and (max-width: 550px) {
    #home .container {
        padding-top: 22px;
    }
    #home .bx-wrapper .bx-viewport,
    #home .banner div:after {
        height: 170px !important;
        top: 0;
    }
    #home .banner img {
        position: relative;
        top: -10px;
        height: 180px;
    }
    #home .bx-wrapper .bx-controls.bx-has-pager {
        top: -175px;
    }
    #home .banner .banner-text {
        font-size: 22px;
        padding: 105px 0 10px 20px;
        line-height: 1.2;
    }
}

@media screen and (max-width: 426px) {
    #home .bx-wrapper .bx-viewport,
    #home .banner div:after {
        height: 150px !important;
    }
    #home .banner .banner-text {
        font-size: 20px;
        padding: 92px 0 8px 12px;
    }
    #home .bx-wrapper .bx-controls.bx-has-pager {
        top: -160px;
    }
    #home .choices > h2 {
        font-size: 26px;
    }
    #home .choices .purpose .title {
        margin: 17px 0 10px 0;
    }
    #home .choices .purpose .purpose-list {
        margin-bottom: 15px;
    }
    #home .content .case-wrap .pack {
        min-height: 390px;
    }
    #home .content .case-wrap .pack img,
    #home .content .case-wrap .pack.COM img {
        width: 100%;
        position: relative;
        top: -43px;
    }
    #home .content .case-wrap .pack.COM img {
        top: -120px;
    }
    #home .content .case-wrap .pack .item {
        position: absolute;
        width: 100%;
    }
    #home .choices .products ul:not(.select-view) li:hover:not(.selected)::before {
        background: none;
    }
}

@media screen and (max-width: 340px) {
    #home .banner .banner-text {
        font-size: 18px;
        padding: 98px 0 8px 10px;
    }
}