/* SET CUSTOM-TABLE COLUMNS WIDTH - START */

#pol-summary.product-details .summary.coverage .custom-table>ul>li>p:nth-of-type(1),
#pol-summary.product-details .summary.coverage .custom-table>ul>li>div:nth-of-type(1) {
    min-width: 49%;
}

#pol-summary.product-details .summary.coverage .custom-table>ul>li>p:nth-of-type(2),
#pol-summary.product-details .summary.coverage .custom-table>ul>li>div:nth-of-type(2) {
    min-width: 27%;
}

#pol-summary.product-details .summary.coverage .custom-table>ul>li>p:nth-of-type(3),
#pol-summary.product-details .summary.coverage .custom-table>ul>li>div:nth-of-type(3) {
    min-width: 18%;
}

#pol-summary .deductible-text{
    white-space: pre-wrap;
    text-align: justify;
    font-weight: normal;
}


/* SET CUSTOM-TABLE COLUMNS WIDTH - END */

#pol-summary.product-details .summary.coverage .box-1 {
    padding: 10px 3%;
}

#pol-summary.product-details .summary.coverage .custom-table>ul>li>div>p.prem-total-1 {
    display: none;
}

#pol-summary.product-details .summary.coverage .custom-table>ul>li.header>p {
    user-select: none;
}

#pol-summary.product-details .summary.coverage .custom-table>ul>li.body>div>div {
    display: none;
    margin-top: 20px;
}

#pol-summary.product-details .summary.coverage .custom-table>ul>li.body>div>div>p:first-of-type {
    font-weight: bold;
    text-transform: uppercase;
}

/* SET CUSTOM-TABLE COLUMNS WIDTH - START - BENEFITS AND COVERAGE - FPA*/
#pol-summary.product-details .summary.benCovFPA .custom-table > ul > li > p:nth-of-type(1),
#pol-summary.product-details .summary.benCovFPA .custom-table > ul > li > div:nth-of-type(1) {
    min-width: 40%;
}
#pol-summary.product-details .summary.benCovFPA .custom-table > ul > li > p:nth-of-type(2),
#pol-summary.product-details .summary.benCovFPA .custom-table > ul > li > div:nth-of-type(2) {
    min-width: 15%;
}
#pol-summary.product-details .summary.benCovFPA .custom-table > ul > li > p:nth-of-type(3),
#pol-summary.product-details .summary.benCovFPA .custom-table > ul > li > div:nth-of-type(3) {
    min-width: 20%;
}
#pol-summary.product-details .summary.benCovFPA .custom-table > ul > li > p:nth-of-type(4),
#pol-summary.product-details .summary.benCovFPA .custom-table > ul > li > div:nth-of-type(4) {
    min-width: 15%;
}
/* SET CUSTOM-TABLE COLUMNS WIDTH - END */

#pol-summary.product-details .summary.benCovFPA .box-1 {
    padding: 10px 3%;
}
#pol-summary.product-details .summary.benCovFPA .custom-table > ul > li > p.centerAlign,
#pol-summary.product-details .summary.benCovFPA .custom-table > ul > li > div > p.centerAlign {
    text-align: center;
}
#pol-summary.product-details .summary.benCovFPA .custom-table > ul > li.header > p {
    user-select: none;
}
#pol-summary.product-details .summary.benCovFPA .custom-table > ul > li.header > p::after {
    background-image: none;
}
#pol-summary.product-details .summary.benCovFPA .custom-table > ul > li.body > div > div {
    display: none;
    margin-top: 20px;
}
#pol-summary.product-details .summary.benCovFPA .custom-table > ul > li.body > div > div > p:first-of-type {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
}

/* SET CUSTOM-TABLE COLUMNS WIDTH - START - BENEFITS AND COVERAGE - IND*/
#pol-summary.product-details .summary.benCovIND .custom-table > ul > li > p:nth-of-type(1),
#pol-summary.product-details .summary.benCovIND .custom-table > ul > li > div:nth-of-type(1) {
    min-width: 49%;
}
#pol-summary.product-details .summary.benCovIND .custom-table > ul > li > p:nth-of-type(2),
#pol-summary.product-details .summary.benCovIND .custom-table > ul > li > div:nth-of-type(2) {
    min-width: 45%;
}
/* SET CUSTOM-TABLE COLUMNS WIDTH - END */

#pol-summary.product-details .summary.benCovIND .box-1 {
    padding: 10px 3%;
}
#pol-summary.product-details .summary.benCovIND .custom-table > ul > li > div > p.prem-total-1 {
    display: none;
}
#pol-summary.product-details .summary.benCovIND .custom-table > ul > li.header > p {
    user-select: none;
}
#pol-summary.product-details .summary.benCovIND .custom-table > ul > li.header > p::after {
    background-image: none;
}
#pol-summary.product-details .summary.benCovIND .custom-table > ul > li.body > div > div {
    display: none;
    margin-top: 20px;
}
#pol-summary.product-details .summary.benCovIND .custom-table > ul > li.body > div > div > p:first-of-type {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
}

/* SET CUSTOM-TABLE COLUMNS WIDTH - START - BENEFITS AND COVERAGE - CI*/
#pol-summary.product-details .summary.benCovCI .custom-table > ul > li > p:nth-of-type(1),
#pol-summary.product-details .summary.benCovCI .custom-table > ul > li > div:nth-of-type(1) {
    min-width: 28%;
}
#pol-summary.product-details .summary.benCovCI .custom-table > ul > li > p:nth-of-type(2),
#pol-summary.product-details .summary.benCovCI .custom-table > ul > li > div:nth-of-type(2) {
    min-width: 40%;
}
#pol-summary.product-details .summary.benCovCI .custom-table > ul > li > p:nth-of-type(3),
#pol-summary.product-details .summary.benCovCI .custom-table > ul > li > div:nth-of-type(3) {
    min-width: 25%;
}
/* SET CUSTOM-TABLE COLUMNS WIDTH - END */

#pol-summary.product-details .summary.benCovCI .box-1 {
    padding: 10px 3%;
}
#pol-summary.product-details .summary.benCovCI .custom-table > ul > li > p.value,
#pol-summary.product-details .summary.benCovCI .custom-table > ul > li > div > p.value {
    font-weight: bold;
}
#pol-summary.product-details .summary.benCovCI .custom-table > ul > li.header > p {
    user-select: none;
}
#pol-summary.product-details .summary.benCovCI .custom-table > ul > li.header > p::after {
    background-image: none;
}
#pol-summary.product-details .summary.benCovCI .custom-table > ul > li.body > div > div {
    display: none;
    margin-top: 20px;
}
#pol-summary.product-details .summary.benCovCI .custom-table > ul > li.body > div > div > p:first-of-type {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
}

#pol-summary.product-details .quote-policy-summary .detail .value.email,
#pol-summary.product-details .summary.payment .detail .value,
#pol-summary .word-break {
    word-break: break-all;
}

#pol-summary .red {
    color: #b11116 !important;
}

#pol-summary .middle {
    width: 13%;
}

#pol-summary .suffix {
    width: 8.5%;
}

#pol-summary .help-icon {
    color: #1B1464;
    display: inline-block;
}

#pol-summary .link:hover {
    text-decoration-line: underline;
}

.mat-tooltip {
    white-space: pre-line;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 12px;
    background: white;
    color: black !important;
}

#pol-summary .detail .value.input {
    margin-top: 2px;
}

#pol-summary .detail .value.input .mat-form-field-flex {
    /* padding: 0 !important; */
    background-color: yellow;
}

#pol-summary .detail .value.input .mat-form-field-flex .mat-form-field-infix {
    border: none;
    padding: 0;
}

#pol-summary .detail .value.input .mat-form-field.select .mat-form-field-flex::before {
    top: 13px
}

#pol-summary .detail .value.input .field-error {
    color: #b1111f;
    margin-top: 9px;
    line-height: 15px;
    font-family: "Inter", "Arial", "Calibri", sans-serif;
    font-size: 12px;
}

#pol-summary .custom-table > ul > li.white {
    background-color: #fff !important;
}

#pol-summary .green {
    color: #219653;
}

#pol-summary .pad-left {
    margin-left: 15px;
}
#pol-summary .pad-top {
    padding-top: 15px;
}
#pol-summary .center {
    text-align: center;
}
#pol-summary .italic {
    font-style: italic;
    margin: 20px;
}
#pol-summary .grid {
    display: grid;
    margin-bottom: 5px;
}
#pol-summary .update-button{
    position:absolute;
    border-radius: 5px;
    font-size: 17px;
    height: 50px;
}

/* Para sa "Cancellation of policy without payment yet for certain users." */
#pol-summary .form-note-cancel{
    font-weight: 400;
    font-family: "Montserrat", "Arial", "Calibri", sans-serif;
}

#pol-summary .form-note-cancel a{
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
    color: #1B1464;
}
/* Hanggang Dito yung changes ng "Cancellation of policy without payment yet for certain users." */

#pol-summary .italic {
    font-style: italic;
}

#pol-summary .margin-bottom{
    margin-bottom: 5px;
}
@media screen and (max-width: 801px) {
    #pol-summary .wac-mobile{
        display: none;
    }
    #pol-summary .item-premium-mobile{
        display: none;
    }
    #pol-summary .item-premium-mobile-size{
        font-size: larger;
    }
    #pol-summary .update-button{
        position: initial;
        margin-bottom: 5px;
    }
    #pol-summary .detail .value.input .mat-form-field-flex .mat-form-field-infix {
        width:50%;
    }
    #pol-summary .right{
        text-align: center;
    }
    
    #pol-summary .ruby {
        display: flex !important;
    }
    #pol-summary .pre {
        white-space: pre-line;
        font-size: smaller;
        overflow-x: auto;
    }
    #pol-summary.product-details .summary.coverage .custom-table>ul>li.body>div>div:nth-of-type(3),
    #pol-summary.product-details .summary.coverage .custom-table>ul>li>div>p.prem-total-1 {
        display: block;
    }
    #pol-summary.product-details .summary.coverage .custom-table>ul>li>p:nth-of-type(3),
    #pol-summary.product-details .summary.coverage .custom-table>ul>li>div:nth-of-type(3) {
        display: none;
    }
    #pol-summary.product-details .summary.coverage .custom-table>ul>li>p:nth-of-type(2),
    #pol-summary.product-details .summary.coverage .custom-table>ul>li>div:nth-of-type(2) {
        margin-right: 0;
    }
    #pol-summary.product-details .summary.coverage .custom-table>ul>li>p:nth-of-type(1),
    #pol-summary.product-details .summary.coverage .custom-table>ul>li>div:nth-of-type(1) {
        min-width: 62%;
    }
    #pol-summary.product-details .summary.coverage .custom-table>ul>li>p:nth-of-type(2),
    #pol-summary.product-details .summary.coverage .custom-table>ul>li>div:nth-of-type(2) {
        min-width: 35%;
    }
    #pol-summary.product-details .summary.benCovFPA .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(1),
    #pol-summary.product-details .summary.benCovFPA .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(2),
    #pol-summary.product-details .summary.benCovFPA .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(3),
    #pol-summary.product-details .summary.benCovFPA .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(4) {
        display: block;
    }

    #pol-summary.product-details .summary.benCovFPA .custom-table > ul > li > div > p,
    #pol-summary.product-details .summary.benCovFPA .custom-table > ul > li.body > div.sp-1 > p,
    #pol-summary.product-details .summary.benCovFPA .custom-table > ul > li > p:nth-of-type(2),
    #pol-summary.product-details .summary.benCovFPA .custom-table > ul > li > div:nth-of-type(2),
    #pol-summary.product-details .summary.benCovFPA .custom-table > ul > li > p:nth-of-type(3),
    #pol-summary.product-details .summary.benCovFPA .custom-table > ul > li > div:nth-of-type(3),
    #pol-summary.product-details .summary.benCovFPA .custom-table > ul > li > p:nth-of-type(4),
    #pol-summary.product-details .summary.benCovFPA .custom-table > ul > li > div:nth-of-type(4) {
        display: none;
    }

    #pol-summary.product-details .summary.benCovFPA .custom-table > ul > li > p:nth-of-type(1),
    #pol-summary.product-details .summary.benCovFPA .custom-table > ul > li > div:nth-of-type(1) {
        min-width: 94%;
    }

    #pol-summary.product-details .summary.benCovFPA .custom-table > ul > li.header > p::after {
        background-image: none;
    }

    #pol-summary.product-details .summary.benCovFPA .custom-table > ul > li:nth-of-type(even) {
        background-color: #eaeaea;
    }

    #pol-summary.product-details .summary.benCovIND .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(1),
    #pol-summary.product-details .summary.benCovIND .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(2) {
        display: block;
    }

    #pol-summary.product-details .summary.benCovIND .custom-table > ul > li > div > p,
    #pol-summary.product-details .summary.benCovIND .custom-table > ul > li.body > div.sp-1 > p,
    #pol-summary.product-details .summary.benCovIND .custom-table > ul > li > p:nth-of-type(2),
    #pol-summary.product-details .summary.benCovIND .custom-table > ul > li > div:nth-of-type(2) {
        display: none;
    }

    #pol-summary.product-details .summary.benCovIND .custom-table > ul > li.header > p::after {
        background-image: none;
    }

    #pol-summary.product-details .summary.benCovIND .custom-table > ul > li > p:nth-of-type(1),
    #pol-summary.product-details .summary.benCovIND .custom-table > ul > li > div:nth-of-type(1) {
        min-width: 94%;
    }

    #pol-summary.product-details .summary.benCovIND .custom-table > ul > li:nth-of-type(even) {
        background-color: #eaeaea;
    }

    #pol-summary.product-details .summary.benCovCI .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(1),
    #pol-summary.product-details .summary.benCovCI .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(2),
    #pol-summary.product-details .summary.benCovCI .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(3) {
        display: block;
    }

    #pol-summary.product-details .summary.benCovCI .custom-table > ul > li.body2,
    #pol-summary.product-details .summary.benCovCI .custom-table > ul > li > div > p,
    #pol-summary.product-details .summary.benCovCI .custom-table > ul > li.body > div.sp-1 > p,
    #pol-summary.product-details .summary.benCovCI .custom-table > ul > li > p:nth-of-type(2),
    #pol-summary.product-details .summary.benCovCI .custom-table > ul > li > div:nth-of-type(2) {
        display: none;
    }

    #pol-summary.product-details .summary.benCovCI .custom-table > ul > li.header > p::after {
        background-image: none;
    }

    #pol-summary.product-details .summary.benCovCI .custom-table > ul > li > p:nth-of-type(1),
    #pol-summary.product-details .summary.benCovCI .custom-table > ul > li > div:nth-of-type(1) {
        min-width: 94%;
    }
    #pol-summary .middle {
        width: 70% !important;
    }
    #pol-summary .suffix {
        width: 20% !important;
    }
    #pol-summary .summary.coverage .custom-table.dependent>ul>li>div.pa,
    #pol-summary .summary.coverage .custom-table.beneficiary>ul>li>div.pa{
        margin-left: auto;
    }
}

@media screen and (max-width: 561px) {
    #pol-summary.product-details .summary.coverage .custom-table>ul>li.body>div>div:nth-of-type(1),
    #pol-summary.product-details .summary.coverage .custom-table>ul>li.body>div>div:nth-of-type(2) {
        display: block;
    }
    #pol-summary.product-details .summary.coverage .custom-table>ul>li.body>div>div:first-of-type {
        margin-top: 0;
    }
    #pol-summary.product-details .summary.coverage .custom-table>ul>li.body>div>div:first-of-type>p {
        font-weight: bold;
    }
    #pol-summary.product-details .summary.coverage .custom-table>ul>li>p,
    #pol-summary.product-details .summary.coverage .custom-table>ul>li>div:nth-of-type(2),
    #pol-summary.product-details .summary.coverage .custom-table>ul>li>div>p {
        display: none;
    }
    #pol-summary.product-details .summary.coverage .custom-table>ul>li>div:nth-of-type(1) {
        min-width: 100%;
    }
    #pol-summary.product-details .summary.coverage .custom-table>ul>li>div:nth-of-type(1) {
        margin-right: 0;
    }
    #pol-summary.product-details .summary.coverage .custom-table>ul>li.footer {
        display: none;
    }
    #pol-summary.product-details .summary.coverage .custom-table>ul>li {
        padding: 0 16px;
    }
    #pol-summary.product-details .summary.coverage .custom-table>ul>li:nth-of-type(even) {
        background-color: #eaeaea;
    }
    #pol-summary.product-details .summary.coverage .custom-table>ul>li>div {
        padding: 20px 0;
    }
    #pol-summary .summary.coverage .custom-table.dependent>ul>li>div>p:nth-of-type(1),
    #pol-summary .summary.coverage .custom-table.beneficiary>ul>li>div>p:nth-of-type(1){
        display: flex !important;
        justify-content: space-between;
        margin-left: 50px;
        margin-top: 10px;
        font-size: 18px;
    }
    #pol-summary .summary.coverage .custom-table.dependent>ul>li>div.pa,
    #pol-summary .summary.coverage .custom-table.beneficiary>ul>li>div.pa{
        display: none !important;
    }
    #pol-summary .ruby {
        display: flex !important;
    }

    }

@media screen and (max-width: 426px) {
    #pol-summary #ids {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid rgba(0, 0, 0, 0.08);
    }
    #pol-summary #ids .detail {
        flex-wrap: wrap;
    }
    #pol-summary #ids .detail .label,
    #pol-summary #ids .detail .value {
        margin-top: 10px;
        margin-right: 4%;
        width: 48%;
        min-width: unset;
        max-width: unset;
    }
    #pol-summary #ids .detail .label:nth-of-type(1),
    #pol-summary #ids .detail .label:nth-of-type(2),
    #pol-summary #ids .detail .value:nth-of-type(1),
    #pol-summary #ids .detail .value:nth-of-type(2) {
        margin-top: 0;
    }
    #pol-summary #ids .detail div:nth-of-type(even) {
        margin-right: 0;
    }
    #pol-summary.product-details .summary.coverage .custom-table>ul>li.body>div>div {
        display: flex !important;
        justify-content: space-between;
        margin-top: 10px;
    }
    #pol-summary.product-details .summary.coverage .custom-table>ul>li.body>div>div>p:first-of-type {
        min-width: 130px;
        max-width: 130px;
        margin-right: 20px;
        margin-bottom: 0;
    }
    #pol-summary.product-details .summary.coverage .custom-table>ul>li.body>div>div>p:last-of-type {
        text-align: right;
    }
    #pol-summary .ruby {
        display: flex !important;
    }
}

@media screen and (max-width: 376px) {
    #pol-summary.product-details .summary.coverage .custom-table>ul>li.body>div>div>p:first-of-type {
        min-width: 100px;
        max-width: 100px;
    }
    #pol-summary.product-details .quote-policy-details .detail .label, .product-details .summary .box-1 .detail .label {
        min-width: 140px;
        max-width: 140px;
        word-break: break-word;
    }
    #pol-summary .ruby {
        display: flex !important;
    }
}

#pol-summary.product-details .summary.coverage .custom-table.dependent>ul>li>p:nth-of-type(1), 
#pol-summary.product-details .summary.coverage .custom-table.dependent>ul>li>div:nth-of-type(1),
#pol-summary.product-details .summary.coverage .custom-table.beneficiary>ul>li>p:nth-of-type(1), 
#pol-summary.product-details .summary.coverage .custom-table.beneficiary>ul>li>div:nth-of-type(1) {
    word-wrap: break-word;
    min-width: 25%;
    max-width: 100%;
}
#pol-summary .custom-table.dependent > ul > li.header p:after,
#pol-summary .custom-table.beneficiary > ul > li.header p:after{
    background-image: none;
    background-size: 18px;
    background-position: center;
}
#pol-summary.custom-table > ul > li > p:last-of-type, .custom-table.custom-table.dependent > ul > li > div.pa:last-of-type,
#pol-summary.custom-table > ul > li > p:last-of-type, .custom-table.custom-table.custom-table.beneficiary > ul > li > div.pa:last-of-type {
    margin-right: 0;
    margin-left: auto;
}
#pol-summary .margin-top{
    margin-top: 20px;
}
#pol-summary .min-margin-top{
    margin-top: 10px;
}
#pol-summary .min-padding{
    padding-top: 10px;
    padding-bottom: 10px;
}
#pol-summary .required:after {
    content:" *";
    color: red;
  }
#pol-summary .justify {
    text-align: justify;
}
#pol-summary .bold-text{
    font-weight: bolder;
}
#pol-summary .kyc{
    border-radius: 15px;
    min-width: 100px;
    padding: 5px 5px;
}
#pol-summary .expand {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
#pol-summary .pre {
    white-space: pre-line;
    font-size: smaller;
}
#pol-summary .box-holder {
    margin-bottom: 10px !important;
}
#pol-summary .bold {
    font-weight: bolder;
}
#pol-summary .right {
    margin-left: 10px;
}
#pol-summary .left {
    float: left;
}
#pol-summary .ruby {
    display: contents;
    overflow: auto;    
    margin: 10px 0 10px;
}
#pol-summary .rem-margin {
    margin-top: 10px;
    margin-bottom: 0px;
}

#pol-summary .container>.form.terms {
    padding-bottom: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
#pol-summary .container>.form.terms .col-case {
    display: flex;
}

#pol-summary .terms-text {
    text-align: justify;
    font-weight: 400;
    font-family: "Montserrat", "Arial", "Calibri", sans-serif;
}

#pol-summary .terms-text a {
    cursor: pointer;
    color: #1b1464;
    font-weight: bold;
    text-decoration: underline;
}

#pol-summary .blue {
    background: #1B1464;
    border-color: #1B1464;
}

div.vd-input{
    margin-bottom: 10px !important;
}

div.vd-first-text{
    margin-bottom: 14px !important;
}

label.red {
    color:#b11116 !important;
}

p.vehicle-form-text{
    padding-left: 10px !important;
}

label.vehicle-details-text{
    font-weight: bold !important;
    margin-bottom: 10px !important;
}

input.vehicle-detail-input {
    text-transform: uppercase !important;
}

input.vehicle-detail-input::placeholder {
    text-transform: initial !important;
}

label.vd-error-text{
    font-size: smaller !important;
    padding-left: 10px !important;
    color: #b11116 !important;
}

#pol-summary .detail .view-btn{
    padding-left: 10px;
    float: right;
    cursor: pointer;
}

#pol-summary .detail .view-btn:hover{
    text-decoration-line: underline;
}

#pol-summary .detail .download-btn{
    cursor: pointer;
    font-weight: bold !important;
}

#pol-summary .detail .download-btn:hover{
    text-decoration-line: underline;
}

/* FIRE CHECKBOX - START */
#pol-summary.product-details .checkbox {
    margin-top: 20px;
    cursor: default;
    padding-right: 2%;
    /* color: gray; */
    font-family: "Montserrat", "Arial", "Calibri", sans-serif;
}

#pol-summary.product-details .currency {
    margin-left: 5px;
    padding-right: 10px;
    cursor: text;
    border-right: 2px solid lightgray;
    display: inline-block;
    font-family: "Montserrat", "Arial", "Calibri", sans-serif;
    font-weight: 600;
    height: 30px;
    padding-top: 7px;
    /* color: gray; */
}

#pol-summary.product-details .amount {
    margin-left: 5px;
    padding-left: 5px;
    cursor: text;
    display: inline-block;
    font-family: "Montserrat", "Arial", "Calibri", sans-serif;
    font-weight: 600;
}

#pol-summary.product-details .insurance-amount {
    border: none;
    margin-bottom: 0%;
    padding-bottom: 0%;
}

#pol-summary.product-details .currency-amount-container {
    display: inline-block;
}
/* FIRE CHECKBOX - END */