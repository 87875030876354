.osp-popup img {
    width: 600px;
    max-width: 80vw !important;
    /* height: 60vh; */
}

@media screen and (max-width: 767px) {
    .osp-popup img {
        max-width: 80vw !important;
    }
}


/* @media screen and (max-width: 640px) {
    .osp-popup iframe{
        min-width:50vw;
        height: 60vh;
    }
}

@media screen and (max-width: 550px) {
	.osp-popup iframe{
        min-width:50vw;
        height: 60vh;
    }
} */