#faq .container {
    margin-top: 35px;
}

#faq .faq-box {
    margin-bottom: 35px;
    max-height: 50vh;
    overflow: auto;
}

#faq .divider {
    margin-top: 15px;
    margin-bottom: 15px;
}

#faq .faq-big-title{
    margin-bottom: 15px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 44px;
    color: #1B1464;
    letter-spacing: -0.01em;
    width: 50%;
}

#faq {
    font-family: Montserrat;
}

#faq .bold {
    font-weight: bold;  
}

#faq .justify {
    text-align: justify;
    text-justify: inter-word;
    white-space: pre-line;
}

#faq .disclaimer {
    color: #1B1464;
    font-weight: bold;
    margin-bottom: 10px;
}

#faq .question{
    font-size: 16px;
    font-family: ProximaNova;
}

#faq .answer{
    font-size: 16px;
    font-family: ProximaNova;
}

#faq .disclaimer-content{
    margin-bottom: 35px;
    font-size: 16px;
}

#faq .margin {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 100%;
}

#faq .btn{
    margin-left: 10px;
    float: right;
    position: relative;
}

#faq .faq-top-button{
    margin-left: auto;
    order: 2;
}

#faq .faq-top{
    padding-top: 15px;
    display: flex;
}

@media screen and (max-width: 720px) {
    #faq .faq-top-button{
        text-align: center;
        margin-bottom: 25px;
        margin-left: 0;
    }
    #faq .faq-top{
        display:grid;
    }
    #faq .faq-big-title{
        width:75%;
        margin-bottom: 20px;
    }
}

#faq pre{
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    font-family:ProximaNova;
    font-size: 16px;
}

#faq .intro-text{
    font-size: 16px;
}