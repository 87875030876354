#choose-plan .plan-container {
    overflow-x: auto;
}
#choose-plan .plans {
    display: flex;
    padding: 30px 0;
    width: fit-content;
}
#choose-plan .plan-box {
    position: relative;
    width: 304px;
    padding: 24px;
    box-sizing: border-box;
    margin-right: 13px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 1px 2px rgba(27, 20, 100, 0.24);
}
#choose-plan .plan-box:first-of-type {
    margin-left: 3px;
}
#choose-plan .plan-box:last-of-type {
    margin-right: 3px;
}
#choose-plan .plan-box h1 {
    font-weight: bold;
    line-height: 24px;
    color: #1B1464;
    font-size: 19px;
}
#choose-plan .plan-box h2 {
    font-size: 14px;
    line-height: 18px;
    color: #4A4A4A;
    margin-top: 8px;
    font-weight: 600;
}
#choose-plan .plan-box .plan-short-desc {
    margin-top: 15px;
    font-size: 14px;
    line-height: 20px;
    color: #4A4A4A;
    border-bottom: 2px solid #F0F0F0;
    font-family: inherit;
    white-space: pre-wrap;
    padding-bottom: 15px;
    box-sizing: border-box;
    text-align: justify;
}
#choose-plan .plan-box .perils {
    margin-top: 15px;
    font-size: 14px;
}
#choose-plan .plan-box .perils p {
    margin-bottom: 4px;
    padding-left: 30px;
    line-height: 22px;
}
#choose-plan .plan-box .perils p:last-of-type {
    margin-bottom: 0;
}
#choose-plan .plan-box .perils .N {
    background: url(../../../../../assets/images/common/icon-circle-check-green.png) no-repeat left top;
}
#choose-plan .plan-box .perils .Y {
    background: url(../../../../../assets/images/common/icon-circle-check-red.png) no-repeat left top;
}
#choose-plan .plan-box .perils .X {
    background: url(../../../../../assets/images/common/icon-circle-cross-black.png) no-repeat left top;
}
#choose-plan .plan-box .plan-btns {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
}
#choose-plan .plan-box .plan-btns button {
    border-radius: 4px;
    font-size: 13px;
    min-width: 102px;
    padding: 7px 10px;
}
#choose-plan .plan-box .plan-btns .select-btn {
    min-width: 145px;
    transition: none;
}
#choose-plan .plan-box.selected .plan-btns .select-btn {
    background-color: #F0F0F0;
    color: #1B1464;
    border-color: #F0F0F0;
    padding-left: 35px;
    position: relative;
}
#choose-plan .plan-box.selected .plan-btns .select-btn::before {
    content: '';
    position: absolute;
    top: 7px;
    left: 15px;
    height: 15px;
    width: 15px;
    background: url(../../../../../assets/images/common/icon-check-blue.png) no-repeat;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px;
}
#choose-plan .form-note {
    border: 2px solid #D5B527;
    box-sizing: border-box;
    border-radius: 24px;
    padding: 10px 20px;
    margin-top: 20px;
}
#choose-plan .form-note p {
    text-align: center;
}
#choose-plan .form-note div.mort p{
    text-align: justify;
}
#choose-plan .form .radio-btn-slot{
    display:inline-block !important;
    width: 48%;
    /*border: 2px solid #D5B527;
    border-radius: 10px;*/
    box-sizing: border-box;
    word-wrap: break-word;
    margin-left: 1%;
    margin-right: 1%;
}
#choose-plan .form .radio-btn-slot .radio-lbl {
    display:block;
    word-wrap: break-word;
    white-space: normal;
    line-height: 1.5;
}
#choose-plan .plan-note {
    border: 2px solid #D5B527;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 2px 4px;
    margin-bottom: 20px;
}
#choose-plan .plan-note p {
    text-align: center;
}
#choose-plan .rec-note {
    border: 2px solid #D5B527;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 2px 4px;
    margin-bottom: 20px;
    width: 60%;
    background-color: #B11116;
	color: #FFF;
	border-color: #B11116;
}
#choose-plan .rec-note p {
    text-align: center;
}

/* LINE / SUBLINE CUSTOM CSS */
#choose-plan .plan-box.MC-COM h1 {
    min-height: 72px;
}
#choose-plan .plan-box.MC-COM .plan-short-desc {
    min-height: 77px;
}
#choose-plan .plan-box.MC-COM .perils {
    min-height: 210px;
}
#choose-plan .plan-box.HA-HCA .rec-slot {
    min-height: 40px;
}
#choose-plan .plan-box.form-offer {
    width: 525px;
    display: flex;
    flex-direction: column;
}
#choose-plan .plan-box.form-offer h1, 
#choose-plan .plan-box.form-offer h2{
    margin-bottom: 1em;
}

@media screen and (max-width: 801px) {
    #choose-plan .plans {
        flex-wrap: wrap;
    }
    #choose-plan .plans .plan-box {
        width: 48.5%;
        margin-right: 2%;
        margin-top: 20px;
    }
    #choose-plan .plans .plan-box:nth-of-type(even) {
        margin-right: 0;
    }
    #choose-plan .plans .plan-box:nth-of-type(odd) {
        margin-left: 3px;
    }
    #choose-plan .plans .plan-box:nth-of-type(1),
    #choose-plan .plans .plan-box:nth-of-type(2) {
        margin-top: 0;
    }
    #choose-plan .plan-box .plan-btns {
        justify-content: space-around;
    }
    #choose-plan .form-note {
        margin-top: 8px;
    }

    /* LINE / SUBLINE CUSTOM CSS */
    #choose-plan .plan-box.MC-COM h2 {
        min-height: 36px;
    }
    #choose-plan .plan-box.MC-COM .plan-short-desc {
        min-height: 57px;
    }
    #choose-plan .prodInput {
        width: 99% !important;
    }
    #choose-plan .prodLabel {
        width: 100% !important;
        text-align: left !important;
    }
}

@media screen and (max-width: 710.5px) {
    #choose-plan .plan-box.MC-COM .plan-short-desc {
        min-height: 77px;
    }
    #choose-plan .prodInput {
        width: 99% !important;
    }
    #choose-plan .prodLabel {
        width: 100% !important;
        text-align: left !important;
    }
    #choose-plan .form .radio-btn-slot{
        display:block;
        width: 98%;
        margin-left: 1%;
        margin-right: 1%;
    }
}

@media screen and (max-width: 701px) {
    #choose-plan .plan-box .plan-btns {
        flex-direction: column;
    }
    #choose-plan .plan-box .plan-btns button {
        margin-top: 10px;
        padding: 14px 10px;
        font-size: 14px;
    }
    #choose-plan .plan-box .plan-btns button:first-of-type {
        margin-top: 0;
    }
    #choose-plan .plan-box.selected .plan-btns .select-btn {
        padding-left: 10px;
    }
    #choose-plan .plan-box.selected .plan-btns .select-btn::before {
        top: 13px;
    }
    #choose-plan .prodInput {
        width: 99% !important;
    }
    #choose-plan .prodLabel {
        width: 100% !important;
        text-align: left !important;
    }
}

@media screen and (max-width: 631.5px) {
    #choose-plan .plan-box.MC-COM .perils {
        min-height: 226px;
    }

    #choose-plan .prodInput {
        width: 99% !important;
    }
    #choose-plan .prodLabel {
        width: 100% !important;
        text-align: left !important;
    }
}

@media screen and (max-width: 611px) {
    #choose-plan .plans .plan-box {
        width: 100%;
        margin-left: 3px !important;
        margin-right: 3px !important;
        margin-top: 20px !important;
    }
    #choose-plan .plans .plan-box:first-of-type {
        margin-top: 0 !important;
    }

    /* LINE / SUBLINE CUSTOM CSS */
    #choose-plan .plan-box.MC-COM h1,
    #choose-plan .plan-box.MC-COM h2,
    #choose-plan .plan-box.MC-COM .plan-short-desc,
    #choose-plan .plan-box.MC-COM .perils {
        min-height: unset;
    }

    #choose-plan .prodInput {
        width: 99% !important;
    }
    #choose-plan .prodLabel {
        width: 100% !important;
        text-align: left !important;
    }
}

#choose-plan .benefits-coverage-container {
    overflow-x: auto;
}

#choose-plan h1.benefits-title {
    color: #b11116;
}

#choose-plan .plan-box .coverage {
    margin-top: 15px;
    font-size: 14px;
}

#choose-plan .col.title{
    padding-bottom: 2%;
    padding-top: 7%;
    font-size: x-large;
}

#choose-plan .blue {
    color: #1B1464;
}

#choose-plan .mat-tooltip {
    white-space: pre-line;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 16px;
    color: #1B1464;
}

#choose-plan .prodVouch {
    border: 2px solid #1B1464 !important;
    border-radius: 4px;
    /*2px solid #fff*/
}

#choose-plan .error {
    border: 2px solid #b11116 !important;
    border-radius: 4px;
    /*2px solid #fff*/
}

#choose-plan .code {
    padding-bottom: 0px !important;
    border-bottom: 0px !important;
    padding-top: 30px !important;
}

#choose-plan .code2 {
    padding-bottom: 0px !important;
    border-bottom: 0px !important;
}

#choose-plan .field-error {
    margin-top: 10px;
    color: #b11116;
    font-size: 14px;
}

#choose-plan .prodLabel{
    margin-top: 1.5%;
    text-align: end;
}

#choose-plan .prodInput {
    width: 63%;
}

#choose-plan .form.no-line {
    border: none;
}

label.referral-label {
    font-size: smaller;
    font-weight: bold;
}

p.referral-text {
    padding-bottom: 5%;
    padding-top: 5%;
    line-height: 1.5;
    max-width: 530px;
}

p.ref-title {
    font-size: x-large;
    color: #1B1464;
    font-weight: 600;
}

button.referral-next {
    width: 49%;
    margin-left: 5px;
}
