
#property-details-form .col.title{
    padding-bottom: 2%;
}

#property-details-form .form-loader .form-loader-container { 
    margin-bottom: 90px;
}

#property-details .maps {
    width: 100%;
}

#property-details .gMap {
    font: inherit;
    background: #fff;
    color: currentColor;
    border: 2px solid #fff;
    border-radius: 4px;
    outline: none;
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 98%;
    vertical-align: bottom;
    text-align: inherit;
    font-family: Inter,sans-serif;
    line-height: 1.6;
    padding: 10px 7px 10px;
}

#property-details .gMap input {
    font: inherit;
    background: transparent;
    color: currentColor;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 100%;
    vertical-align: bottom;
    text-align: inherit;
}

#property-details .gMap-field {
    padding-bottom: 1%;
}

#property-details .checkbox {
    content: none;
    float: left;
}

#property-details .bldg {
    width: 100% !important;
}

#property-details .checkbox.bldg {
    padding-top: 5%;
}

#property-details .cont {
    width: 100% !important;
}

#property-details .checkbox.cont {
    padding-top: 5%;
}

#property-details .bldg-cont-case {
    margin-bottom: 0px;
}

#property-details .label-total{
    font-weight: 600;
}

#property-details .total-label-case {
    width: 24%;
}

#property-details .si-field-error {
    color: #b1111f;
    margin-top: 9px;
    line-height: 15px;
    font-family: "Inter", "Arial", "Calibri", sans-serif;
    font-size: 12px;
}

#property-details .error {
    border: 2px solid #b11116 !important;
    border-radius: 4px;
    /*2px solid #fff*/
}

#property-details .label {
    margin-bottom: 1%;
}

#property-details .age-of-bldg {
    display: inline-block !important;
    width: 100%;
}

#property-details .age {
    width: 28.8%;
    padding-top: 32px;
}

#property-details .age-label {
    padding-top: 4%;
}

#property-details .const-label {
    padding-top: 1.5%;
}

#property-details .const-year {
    width: 66%;
    /*width: 100%;*/
    margin-right: 5%;
}

#property-details .const-yr-opt {
    /*width: 24%;*/
    width: 100%;
}

/*#property-details .const-year-option-radio-button.exact {
    margin-bottom: 40px;
}*/

/*#property-details .const-yr-opt mat-radio-button {
    margin: 0px;
}*/

#property-details .const-field {
    /*width: 74%;*/
    width: 100%;
}

#property-details .const-field.exact {
    /*width: 74%;
    margin-bottom: 25px;
    */
    width: 100%;
}

#property-details .no-of-floor {
    width: 17.8%;
}

#property-details .roofPropDtl-type-radio-button {
    width: 100%;
}

#property-details .wallPropDtl-type-radio-button {
    width: 100%;
}

#property-details .roof-others{
    width: 100%;
}

#property-details .wall-others{
    width: 100%;
}

#property-details .currency {
    width: 51px;
    margin-right: 0px;
}

#property-details .currency-field {
    width: 75%;
}

#property-details .currency-field input::placeholder {
    text-align: right;
}

#property-details .currency-field input::-webkit-input-placeholder {
    text-align: right;
}

#property-details .mortTag {
    width: 100%;
}

#property-details .font {
    margin-bottom: 10px;
    font-family: Inter,Arial,Calibri,sans-serif;
    font-weight: 400;
    line-height: 30px;
    color: #373f41;
    display: inline-block;
    position: relative;
}

/*#property-details .bank {
    width: 80%;
}

#property-details .loan {
    width: 80%;
}*/

#property-details .mort-amt input::placeholder {
    text-align: right;
}

#property-details .mort-amt input::-webkit-input-placeholder {
    text-align: right;
}

#property-details .bank-policy-doc {
    width: 100%;
}

/*#property-details .mort-set {
    padding-top: 2%;
}*/

#property-details .req label.prop-opt::before {
    content:"*";
    padding-left: 5px;
    float: right;
    color: red;
}

#property-details .existIns {
    width: 100%;
}

/*#property-details .existComp {
    width: 80%;
}

#property-details .existchkbox {
    width: 100%;
}*/

#property-details .label-front {
    padding-top: 20%;
    
}

#property-details .label-vertical {
    padding-top: 7%;
    
}

#property-details .text-occupancy {
    width: 100%;
}

#property-details .surround-field {
    display: flex;
    flex-direction: row;
}

#property-details .surround-field > .surround-label {
    margin-top: 0.875em;
    width: 4em;
}

#property-details .map-button {
    padding-top: 5px;
}

#property-details .form-note.caveat {
    border: 2px solid #D5B527;
    box-sizing: border-box;
    border-radius: 24px;
    padding: 10px 20px;
    margin-top: 20px;
}
#property-details .form-note.caveat p {
    text-align: justify;
}

#property-details .link{
    font-weight: 700;
    cursor: pointer;
    color: #1b1464;
    text-decoration: underline;
}

#property-details .invalid-checkbox.mat-checkbox .mat-checkbox-frame {
    border-color: #b11116;
}

@media screen and (min-width: 830px) {
    #property-details .label-hide {
        display: none;
    }

    #property-details .label-front {
        padding-top: 20%;
        width: 20%;
    }
    
    #property-details .label-vertical {
        padding-top: 7%;
        width: 20%;
    }

    #property-details .first-half {
        margin-right: 1%;
    }

    #property-details .occupancy {
        width: 78%;
    }

    #property-details .adjust-fields-ext {
        padding-left: 1%;
        width: 78%;
    }

    #property-details .adjust-fields-roof {
        padding-left: 8%;
        width: 78%;
    }
    
    #property-details .adjust-fields-storey {
        padding-left: 15%;
        width: 78%;
    }
}

@media screen and (max-width: 830px) {
    #property-details-form .form-loader .form-loader-container { 
        margin: 75px 0 65px;
    }
    
    #property-details .age-of-bldg {
        display: unset !important;
    }
    
    #property-details .age {
        width: 100%;
    }
    
    #property-details .age-label {
        padding-top: 0px;
    }
    
    #property-details .const-year {
        width: 100%;
    }

    #property-details .const-yr-opt {
        margin: 0px !important;
    }
    
    #property-details .no-of-floor {
        width: 100%;
    }

    #property-details .currency .currency-input {
        text-align: center;
    }

    #property-details .label-hide-cont {
        display: none;
    }

    #property-details .checkbox.cont {
        padding-top: 0px;
    }

    /*#property-details .checkbox.bldg mat-checkbox label {
        padding-right: 25px !important;
    }

    #property-details .checkbox.cont mat-checkbox label {
        padding-right: 25px !important;
    }*/

    #property-details .total-label-case {
        width: 100%;
    }

    #property-details .bank {
        width: 100%;
    }
    
    #property-details .loan {
        width: 100%;
    }

    #property-details .existComp {
        width: 100%;
    }

    #property-details .label-front {
        padding-top: 12%;
        width: 20%;
    }

    #property-details .text-occupancy {
        width: 80%;
    }

    #property-details .label-vertical {
        padding-top: 4%;
        width: 20%;
    }

    #property-details .bank-policy-doc {
        width: 100%;
    }

    #property-details .currency-field {
        width: 86%;
    }
}

