#use-of-cookies {
    position: fixed;
    bottom: 0;
    background-color: rgba(28,28,28,.8);
    z-index: 998;
    transition: bottom ease 1.2s;
    padding: 24px 0;
    min-width: 320px;
}
#use-of-cookies.hide {
    bottom: -1000px;
}
#use-of-cookies .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#use-of-cookies .container > pre {
    line-height: 1.5;
    color: #fff;
    white-space: pre-wrap;
    text-align: justify;
}
#use-of-cookies .container > .buttons {
    display: flex;
    margin-left: 45px;
}
#use-of-cookies .container > .buttons a {
    font-family: "ProximaNova", "Arial", "Calibri", sans-serif;
    margin-left: 12px;
    min-width: 150px;
    height: 48px;
}
#use-of-cookies .container > .buttons a:first-of-type {
    margin-left: 0;
    min-width: 130px;
}
#use-of-cookies .btn {
    padding: 13px 16px;
}
#use-of-cookies .btn:not(.btn2) {
    border: 1px solid transparent;
    background-color: unset;
    color: #fff;
    font-weight: unset;
}

@media screen and (max-width: 1023px) {
    #use-of-cookies .container > .buttons {
        flex-direction: column-reverse;
    }
    #use-of-cookies .container > .buttons a {
        margin-left: 0;
    }
}

@media screen and (max-width: 767px) {
    #use-of-cookies {
        padding-bottom: 15px;
    }
    #use-of-cookies .container {
        display: block;
    }
    #use-of-cookies .container > pre {
        margin-bottom: 24px;
    }
    #use-of-cookies .container > .buttons {
        margin-left: 0;
    }
}