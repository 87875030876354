.custom-table {
    font-family: "Arial", "Calibri", sans-serif;
}
.custom-table > ul > li:nth-of-type(odd) {
    background-color: #fff;
}
.custom-table > ul > li:nth-of-type(even) {
    background-color: #f8f8f8;
}
.custom-table > ul > li.header p,
.custom-table > ul > li.footer p {
    text-transform: uppercase;
    font-weight: bold;
}
.custom-table > ul > li.header p {
    cursor: pointer;
    position: relative;
}
.custom-table > ul > li.header p::after,
.custom-table > ul > li.header p.sort.asc::after,
.custom-table > ul > li.header p.sort.desc::after {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    height: 18px;
    width: 18px;
    margin-left: 3px;
}
.custom-table > ul > li.header p::after {
    background-image: url(../images/common/icon-table-sort.png);
    background-size: 18px;
    background-position: center;
}
.custom-table > ul > li.header p.sort.asc::after {
    background-image: url(../images/common/icon-table-sort-asc.png);
    background-size: 9px;
    background-position: top;
    margin-top: 1px;
}
.custom-table > ul > li.header p.sort.desc::after {
    background-image: url(../images/common/icon-table-sort-desc.png);
    background-size: 9px;
    background-position: bottom;
    height: 16px;
}
.custom-table > ul > li {
    display: flex;
    padding: 0 26px 0 16px;
}
.custom-table > ul > li > p.ralign,
.custom-table > ul > li > div > p.ralign {
    text-align: right;
}
.custom-table > ul > li > p,
.custom-table > ul > li > div {
    margin-right: 3%;
    padding: 12px 0;
}
.custom-table > ul > li > p:last-of-type,
.custom-table > ul > li > div:last-of-type {
    margin-right: 0;
}

/* CUSTOM-TABLE-2 */

.custom-table-2 {
    position: relative;
    font-family: "Arial", "Calibri", sans-serif;
}
.custom-table-2::before {
    content: 'Scroll to View More >';
    display: none;
    text-align: right;
    padding-bottom: 20px;
    padding-right: 8px;
    margin-bottom: 10px;
    border-bottom: 2px solid #1b1464;
    font-weight: bold;
    color: #1b1464;
    font-size: 15px;
}
.custom-table-2 > ul {
    overflow-x: auto;
}
.custom-table-2 > ul > li:nth-of-type(odd) {
    background-color: #fff;
}
.custom-table-2 > ul > li:nth-of-type(even) {
    background-color: #f8f8f8;
}
.custom-table-2.background-2 > ul > li:nth-of-type(odd) {
    background-color: #f8f8f8;
}
.custom-table-2.background-2 > ul > li:nth-of-type(even) {
    background-color: #fff;
}
.custom-table-2 > ul > li.header > div > p,
.custom-table-2 > ul > li.footer > div > p {
    text-transform: uppercase;
    font-weight: bold;
}
.custom-table-2:not(.no-sort) > ul > li.header > div > p {
    cursor: pointer;
    position: relative;
}
.custom-table-2:not(.no-sort) > ul > li.header > div > p::after,
.custom-table-2:not(.no-sort) > ul > li.header > div > p.sort.asc::after,
.custom-table-2:not(.no-sort) > ul > li.header > div > p.sort.desc::after {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    height: 18px;
    width: 18px;
    margin-left: 3px;
}
.custom-table-2:not(.no-sort) > ul > li.header > div > p::after {
    background-image: url(../images/common/icon-table-sort.png);
    background-size: 18px;
    background-position: center;
}
.custom-table-2:not(.no-sort) > ul > li.header > div > p.sort.asc::after {
    background-image: url(../images/common/icon-table-sort-asc.png);
    background-size: 9px;
    background-position: top;
    margin-top: 1px;
}
.custom-table-2:not(.no-sort) > ul > li.header > div > p.sort.desc::after {
    background-image: url(../images/common/icon-table-sort-desc.png);
    background-size: 9px;
    background-position: bottom;
    height: 16px;
}
.custom-table-2 > ul > li {
    padding: 12px 16px;
}
.custom-table-2:not(.no-sort) > ul > li {
    padding: 12px 26px 12px 16px;
}
.custom-table-2 > ul > li > div {
    display: flex;
}
.custom-table-2 > ul > li > div > p {
    margin-right: 3%;
    display: flex;
    align-items: center;
}
.custom-table-2 > ul > li > div > p:last-of-type {
    margin-right: 0;
}
.custom-table-2 > ul > li > div > p.ralign {
    display: block;
    text-align: right;
}
.custom-table-2 > ul > li > div > p > .mat-icon {
    margin-left: 5px;
}
.custom-table-2 > ul > li > div.category > p {
    min-width: 100% !important;
    font-weight: bold;
}
.custom-table-2 > ul > li > div.sub-category > p:first-of-type {
    box-sizing: border-box;
    padding-left: 20px;
}