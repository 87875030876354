.mat-form-field {
    display: unset !important;
    position: unset !important;
    text-align: unset !important;
}
.mat-form-field,
.mat-select,
.mat-option,
.mat-radio-button {
    font-family: 'Inter', sans-serif;
    line-height: 1.6;
}
.mat-form-field.select .mat-form-field-flex {
    padding: 0px 36px 0 7px !important;
}
.mat-form-field.select .mat-form-field-flex::before {
    content: '';
    position: absolute;
    background-image: url("../images/common/icon-arrow-down-gray.png");
    background-repeat: no-repeat;
    background-size: contain;
    height: 10px;
    width: 12px;
    right: 12px;
    top: 21px;
}
.mat-form-field-appearance-fill .mat-form-field-flex {
    border-radius: 4px !important;
    padding: 0px 7px !important;
    background: #fff;
}
.mat-form-field-appearance-fill .mat-form-field-infix {
    padding: 0 0 10px 0;
}
.mat-form-field-infix {
    border-top: 10px solid transparent;
}
.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
    transform: translateY(-10%) !important;
}
.mat-form-field-underline {
    width: unset !important;
}
.mat-radio-button {
    margin: 12px 50px 0 0;
}
.mat-radio-button:last-of-type {
    margin-right: 0;
}
.mat-radio-outer-circle,
.mat-radio-inner-circle,
.mat-radio-container {
    height: 17px !important;
    width: 17px !important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #8f2a2a;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: #8f2a2a;
}
.mat-radio-label {
    display: flex !important;
}
.mat-radio-label-content {
    font-family: "ProximaNova", "Arial", "Calibri", sans-serif;
    font-weight: 600;
    color: #4A4A4A;
}
.mat-radio-persistent-ripple {
    width: unset !important;
    height: unset !important;
}
.mat-ripple-element{
    display: none !important;
}
.mat-form-field-subscript-wrapper {
    position: unset !important;
    padding: 0 !important;
}
.mat-header-cell,
.mat-cell,
.mat-footer-cell {
    font-size: 16px;
}
.mat-form-field-subscript-wrapper {
    font-size: inherit;
}
.mat-form-field-wrapper > .mat-form-field-flex {
    border: 2px solid #fff;
}
.mat-form-field-invalid > .mat-form-field-wrapper > .mat-form-field-flex {
    border: 2px solid #8f2a2a;
}
.mat-error {
    color: #8f2a2a;
}
.mat-icon {
    height: 21px !important;
    width: 24px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    cursor: default;
}
/* Bumababa po kasi yung tooltip sa mga table po */
.mat-table .mat-icon { 
    display: inline-block !important;
}
.material-icons {
    font-size: 18px;
}
.mat-select-placeholder {
    color: #E0E0E0;
}
.mat-form-field-wrapper {
    padding-bottom: 0;
}
.mat-select-value-text,
.mat-select-panel {
    font-weight: bold;
}
.mat-form-field-subscript-wrapper {
    display: none;
}
.mat-form-field-suffix {
    position: absolute;
    right: 0;
    top: 6px;
}
.mat-card-subtitle,
.mat-card-content {
    font-size: 16px;
}

@media screen and (max-width: 480px) {
    mat-radio-group .mat-radio-button {
        display: block;
    }
    .mat-radio-button {
        margin: 20px 0 0 15px;
    }
    .mat-radio-outer-circle,
    .mat-radio-inner-circle {
        height: 20px !important;
        width: 20px !important;
    }
    .mat-radio-label-content {
        padding-top: 3px;
        padding-left: 15px !important;
    }
}

mat-dialog-actions {
    /* justify-content: flex-end; */
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    direction: rtl;
}

mat-dialog-actions button .mat-button-wrapper{
    direction: initial;
}

mat-dialog-actions button{
    direction: initial;
}

mat-dialog-content {
    display: flex;
    font-family: "Inter", "Arial", "Calibri", sans-serif;
    line-height: 22px;
    margin: 0 !important;
    padding: 0 !important;
}
mat-dialog-container {
    padding: 10px 15px !important;
}

.mat-form-field-appearance-fill.required .mat-form-field-flex {
    background-color: #FCF4DB;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #b11116;
}

.mat-paginator,
.mat-dialog-container {
    background-color: #F8F8F8;
}

h3.mat-dialog-title {
    margin-bottom: 8px;
}

.mat-checkbox .mat-checkbox-inner-container{
    margin-right: 14px;
}

.mat-checkbox .mat-checkbox-layout{
    white-space: normal;
}

.mat-paginator .mat-icon-button[type=button]:disabled {
    background-color: transparent !important;
    border: none !important;
    cursor: not-allowed;
    opacity: .6;
}