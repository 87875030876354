#acceptance-form > .form-loader .form-loader-container {
    margin-top: 50px;
    margin-bottom: 90px;
}
#policy-header-details-form {
    margin-bottom: 30px;
}
#policy-header-details-form ~ .form .bold {
    font-weight: bold;
}
#policy-header-details-form > .form-loader .form-loader-container {
    margin-top: 70px;
    margin-bottom: 30px;
    padding-bottom: 60px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
#policy-header-details-form .policy-header-details-form-msg.top {
    display: none;
}
#acceptance .policy-header {
    margin-bottom: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

#acceptance .deductible-text{
    white-space: pre-wrap;
    text-align: justify;
    font-weight: normal;
}

/* SET CUSTOM-TABLE COLUMNS WIDTH - START - COVERAGE*/
#acceptance.product-details .summary.coverage .custom-table > ul > li > p:nth-of-type(1),
#acceptance.product-details .summary.coverage .custom-table > ul > li > div:nth-of-type(1) {
    min-width: 49%;
}
#acceptance.product-details .summary.coverage .custom-table > ul > li > p:nth-of-type(2),
#acceptance.product-details .summary.coverage .custom-table > ul > li > div:nth-of-type(2) {
    min-width: 27%;
}
#acceptance.product-details .summary.coverage .custom-table > ul > li > p:nth-of-type(3),
#acceptance.product-details .summary.coverage .custom-table > ul > li > div:nth-of-type(3) {
    min-width: 18%;
}
/* SET CUSTOM-TABLE COLUMNS WIDTH - END */

#acceptance.product-details .summary.coverage .box-1 {
    padding: 10px 3%;
}
#acceptance.product-details .summary.coverage .custom-table > ul > li > div > p.prem-total-1 {
    display: none;
}
#acceptance.product-details .summary.coverage .custom-table > ul > li.header > p {
    user-select: none;
}
#acceptance.product-details .summary.coverage .custom-table > ul > li.body > div > div {
    display: none;
    margin-top: 20px;
}
#acceptance.product-details .summary.coverage .custom-table > ul > li.body > div > div > p:first-of-type {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
}

/* SET CUSTOM-TABLE COLUMNS WIDTH - START - BENEFITS AND COVERAGE - FPA*/
#acceptance.product-details .summary.benCovFPA .custom-table > ul > li > p:nth-of-type(1),
#acceptance.product-details .summary.benCovFPA .custom-table > ul > li > div:nth-of-type(1) {
    min-width: 40%;
}
#acceptance.product-details .summary.benCovFPA .custom-table > ul > li > p:nth-of-type(2),
#acceptance.product-details .summary.benCovFPA .custom-table > ul > li > div:nth-of-type(2) {
    min-width: 15%;
}
#acceptance.product-details .summary.benCovFPA .custom-table > ul > li > p:nth-of-type(3),
#acceptance.product-details .summary.benCovFPA .custom-table > ul > li > div:nth-of-type(3) {
    min-width: 20%;
}
#acceptance.product-details .summary.benCovFPA .custom-table > ul > li > p:nth-of-type(4),
#acceptance.product-details .summary.benCovFPA .custom-table > ul > li > div:nth-of-type(4) {
    min-width: 15%;
}
/* SET CUSTOM-TABLE COLUMNS WIDTH - END */

#acceptance.product-details .summary.benCovFPA .box-1 {
    padding: 10px 3%;
}
#acceptance.product-details .summary.benCovFPA .custom-table > ul > li > p.centerAlign,
#acceptance.product-details .summary.benCovFPA .custom-table > ul > li > div > p.centerAlign {
    text-align: center;
}
#acceptance.product-details .summary.benCovFPA .custom-table > ul > li.header > p {
    user-select: none;
}
#acceptance.product-details .summary.benCovFPA .custom-table > ul > li.header > p::after {
    background-image: none;
}
#acceptance.product-details .summary.benCovFPA .custom-table > ul > li.body > div > div {
    display: none;
    margin-top: 20px;
}
#acceptance.product-details .summary.benCovFPA .custom-table > ul > li.body > div > div > p:first-of-type {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
}

/* SET CUSTOM-TABLE COLUMNS WIDTH - START - BENEFITS AND COVERAGE - IND*/
#acceptance.product-details .summary.benCovIND .custom-table > ul > li > p:nth-of-type(1),
#acceptance.product-details .summary.benCovIND .custom-table > ul > li > div:nth-of-type(1) {
    min-width: 49%;
}
#acceptance.product-details .summary.benCovIND .custom-table > ul > li > p:nth-of-type(2),
#acceptance.product-details .summary.benCovIND .custom-table > ul > li > div:nth-of-type(2) {
    min-width: 45%;
}
/* SET CUSTOM-TABLE COLUMNS WIDTH - END */

#acceptance.product-details .summary.benCovIND .box-1 {
    padding: 10px 3%;
}
#acceptance.product-details .summary.benCovIND .custom-table > ul > li > div > p.prem-total-1 {
    display: none;
}
#acceptance.product-details .summary.benCovIND .custom-table > ul > li.header > p {
    user-select: none;
}
#acceptance.product-details .summary.benCovIND .custom-table > ul > li.header > p::after {
    background-image: none;
}
#acceptance.product-details .summary.benCovIND .custom-table > ul > li.body > div > div {
    display: none;
    margin-top: 20px;
}
#acceptance.product-details .summary.benCovIND .custom-table > ul > li.body > div > div > p:first-of-type {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
}

/* SET CUSTOM-TABLE COLUMNS WIDTH - START - BENEFITS AND COVERAGE - CI*/
#acceptance.product-details .summary.benCovCI .custom-table > ul > li > p:nth-of-type(1),
#acceptance.product-details .summary.benCovCI .custom-table > ul > li > div:nth-of-type(1) {
    min-width: 28%;
}
#acceptance.product-details .summary.benCovCI .custom-table > ul > li > p:nth-of-type(2),
#acceptance.product-details .summary.benCovCI .custom-table > ul > li > div:nth-of-type(2) {
    min-width: 40%;
}
#acceptance.product-details .summary.benCovCI .custom-table > ul > li > p:nth-of-type(3),
#acceptance.product-details .summary.benCovCI .custom-table > ul > li > div:nth-of-type(3) {
    min-width: 25%;
}
/* SET CUSTOM-TABLE COLUMNS WIDTH - END */

#acceptance.product-details .summary.benCovCI .box-1 {
    padding: 10px 3%;
}
#acceptance.product-details .summary.benCovCI .custom-table > ul > li > p.value,
#acceptance.product-details .summary.benCovCI .custom-table > ul > li > div > p.value {
    font-weight: bold;
}
#acceptance.product-details .summary.benCovCI .custom-table > ul > li.header > p {
    user-select: none;
}
#acceptance.product-details .summary.benCovCI .custom-table > ul > li.header > p::after {
    background-image: none;
}
#acceptance.product-details .summary.benCovCI .custom-table > ul > li.body > div > div {
    display: none;
    margin-top: 20px;
}
#acceptance.product-details .summary.benCovCI .custom-table > ul > li.body > div > div > p:first-of-type {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
}

/*  UPDATED CSS FOR TABLE - START  */

#acceptance.product-details .summary.dependents .custom-table > ul > li > p:nth-of-type(1),
#acceptance.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(1) {
    min-width: 25%;
}
#acceptance.product-details .summary.dependents .custom-table > ul > li > p:nth-of-type(2),
#acceptance.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(2) {
    min-width: 14%;
}
#acceptance.product-details .summary.dependents .custom-table > ul > li > p:nth-of-type(3),
#acceptance.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(3) {
    min-width: 21%;
}
#acceptance.product-details .summary.dependents .custom-table > ul > li > p:nth-of-type(4),
#acceptance.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(4) {
    min-width: 10%;
}
#acceptance.product-details .summary.dependents .custom-table > ul > li > p:nth-of-type(5),
#acceptance.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(5) {
    min-width: 11%;
}
#acceptance.product-details .summary.dependents .custom-table > ul > li > p:nth-of-type(6),
#acceptance.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(6) {
    min-width: 5%;
}
#acceptance.product-details .summary.dependents .box-1 {
    padding: 10px 1%;
}
#acceptance.product-details .summary.dependents .custom-table > ul > li > div > p.prem-total-1 {
    display: none;
}
#acceptance.product-details .summary.dependents .custom-table > ul > li > p.centerAlign,
#acceptance.product-details .summary.dependents .custom-table > ul > li > div > p.centerAlign {
    text-align: center;
}
#acceptance.product-details .summary.dependents .custom-table > ul > li.header > p {
    user-select: none;
}
#acceptance.product-details .summary.dependents .custom-table > ul > li.body > div > div {
    display: none;
    margin-top: 20px;
}
#acceptance.product-details .summary.dependents .custom-table > ul > li.body > div > div > p:first-of-type {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
}

/*  UPDATED CSS FOR TABLE - END  */

#acceptance-change-eff-date {
    max-width: 90vw;
}
#acceptance-change-eff-date .periodContainer {
    display: flex;
    justify-content: center;
}
#acceptance-change-eff-date .mat-form-field {
    flex-basis: 45%;
}
#acceptance-change-eff-date .periodContainer .mat-form-field .mat-form-field-infix {
    width: inherit;
}
#acceptance-change-eff-date .periodContainer .separator {
    place-self: center;
    margin: 4px;
    font-size: 24px;
    font-weight: bold;
}
#acceptance .form-fields .policy-header-details .detail:nth-of-type(6) .value{
    color: #000;
}
#acceptance .help-icon {
    color:#1B1464;
    display: inline-block;
}
#acceptance .word-break {
    word-break: break-all;
}
#acceptance .terms-text {
    text-align: justify;
}
.mat-tooltip{
    white-space: pre-line;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 12px;
    background: white;
    color: black !important;
}

/* FIRE CHECKBOX - START */
#acceptance.product-details .checkbox {
    margin-top: 20px;
    cursor: default;
    padding-right: 2%;
    /* color: gray; */
    font-family: "Montserrat", "Arial", "Calibri", sans-serif;
}

#acceptance.product-details .currency {
    margin-left: 5px;
    padding-right: 10px;
    cursor: text;
    border-right: 2px solid lightgray;
    display: inline-block;
    font-family: "Montserrat", "Arial", "Calibri", sans-serif;
    font-weight: 600;
    height: 30px;
    padding-top: 7px;
    /* color: gray; */
}

#acceptance.product-details .amount {
    margin-left: 5px;
    padding-left: 5px;
    cursor: text;
    display: inline-block;
    font-family: "Montserrat", "Arial", "Calibri", sans-serif;
    font-weight: 600;
}

#acceptance.product-details .insurance-amount {
    border: none;
    margin-bottom: 0%;
    padding-bottom: 0%;
}

#acceptance.product-details .currency-amount-container {
    display: inline-block;
}
/* FIRE CHECKBOX - END */

@media screen and (max-width: 831px) {
    #acceptance-form > .form-loader .form-loader-container {
        margin-top: 90px;
    }
}

@media screen and (max-width: 801px) {
    #acceptance.product-details .summary.coverage .custom-table > ul > li.body > div > div:nth-of-type(3),
    #acceptance.product-details .summary.coverage .custom-table > ul > li > div > p.prem-total-1 {
        display: block;
    }
    #acceptance.product-details .summary.coverage .custom-table > ul > li > p:nth-of-type(3),
    #acceptance.product-details .summary.coverage .custom-table > ul > li > div:nth-of-type(3) {
        display: none;
    }
    #acceptance.product-details .summary.coverage .custom-table > ul > li > p:nth-of-type(2),
    #acceptance.product-details .summary.coverage .custom-table > ul > li > div:nth-of-type(2) {
        margin-right: 0;
    }
    #acceptance.product-details .summary.coverage .custom-table > ul > li > p:nth-of-type(1),
    #acceptance.product-details .summary.coverage .custom-table > ul > li > div:nth-of-type(1) {
        min-width: 62%;
    }
    #acceptance.product-details .summary.coverage .custom-table > ul > li > p:nth-of-type(2),
    #acceptance.product-details .summary.coverage .custom-table > ul > li > div:nth-of-type(2) {
        min-width: 35%;
    }

    #acceptance.product-details .summary.benCovFPA .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(1),
    #acceptance.product-details .summary.benCovFPA .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(2),
    #acceptance.product-details .summary.benCovFPA .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(3),
    #acceptance.product-details .summary.benCovFPA .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(4) {
        display: block;
    }

    #acceptance.product-details .summary.benCovFPA .custom-table > ul > li > div > p,
    #acceptance.product-details .summary.benCovFPA .custom-table > ul > li.body > div.sp-1 > p,
    #acceptance.product-details .summary.benCovFPA .custom-table > ul > li > p:nth-of-type(2),
    #acceptance.product-details .summary.benCovFPA .custom-table > ul > li > div:nth-of-type(2),
    #acceptance.product-details .summary.benCovFPA .custom-table > ul > li > p:nth-of-type(3),
    #acceptance.product-details .summary.benCovFPA .custom-table > ul > li > div:nth-of-type(3),
    #acceptance.product-details .summary.benCovFPA .custom-table > ul > li > p:nth-of-type(4),
    #acceptance.product-details .summary.benCovFPA .custom-table > ul > li > div:nth-of-type(4) {
        display: none;
    }

    #acceptance.product-details .summary.benCovFPA .custom-table > ul > li > p:nth-of-type(1),
    #acceptance.product-details .summary.benCovFPA .custom-table > ul > li > div:nth-of-type(1) {
        min-width: 94%;
    }

    #acceptance.product-details .summary.benCovFPA .custom-table > ul > li.header > p::after {
        background-image: none;
    }

    #acceptance.product-details .summary.benCovFPA .custom-table > ul > li:nth-of-type(even) {
        background-color: #eaeaea;
    }

    #acceptance.product-details .summary.benCovIND .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(1),
    #acceptance.product-details .summary.benCovIND .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(2) {
        display: block;
    }

    #acceptance.product-details .summary.benCovIND .custom-table > ul > li > div > p,
    #acceptance.product-details .summary.benCovIND .custom-table > ul > li.body > div.sp-1 > p,
    #acceptance.product-details .summary.benCovIND .custom-table > ul > li > p:nth-of-type(2),
    #acceptance.product-details .summary.benCovIND .custom-table > ul > li > div:nth-of-type(2) {
        display: none;
    }

    #acceptance.product-details .summary.benCovIND .custom-table > ul > li.header > p::after {
        background-image: none;
    }

    #acceptance.product-details .summary.benCovIND .custom-table > ul > li > p:nth-of-type(1),
    #acceptance.product-details .summary.benCovIND .custom-table > ul > li > div:nth-of-type(1) {
        min-width: 94%;
    }

    #acceptance.product-details .summary.benCovIND .custom-table > ul > li:nth-of-type(even) {
        background-color: #eaeaea;
    }

    #acceptance.product-details .summary.benCovCI .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(1),
    #acceptance.product-details .summary.benCovCI .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(2),
    #acceptance.product-details .summary.benCovCI .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(3) {
        display: block;
    }

    #acceptance.product-details .summary.benCovCI .custom-table > ul > li.body2,
    #acceptance.product-details .summary.benCovCI .custom-table > ul > li > div > p,
    #acceptance.product-details .summary.benCovCI .custom-table > ul > li.body > div.sp-1 > p,
    #acceptance.product-details .summary.benCovCI .custom-table > ul > li > p:nth-of-type(2),
    #acceptance.product-details .summary.benCovCI .custom-table > ul > li > div:nth-of-type(2) {
        display: none;
    }

    #acceptance.product-details .summary.benCovCI .custom-table > ul > li.header > p::after {
        background-image: none;
    }

    #acceptance.product-details .summary.benCovCI .custom-table > ul > li > p:nth-of-type(1),
    #acceptance.product-details .summary.benCovCI .custom-table > ul > li > div:nth-of-type(1) {
        min-width: 94%;
    }

    #acceptance.product-details .summary.dependents .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(1),
    #acceptance.product-details .summary.dependents .custom-table > ul > li.body > div.sp-6 > div:nth-of-type(2),
    #acceptance.product-details .summary.dependents .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(3),
    #acceptance.product-details .summary.dependents .custom-table > ul > li.body > div.sp-6 > div:nth-of-type(4),
    #acceptance.product-details .summary.dependents .custom-table > ul > li.body > div.sp-1 > div:nth-of-type(5),
    #acceptance.product-details .summary.dependents .custom-table > ul > li.body > div.sp-6 > div:nth-of-type(6),
    #acceptance.product-details .summary.dependents .custom-table > ul > li > div > p.prem-total-1 {
        display: block;
    }
    
    #acceptance.product-details .summary.dependents .custom-table > ul > li > p,
    #acceptance.product-details .summary.dependents .custom-table > ul > li > div > p,
    #acceptance.product-details .summary.dependents .custom-table > ul > li.body > div.sp-1 > p,
    #acceptance.product-details .summary.dependents .custom-table > ul > li.body > div.sp-6 > p,
    #acceptance.product-details .summary.dependents .custom-table > ul > li > p:nth-of-type(2),
    #acceptance.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(2),
    #acceptance.product-details .summary.dependents .custom-table > ul > li > p:nth-of-type(3),
    #acceptance.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(3),
    #acceptance.product-details .summary.dependents .custom-table > ul > li > p:nth-of-type(4),
    #acceptance.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(4),
    #acceptance.product-details .summary.dependents .custom-table > ul > li > p:nth-of-type(5),
    #acceptance.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(5) {
        display: none;
    }
    #acceptance.product-details .summary.dependents .custom-table > ul > li > p:nth-of-type(2),
    #acceptance.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(2) {
        margin-right: 0;
    }
    #acceptance.product-details .summary.dependents .custom-table > ul > li > p:nth-of-type(1),
    #acceptance.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(1) {
        min-width: 47%;
    }
    #acceptance.product-details .summary.dependents .custom-table > ul > li > p:nth-of-type(6),
    #acceptance.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(6) {
        min-width: 47%;
    }
}

@media screen and (max-width: 767px) {
    #policy-header-details-form .policy-header-details-form-msg.top {
        display: block;
    }
    #policy-header-details-form .policy-header-details-form-msg.bottom {
        display: none;
    }
}

@media screen and (max-width: 561px) {
    #policy-header-details-form .form-loader .form-loader-container {
        margin-top: 90px;
    }
    #acceptance.product-details .summary.coverage .custom-table > ul > li.body > div > div:nth-of-type(1),
    #acceptance.product-details .summary.coverage .custom-table > ul > li.body > div > div:nth-of-type(2) {
        display: block;
    }
    #acceptance.product-details .summary.coverage .custom-table > ul > li.body > div > div:first-of-type {
        margin-top: 0;
    }
    #acceptance.product-details .summary.coverage .custom-table > ul > li.body > div > div:first-of-type > p {
        font-weight: bold;
    }
    #acceptance.product-details .summary.coverage .custom-table > ul > li > p,
    #acceptance.product-details .summary.coverage .custom-table > ul > li > div:nth-of-type(2),
    #acceptance.product-details .summary.coverage .custom-table > ul > li > div > p {
        display: none;
    }
    #acceptance.product-details .summary.coverage .custom-table > ul > li > div:nth-of-type(1) {
        min-width: 100%;
    }
    #acceptance.product-details .summary.coverage .custom-table > ul > li > div:nth-of-type(1) {
        margin-right: 0;
    }
    #acceptance.product-details .summary.coverage .custom-table > ul > li.footer {
        display: none;
    }
    #acceptance.product-details .summary.coverage .custom-table > ul > li {
        padding: 0 16px;
    }
    #acceptance.product-details .summary.coverage .custom-table > ul > li:nth-of-type(even) {
        background-color: #eaeaea;
    }
    #acceptance.product-details .summary.coverage .custom-table > ul > li > div {
        padding: 20px 0;
    }

    #acceptance.product-details .summary.dependents .custom-table > ul > li.body > div > div:nth-of-type(1),
    #acceptance.product-details .summary.dependents .custom-table > ul > li.body > div > div:nth-of-type(2), 
    #acceptance.product-details .summary.dependents .custom-table > ul > li.body > div > div:nth-of-type(4),
    #acceptance.product-details .summary.dependents .custom-table > ul > li.body > div > div:nth-of-type(6) {
        display: block;
    }
    #acceptance.product-details .summary.dependents .custom-table > ul > li.body > div > div:first-of-type {
        margin-top: 0;
    }
    #acceptance.product-details .summary.dependents .custom-table > ul > li.body > div > div:first-of-type > p {
        font-weight: bold;
    }
    #acceptance.product-details .summary.dependents .custom-table > ul > li > p:nth-of-type(6),
    #acceptance.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(6) {
        display: none;
    }
    #acceptance.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(1) {
        min-width: 100%;
    }
    #acceptance.product-details .summary.dependents .custom-table > ul > li > div:nth-of-type(1) {
        margin-right: 0;
    }
    #acceptance.product-details .summary.dependents .custom-table > ul > li.footer {
        display: none;
    }
    #acceptance.product-details .summary.dependents .custom-table > ul > li {
        padding: 0 16px;
    }
    #acceptance.product-details .summary.dependents .custom-table > ul > li:nth-of-type(even) {
        background-color: #eaeaea;
    }
    #acceptance.product-details .summary.dependents .custom-table > ul > li > div {
        padding: 20px 0;
    }
}

@media screen and (max-width: 426px) {
    #acceptance.product-details .summary.coverage .custom-table > ul > li.body > div > div {
        display: flex !important;
        justify-content: space-between;
        margin-top: 10px;
    }
    #acceptance.product-details .summary.coverage .custom-table > ul > li.body > div > div > p:first-of-type {
        min-width: 165px;
        max-width: 165px;
        margin-right: 20px;
        margin-bottom: 0;
    }
    #acceptance.product-details .summary.coverage .custom-table > ul > li.body > div > div > p:last-of-type {
        text-align: right;
    }

    #acceptance.product-details .summary.dependents .custom-table > ul > li.body > div > div {
        display: flex !important;
        justify-content: space-between;
        margin-top: 10px;
    }
    #acceptance.product-details .summary.dependents .custom-table > ul > li.body > div > div > p:first-of-type {
        min-width: 165px;
        max-width: 165px;
        margin-right: 20px;
        margin-bottom: 0;
    }
    #acceptance.product-details .summary.dependents .custom-table > ul > li.body > div > div > p:last-of-type {
        text-align: right;
    }
    #acceptance.product-details .currency {
        margin-left: 0px;
        padding-right: 3px;
        cursor: text;
        border-right: 2px solid lightgray;
        display: inline-block;
        font-family: "Montserrat", "Arial", "Calibri", sans-serif;
        font-weight: 600;
        height: 30px;
        padding-top: 7px;
        /* color: gray; */
    }

    #acceptance.product-details .amount {
        margin-left: 0px;
        padding-left: 3px;
        cursor: text;
        display: inline-block;
        font-family: "Montserrat", "Arial", "Calibri", sans-serif;
        font-weight: 600;
        /* color: gray; */
    }
    
    #acceptance.product-details .checkbox {
        margin-top: 0%;
        padding-right: 0%;
    }

    #acceptance.product-details .currency-amount-container {
        /* margin-left: 17%; */
        float: right;
        margin-top: -2%;
    }
}

@media screen and (max-width: 350px) {

    #rev-prem-charges.product-details .currency-amount-container {
        float: right;
        margin-top: -2%;
    }

}