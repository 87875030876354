#policy-details .form-note {
    margin-top: 40px;
    line-height: 25px;
}
#policy-details .form-note.custom-form-note-1.with-remarks {
    margin: 30px 0;
}
#policy-details .form-note.custom-form-note-1:not(.with-remarks) {
    margin-top: 30px;
}
#policy-details-form .form-loader .form-loader-container { 
    margin-bottom: 90px;
}
#policy-details h2 .big-title {
    letter-spacing: 0.04em;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 600;
    padding: 2px 0 1px;
}
#policy-details .form-cont {
    display: flex;
    justify-content: center;
    margin-top: 2em;
}
#policy-details .form-cont form {
    width: 300px;
    background-color: #f0f0f0;
    padding: 2em 2em 0 2em;
    margin-bottom: 1em;
    max-width: 300px;
}
#policy-details .form-cont form.otp-frm {
    /* width: 300px; */
    background-color: #fff;
}
#policy-details .help-icon {
    color:#1B1464;
    display: inline-flex;
    position: absolute;
    right: 0;
}
#policy-details #policy-details-form .col-case .field {
    margin-bottom: 15px;
}
#policy-details #policy-details-form .col-case {
    margin-bottom: 0;
}
#policy-details a {
    color: #b11116;
    text-decoration: underline;
}

@media screen and (max-width: 875px) {
	#policy-details-form .form-loader .form-loader-container { 
        margin: 75px 0 65px;
    }
    #policy-details .form-cont form {
        width: 38vw;
    }
    #policy-details .form-cont form.otp-frm {
        width: 38vw;
    }
}

@media screen and (max-width: 680px) {
	#policy-details-form .form-loader .form-loader-container { 
        margin: 75px 0 65px;
    }
    #policy-details .form-cont form {
        width: 50vw;
    }
    #policy-details .form-cont form.otp-frm {
        width: 50vw;
    }
}

@media screen and (max-width: 460px) {
    #policy-details .form-note {
        margin-top: 30px;
    }
    #policy-details .form-cont form {
        width: 100vw;
    }
    #policy-details .form-cont form.otp-frm {
        width: 100vw;
    }
}

#policy-details #otp-form .otp-title {
    font-size: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 28px;
    margin: 10px 0 25px;
    color: #1B1464;
}
#policy-details #otp-form .form-fields {
    margin-bottom: 20px;
}
#policy-details #otp-form .form-fields > h2 {
    margin-bottom: 10px;
}
#policy-details #otp-form .form-fields > p {
    margin-bottom: 30px;
    line-height: 22px;
    text-align: justify;
}
#policy-details #otp-form .form-fields .trouble {
    margin: 10px 0 0;
}
#policy-details #otp-form .form-fields .trouble a {
    cursor: pointer;
    color: #b11116;
    transition: color ease .3s;
}
#policy-details #otp-form .form-fields .trouble a:hover {
    color: #1B1464;
}
#policy-details #otp-form .form-fields .otp-options {
    min-height: 115px;
}
#policy-details #otp-form .form-fields .otp-options .option {
    display: flex;
    cursor: pointer;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 3px;
    padding: 17px 15px;
    margin-bottom: 10px;
    transition: background-color ease .3s;
}
#policy-details #otp-form .form-fields .otp-options .option:hover {
    background-color: #e5e5e5;
}
#policy-details #otp-form .form-fields .otp-options .option p {
    min-width: 55px;
}
#policy-details #otp-form .col-case {
    display: flex;
    justify-content: center;
    text-align: center;
}
#policy-details .pd-form-msg {
    min-height: 40px;
    align-items: center;
    color: #b11116;
    font-size: 14px;
    justify-content: center;
    line-height: 18px;
    text-align: unset;
}
#policy-details .form-btns {
    text-align: center;
    margin-top: 20px;
}
#policy-details .form-btns button:nth-of-type(1) {
    width: 48% !important;
    max-width: unset !important;
}
#policy-details .form-btns button:nth-of-type(2) {
    margin-right: 0;
    margin-left: 3px;
    width: 48% !important;
    max-width: unset !important;
}
#policy-details .form-btns button:nth-of-type(3) {
    margin-top: 10px;
    margin-left: 0;
    width: 100% !important;
    max-width: unset !important;
}
#policy-details .trustBrowserQ {
    font-size: 17px;
}
#policy-details .trustBrowserM {
    margin-bottom: 95px;
}
#policy-details .verify-btns {
    display: flex;
    flex-wrap: wrap;
}
#policy-details #otp-form .form-fields .form .col-case .field .mat-form-field-infix {
    background-color: #f8f8f8;
}

@media screen and (max-width: 325px) {
    #policy-details .verify-btns {
        flex-direction: column;
    }
    #policy-details .form-btns button:first-of-type,
    #policy-details .form-btns button:nth-of-type(2) {
        margin-right: 0 !important;
    }
    #policy-details .form-btns button:first-of-type,
    #policy-details .form-btns button:nth-of-type(2) {
        width: 100% !important;
    }
    #policy-details .form-btns button:nth-of-type(2) {
        margin-top: 13px !important;
    }
}