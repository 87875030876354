#stepper {
    position: relative;
    overflow: hidden;
}
#stepper .container {
    position: relative;
    z-index: 2;
}
#stepper .title {
    font-size: 36px;
    font-weight: 300;
    line-height: 28px;
    padding: 36px 0;
    float: left;
    max-width: 520px;
}
#stepper.no-bar-img .bar-img {
    display: none;
}
#stepper .bar-img {
    float: right;
    padding: 38px 0;
}
#stepper .bar-img ul {
    display: table;
    width: 100%;
    overflow: visible;
}
#stepper .bar-img ul li {
    position: relative;
    width: 24px;
    height: 24px;
    float: left;
    margin-left: 48px;
    transition: all ease .3s;
}
#stepper .bar-img ul li:first-child {
    margin-left: 0;
}
#stepper .bar-img ul li::before,
#stepper .bar-img ul li::after {
    content: '';
    position: absolute;
    box-sizing: border-box;
    top: 0;
    bottom: 0;
    margin: auto;
    transition: all ease .3s;
}
#stepper .bar-img ul li::before {
    width: 24px;
    height: 24px;
    background: #ddd;
    left: 0;
    border-radius: 50%;
}
#stepper .bar-img ul li::after {
    right: 24px;
    width: 48px;
    height: 1px;
    background: #ddd;
}
#stepper .bar-img ul li.active::before {
    background: #1B1464;
}
#stepper .bar-img ul li.active::after {
    background: #1B1464;
}
#stepper .bar-img ul li:first-child:after {
    display: none;
}
#stepper .bg-stepper {
    position: absolute;
    right: 0;
    top: 36%;
    bottom: 0;
    margin: auto;
    z-index: 1;
    opacity: 1;
    transition: all ease .3s;
}

@media screen and (max-width: 1280px) {
	#stepper .bg-stepper {
        opacity: .4;
    }
}

@media screen and (max-width: 610px) {
    #stepper .title,
    #stepper .bar-img {
        float: initial;
    }
    #stepper .title {
        text-align: center;
        max-width: 100%;
    }
    #stepper:not(.no-bar-img) .title {
        padding-bottom: 0;
    }
    #stepper .bar-img ul {
        display: flex;
        justify-content: center;
    }
    #stepper:not(.no-bar-img) .bg-stepper {
        opacity: .2;
        top: 50%;
    }
}

@media screen and (max-width: 426px) {
    #stepper .bar-img ul li,
    #stepper .bar-img ul li::before {
        width: 28px;
        height: 28px;
    }
    #stepper .bar-img ul li {
        margin-left: 42px;
    }
    #stepper .bar-img ul li::after {
        width: 46px;
    }
    #stepper.no-bar-img .bg-stepper {
        opacity: .2;
    }
}

@media screen and (max-width: 374px) {
    #stepper .bar-img ul li,
    #stepper .bar-img ul li::before {
        width: 26px;
        height: 26px;
    }
    #stepper .bar-img ul li {
        margin-left: 38px;
    }
    #stepper .bar-img ul li::after {
        width: 40px;
    }
}