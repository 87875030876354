#email-list-input .email-close {
    display: inline-block !important;
    vertical-align: sub;
}

#email-list-input .cc-list {
    padding: 2px;
    border: 1px solid;
    border-radius: 10px;
    margin-right: 3px;
    display: inline-flex;
    width: fit-content;
    align-items: flex-end;
}

#email-list-input .error-email {
    border-color: red;
    background-color: red;
    color: white;
}

#email-list-input .cc-list .mat-icon {
    width: unset !important;
}

.ng-invalid>#email-list-input .mat-form-field-flex {
    border: 2px solid #8f2a2a;
}

#email-list-input .mat-form-field-infix input {
    display: inline-flex;
    width: unset;
    min-width: unset;
}