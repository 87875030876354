#expired-report-link {
    height: 100vh;
    background-color: #fff;
}
#expired-report-link .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 24px;
    font-weight: bold;
}