#sub-initial #sub-initial-form .form-fields h2 {
    margin-bottom: 7px;
}
#sub-initial #sub-initial-form .form-fields > p {
    margin-bottom: 30px;
    font-size: 15px;
}

#sub-initial #sub-initial-form .pwd-reqs > p {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 15px;
    padding-left: 30px;
}

#sub-initial #sub-initial-form .pwd-reqs .N {
    background: url(../../../../../../../assets/images/common/icon-circle-cross-black.png) no-repeat left top;
    color: #b11116;
}

#sub-initial #sub-initial-form .pwd-reqs .Y {
    background: url(../../../../../../../assets/images/common/icon-circle-check-red.png) no-repeat left top;
    color: lightgreen;
    display: none;
}

@media screen and (max-width: 520px) {
    #sub-initial #sub-initial-form .form-fields > p {
        text-align: center;
    }
}