#customer-information .col.title{
    padding-bottom: 2%;
}

#customer-information .field.instructions2 {
    padding-bottom: 4%;
    line-height: 22px;
}

#customer-information .text{
    padding-bottom: 4%;
}

#customer-information .weighted {
    font-weight: 600;
}

#customer-information .backColor{
    background-color: #f0f0f0;
    border-radius: 4px;
    margin-bottom: 24px;
}

#customer-information .compName {
    overflow: hidden;
    margin-bottom: 24px;
}

#customer-information .displayName{
    padding: 1%;
}

#customer-information .field.instructions {
    padding-bottom: 4%;
}

#customer-information pre{
    font-family: inherit;
    white-space: pre-wrap;
}

#customer-information .field.border {
    border-style: solid;
    border-width: medium;
    border-radius: 100px;
    border-color: #d6b72d;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    padding-left: 2%;
    padding-right: 2%;
    text-align: center;
}

#customer-information .viewList{
    color: #302a73;
    font-weight: 600;
    font-size: 16px;
    background: none;
    border: none;
    cursor: pointer;
}

#customer-information .tooltip-list {
    white-space: pre;
}

#customer-information .postal{
    text-transform: uppercase;
}

#customer-information .hide {
    display: none;
}

#customer-information .hidden {
    visibility: hidden;
}

#customer-information .form {
    margin-bottom: 24px;
}

#customer-information .checkbox {/*.field.required label::before {*/
    content: none;
    float: left;
}

#customer-information .tin {
    width: 100% !important;
}

#customer-information .checkbox.tin {
    padding-top: 2%;
}

#customer-information .sameAsPermanent {
    pointer-events: none;
}

#customer-information .disabled {
    background-color: rgba(243, 9, 9, 0.02);
}

ul.list {
    list-style-position: inside;
    width: 335px;
}

li.overlay {
    list-style-type: disc !important;
}

.ocrTag {
    font-size: 14px;
}

/*UPLOADIING IDS*/
#customer-information mat-card {
    height: 250px;
}
#customer-information .image-upload-container, .replace {
    cursor: pointer;
}
#customer-information .img-preview-container {
    background-color: #f7f7f7;
    position: relative;
    height: 200px;
}
#customer-information .img-preview {
    background: center center no-repeat;
    background-size: contain;
    height: 100%;
    width: 100%;
}
#customer-information .textract-container {
    position: relative;
    top: 32%;
}
#customer-information .textract-loader {
    position: relative;
    left: 37%;
    border: 10px solid #ffffff;
    border-top: 10px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
#customer-information .redBorder {
    border: 2px solid #8f2a2a;
    border-radius: 4px;
}





#customer-information .decrease {
    height: 10%;
    width: 77%;
    background-color: #302a73;
    border-color: #302a73;
    color: white;
}
#customer-information .btn-bwm {
    background: url(../../../../../assets/images/policy/customer-information/image-white.png) no-repeat left;
    background-color: #302a73;
    border-color: #302a73;
    color: white;
    height: 10%;
    width: 77%;
    text-indent: 17%;
    background-position: 9% 50%;
}
#customer-information .image, .repImage{
    display: none;
}
#customer-information .id{
    border-style: solid;
    border-width: thin;
    border-color: #302a73;
    background-color: #302a73;
    height: 20%;
    padding: 0;
    margin-bottom: 10%;
}
#customer-information .upload-text{
    padding-top: 25%;
}
#customer-information .drag{
    font-weight: 600;
    /*justify-content: center;
    align-items: center;
    text-align: center;*/
}
#customer-information .upload-text p{
    padding-bottom: 5%;
    text-align: center;
}
#customer-information .button-id {
    padding-left: 21%;
    padding-top: 28%;
}
#customer-information .upload-image{
    font-size: 14px;
}
#customer-information .idGrp{
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-bottom: 10%;
    background-color: #f2f2f2;
}
#customer-information .add{
    /*background: url(../../../../../assets/images/policy/customer-information/id.png) no-repeat left;*/
    color: #ffffff;
    font-weight: 600;
    background-color: #302a73;
    /*line-height: 308px;*/
    line-height: 50px;
    width: 100%;
    height: 100%;
    background-position: 50% 40%;
    border-radius: 2px;
}
#customer-information .delete {
    background-color: #dddddd;
    border-color: #dddddd;
    color: rgb(27, 20, 100);
    min-width: 36%;
    height: 34px;
}
#customer-information .alert{
    height: 200px;
}
#customer-information .replace{
    background: url(../../../../../assets/images/policy/customer-information/image-blue.png) no-repeat left;
    background-color: white;
    border-style: solid;
    border-width: thin;
    border-radius: 4px;
    border-color: #302a73;
    color: #302a73;
    width: 57%;
    text-indent: 11%;
    background-position: 3% 50%;
    margin-left: 3%;
    padding: 3% 1% 3% 2%;
}
#customer-information .mat-card-actions{
    margin-left: 0px;
    margin-right: 0px;
}
#customer-information .replace-image {
    padding-top: 1%;
}
#customer-information .upload {
    width: 100% !important;
    margin-right: 0%;
    margin-bottom: 24px;
}
#customer-information .addId {
    margin-left: 2.3%;
    margin-bottom: 24px;
}
#customer-information .space{
    margin-right: 2.3%;
}
#customer-information .idImg {
    align-items: center;
}
/* FOR PHOTO/VIDEO */
/*#customer-information body {
    background-color: #F0F0F0;
}

#customer-information #app {
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}
#customer-information #video {
    background-color: #000000;
}
#customer-information #canvas {
    display: none;
}
#customer-information li {
    display: inline;
    padding: 5px;
}*/

#customer-information .suffix{
    width: 8.5%;
}

#customer-information .uppercase{
    text-transform: uppercase;
}

#customer-information .middle{
    width: 20.7%;
    margin-right: 2.3%;
}

#customer-information .extend{
    width: 65.5%;
}

#customer-information .red::after
{
    color: red;
    content: " *";
}

.rm-consent{
    max-width: 50vw;
}

.rm-consent .buttons button{
    background-color: #B11116;
    width: 100%;
    color: #FFF;
}

.rm-consent .buttons .recommended{
    font-style: italic;
    font-size: x-small;
    text-align: center;
    margin-bottom: 12px;
}

@media screen and (max-width: 720px) {
    #customer-information .middle{
        width: 65%;
    }
    #customer-information .suffix{
        width: 30%;
        padding-left: 5%;
    }
    #customer-information .extend{
        width: 100%;
    }
    .rm-consent{
        max-width: 80vw;
    }
}

/*Supporting docs*/
#customer-information .upload-docs-container, .replace {
    cursor: pointer;
    font-size: 14px !important;
    text-align: center;
    border-color: #302a73;
    color: #302a73;
    letter-spacing: -0.05em;
    width: 100%;
    font-weight: 600;
    border-width: 2px;
}

#customer-information .row-file-name {
    height: 25px;
}

#customer-information .file-name {
    width: 66%;
}

#customer-information .file-view{
    width: 15%;
    text-align: center;
}

#customer-information .file-remove {
    width: 20%;
    text-align: left;
}

#customer-information .support-docs-buttons{
    color: #302a73;
    font-size: 16px;
    background: none;
    border: none;
    text-decoration-line: underline;
    cursor: pointer;
}

#customer-information .marital-stat {
    font-weight: 700;
    padding-top: 20px;
    font-size: large;
    padding-left: 5px;
}