#footer {
    background: #f0f0f0;
    padding: 42.5px 0;
    min-width: 320px;
    font-family: ProximaNova;
}
#footer > .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#footer > .container.block {
    padding: 30px;
    display: block;
    width: auto;
    font-style: italic;
    font-size: 10px;
    font-weight: bold;
}
#footer img {
    width: 144px;
    height: 45px;
}
#footer > .container > div {
    display: flex;
    font-size: 16px;
}
#footer > .container > div > a {
    margin-left: 48px;
    color: #000;
    cursor: pointer;
    transition: none;
}
#footer > .container > div > a:hover {
    color: #b11116;
    font-weight: bold;
    text-decoration: underline;
}

@media screen and (max-width: 640px) {
    #footer > .container > div > a {
        margin-left: 20px;
    }
}

@media screen and (max-width: 550px) {
    #footer {
        padding: 20px 0;
    }
    #footer > .container {
        display: block;
        text-align: center;
    }
    #footer img {
        width: 132px;
        height: 42px;
    }
    #footer > .container > div {
        display: block;
        margin-top: 25px;
    }
    #footer > .container > div > a {
        margin: 10px 0 0;
        display: block;
        font-size: 100%;
    }
}

@media screen and (max-width: 426px) {
    #footer > .container > div > a:hover {
        color: #000;
        font-weight: initial;
        text-decoration: none;
    }
}
