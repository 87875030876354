/* STEPPER BUTTONS */

#stepper-dtl #btn-save,
#stepper-dtl #btn-print,
#stepper-dtl #btn-email {
    min-width: 120px;
}

#stepper-dtl #btn-continue {
    width: 200px;
}


/* EMAIL / PRINT */

#email-print img {
    width: 100px;
    height: 32px;
}

#email-print table {
    width: 100%;
}

#email-print .form-wrapper {
    width: 100%;
    max-width: 1020px;
    min-width: 320px;
    margin: 0 auto;
    transition: all ease .2s;
    border-top: 4px solid #1B1464;
    box-shadow: #8f2a2a;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0 1px 2px 0px rgb(0 0 0 / 25%);
    box-shadow: 0 1px 2px 0px rgb(0 0 0 / 25%);
}

#email-print .form.email-print {
    margin-bottom: 20px;
    padding-bottom: 8px;
    border-bottom: 0px solid #8f2a2a;
}

#email-print .form>.col-6 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

#email-print .form>.col-6>.field {
    width: 15.87%;
}

#email-print .step-title>.step-btns>button.btn-blue,
#email-print .form-btns>button.btn-blue {
    background-color: #1B1464;
    color: #fff;
}


/* #email-print .step-title>.step-btns>button,
#email-print .form-btns>button {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 600;
    min-width: 60px;
    padding: 12px 15px;
    border-radius: 5px;
    cursor: pointer;
} */

#email-print .mat-row:nth-child(even) {
    background-color: white;
}

#email-print .mat-row:nth-child(odd) {
    background-color: whitesmoke;
}

#email-print .mat-table {
    font-family: 'Montserrat', sans-serif;
    box-shadow: none;
}

#email-print .field.required .field-desc {
    justify-content: start;
}

#email-print .form-wrapper .mat-form-field-flex {
    background: transparent;
}

#email-print table {
    width: 100%;
    vertical-align: middle;
    min-width: 400px;
    overflow-x: scroll;
}

#email-print table tr.mat-row,
#email-print table tr.mat-header-row {
    height: 38px;
}

#email-print table tr.mat-row td,
#email-print table tr.mat-header-row th {
    vertical-align: middle;
}

#email-print table tr.mat-row td:first-child {
    font-weight: 550;
}

#email-print table tr.mat-header-row th {
    font-weight: 700;
    color: black;
}

#email-print .number {
    text-align: end;
}

#email-print .form-wrapper .container:first-child {
    margin-top: 0px;
    padding-top: 20px;
    background-color: #fafafc;
}

#email-print .weighted {
    font-weight: 600;
}

#email-print #totalTable th {
    display: none;
}

#email-print .mat-form-field-infix {
    display: flex;
}

#email-print .mat-form-field-infix .mat-icon {
    font-size: 20px;
}

#email-print .col-3 .field-2 {
    width: 63%;
    max-width: unset;
}

#email-overlay {
    width: 90vw;
    max-width: 500px;
}

#email-print .form-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

#email-print .form-wrapper p {
    margin: 10px 0;
    padding: 0 12px;
    text-align: center;
}

#email-print .form-wrapper .spinner-container {
    flex-basis: 100%;
}

#email-print .col-3 .field-1,
#email-print .col-3 .field-2 {
    width: 48.8%;
}

@media screen and (max-width: 766px) {
    #email-print .col-3 .field-1,
    #email-print .col-3 .field-2 {
        width: 100%;
    }
}

#email-print .field.border {
    border-radius: 100px;
    border: solid #d6b72d;
    padding: 1.5% 2%;
    text-align: center;
}

#email-overlay .form-note {
    margin-bottom: 15px;
}

#email-print .instructions-bottom {
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: justify;
    font-style: italic;
}