/* FONTS */

@import './assets/css/fonts.css';

/* THIRD PARTY */

@import '~swiper/swiper-bundle.css';

/* GLOBAL */

@import './assets/css/reset.css';
@import './assets/css/common.css';
@import './assets/css/material.css';
@import './assets/css/bx-slider.css';
@import './assets/css/modified-material.css';
@import './assets/css/form.css';
@import './assets/css/product-details.css';
@import './assets/css/custom-table.css';
@import './assets/css/spinner.css';

/* COMMON */

@import './app/components/common/app-message/app-message.css';
@import './app/components/common/lov/lov.component.css';
@import './app/components/common/loading/loading.component.css';
@import './app/components/common/message-box/message-box.css';
@import './app/components/common/email-list/email-list.component.css';
@import './app/components/use-of-cookies/use-of-cookies.css';
@import './app/components/header/header.css';
@import './app/components/footer/footer.css';
@import './app/components/home/home.css';
@import './app/components/contact-us/contact-us.css';
@import './app/components/renewal-redirect/renewal-redirect.component.css';
@import './app/components/payment-redirect/payment-redirect.css';
@import './app/components/faq/faq.css';
@import './app/components/dashboard/dashboard.css';
@import './app/components/profile/profile.css';
@import './app/components/common/overlay/overlay.css';
@import './app/components/maintenance/maintenance.css';
@import './app/components/expired-report-link/expired-report-link.css';
@import './app/components/common/popup/popup.component.css';

/* FORMS */

@import './app/components/forms/stepper/stepper.css';
@import './app/components/forms/stepper/stepper-dtl/stepper-dtl.css';
@import './app/components/forms/login-register/login-register.css';
@import './app/components/forms/login-register/registration/registration.css';
@import './app/components/forms/login-register/registration/subform/sub-register/sub-register.css';
@import './app/components/forms/login-register/login/login.css';
@import './app/components/forms/login-register/login/subform/sub-otp/sub-otp.css';
@import './app/components/forms/login-register/login/subform/sub-initial/sub-initial.css';
@import './app/components/forms/login-register/login/subform/sub-reset/sub-reset.css';
@import './app/components/forms/login-register/login/subform/sub-forgot/sub-forgot.css';
@import './app/components/forms/quotation/introduction/introduction.css';
@import './app/components/forms/quotation/choose-plan/choose-plan.css';
@import './app/components/forms/quotation/vehicle-details/vehicle-details.css';
@import './app/components/forms/quotation/property-assessment/property-assessment.css';
@import './app/components/forms/quotation/property-details/property-details.css';
@import './app/components/forms/quotation/coverage-perils/coverage-perils.css';
@import './app/components/forms/quotation/rev-prem-charges/rev-prem-charges.css';
@import './app/components/forms/quotation/email-print/email-print.css';
@import './app/components/forms/policy/customer-information/customer-information.css';
@import './app/components/forms/policy/acceptance/acceptance.css';
@import './app/components/forms/policy/payment/payment.css';
@import './app/components/forms/policy/pol-summary/pol-summary.component.css';
@import './app/components/forms/payment-and-renewal/policy-details-validation/policy-details-validation.css';
