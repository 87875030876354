input {
    font-weight: bold !important;
}
::placeholder {
    font-weight: normal;
    color: #e0e0e0 !important;
    text-align: left;
}
form .form-fields {
    display: block;
}
#stepper-dtl .btn-wrap.hide,
form .form-fields.hide,
form .form-btns.hide {
    display: none;
}
.form {
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
.form-fields .form:not(:last-of-type) {
    margin-bottom: 30px;
}
.form .title {
    text-transform: uppercase;
    font-family: "Arial", "Calibri", sans-serif;
    font-size: 22px;
    letter-spacing: .04em;
    color: #000;
    margin-bottom: 20px;
}
.form .field {
    position: relative;
}
.form .field.detail .value {
    font-family: "Inter", "Arial", "Calibri", sans-serif;
    color: #000;
    font-weight: 700;
    margin-top: 8px;
}
form .form-msg {
    padding: 11px 20px;
    background: #fff;
    font-weight: 400;
    border-radius: 5px;
    font-family: "Montserrat", "Arial", "Calibri", sans-serif;
}
form .top .form-msg {
    margin-bottom: 25px;
}
form .bottom .form-msg {
    margin-top: 25px;
}
.form-note {
    font-weight: 400;
    font-family: "Montserrat", "Arial", "Calibri", sans-serif;
}
form .amount-field {
    text-align: right;
}
.form-note a {
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
    color: #1B1464;
}
form .form-msg.success {
    border: 1px solid #219653;
    background-color: #21965333;
}
form .form-msg.error {
    border: 1px solid #b11116;
    background-color: #b1111633;
}
form .form-msg.info {
    border: 1px solid #D5B527;
    background-color: #D5B52733;
}
form .form-msg p {
    text-align: center;
    line-height: 24px;
}
.form .field label {
    margin-bottom: 10px;
    font-family: "Inter", "Arial", "Calibri", sans-serif;
    font-weight: 400;
    line-height: 16px;
    color: #373F41;
    display: inline-block;
    position: relative;
}
.form .field:not(.required) label.rdo-label {
    display: block;
}
.form .field > .field-error {
    color: #b1111f;
    margin-top: 9px;
    line-height: 15px;
    font-family: "Inter", "Arial", "Calibri", sans-serif;
    font-size: 12px;
}
.form .field .tooltip {
    position: absolute;
    right: 0;
    top: 2px;
}
.form .field label.rdo-label {
    font-family: "Inter", "Arial", "Calibri", sans-serif;
    font-size: 16px;
}
.form .field.required label::before {
    content:"*";
    padding-left: 5px;
    float: right;
    color: red;
}
.form .field.required mat-radio-group {
    display: block;
}
.form .field.required mat-radio-group label::before {
    display: none;
}
.form .col-case {
    overflow: hidden;
    margin-bottom: 24px;
}
.form .col-case:last-of-type,
.form .with-form-msg .col-case:last-of-type {
    margin-bottom: 0;
}
.form .col-case .field {
    float: left;
    width: 100%;
}
.form .col-2 .field {
    width: 49%;
    margin-right: 2%;
}
.form .col-2 .field:nth-child(even) {
    margin-right: 0;
}
.form .col-3 .field {
    width: 31.8%;
    margin-right: 2.3%;
}
.form .col-3 .field.col-2 {
    width: 65.9%;
    margin-right: 2.3%;
}
.form .col-3 .field:nth-child(3n),
.form .col-3 .field:last-of-type {
    margin-right: 0;
}
.form .col-4 .field {
    width: 23.31%;
    margin-right: 2.25% ;
}
.form .col-4 .field:nth-child(4n) {
    margin-right: 0;
}
.form .col-5 .field {
    width: 19.5%;
    margin-right: 0.5% ;
}
.form .col-5 .field:nth-child(5n),
.form .col-3 .field:last-of-type {
    margin-right: 0;
}
.form-btns {
    margin: 30px 0 42px;
    text-align: right;
}
.form-btns .btn {
    display: inline-block;
    margin: 0 8px;
    min-width: 144px;
    padding: 13px 10px;
    font-family: "ProximaNova", "Arial", "Calibri", sans-serif;
    font-size: 16px;
    font-weight: 600;
}
.form-btns .btn:first-of-type {
    margin-left: 0;
}
.form-btns .btn:last-of-type {
    margin-right: 0;
}
.form-btns .btn4 {
    border: 1px solid #E0E0E0;
}
.form-btns .btn2 {
    border: 1px solid #B11116;
}
.box-wrap {
    margin-top: 50px;
    border-radius: 5px;
    padding: 18px 24px;
    box-shadow: 0px 1px 2px rgba(27, 20, 100, 0.24);
    font-family: "ProximaNova", "Arial", "Calibri", sans-serif;
}
.box-wrap table:last-of-type {
    margin-bottom: 0;
}
.form .box-wrap,
.form table {
    margin: 0 0 20px;
}
.btn-wrap {
    text-align: center;
}
.btn-wrap.right-aligned {
    text-align: right;
}
.btn-wrap.left-aligned {
    text-align: left;
}
.btn-wrap .btn {
    display: inline-block;
    margin: 0 8px;
}
.btn-wrap .btn:first-of-type {
    margin-left: 0;
}
.btn-wrap .btn:last-of-type {
    margin-right: 0;
}
button:disabled {
    background-color: #a9a9a9 !important;
    cursor: not-allowed;
    border: 1px solid #a9a9a9 !important;
    opacity: .6;
}
button:disabled:hover {
    background-color: #a9a9a9 !important;
    border: 1px solid #a9a9a9 !important;
}
.supporting-docs {
    padding-top: 4px;
}
.supporting-docs > button {
    font-size: 14px;
    width: 100%;
    min-height: 48px;
}
.supporting-docs > button:hover {
    background-color: initial;
    color: #1b1464;
}
.supporting-docs .error-msg {
    font-size: 15px;
    color: #b11116;
    padding-top: 15px;
    line-height: 20px;
}
.supporting-docs > .files {
    padding-top: 7px;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
}
.supporting-docs > .files:first-of-type {
    padding-top: 15px;
}
.supporting-docs > .files .buttons {
    display: flex;
    color: #1b1464;
}
.supporting-docs > .files .buttons p {
    padding-left: 15px;
    text-decoration: underline;
    cursor: pointer;
}

/* table style 01*/
.tb-style01 {
    table-layout: fixed;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-bottom: 10px;
    letter-spacing: .5px;
}
.tb-style01 th {
    font-weight: normal;
    vertical-align: middle;
    padding: 0 5px 6px 0;
}
.tb-style01 td {
    font-weight: 600;
    font-size: 16px;
    color: #000;
}

/* FORM LOADER */
.form-loader .form-loader-container {
    margin: 50px 0 40px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.form-loader img {
    width: 35px;
    position: absolute;
    top: 20px;
    transition: all ease .3s;
}
.form-loader .loader-logo {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: rotate 3s linear infinite;
}
.form-loader .loader-logo .circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    border: 10px solid transparent;
}
.form-loader .loader-logo .circle::before,
.form-loader .loader-logo .circle::after {
    content: '';
    position: absolute;
    height: 10px;
    width: 10px;
    border-radius: 50%;
}
.form-loader .loader-logo .circle::before {
    top: 5px;
    right: 5px;
}
.form-loader .loader-logo .circle::after {
    top: 4px;
    left: 5px;
}
.form-loader .loader-logo .circle.circle-1 {
    border-top-color: #B11116;
}
.form-loader .loader-logo .circle.circle-1::before,
.form-loader .loader-logo .circle.circle-1::after {
    background-color: #B11116;
}
.form-loader .loader-logo .circle.circle-2 {
    border-top-color: #1B1464;
    transform: rotate(120deg);
}
.form-loader .loader-logo .circle.circle-2::before,
.form-loader .loader-logo .circle.circle-2::after {
    background-color: #1B1464;
}
.form-loader .loader-logo .circle.circle-3 {
    border-top-color: #D5B527;
    transform: rotate(240deg);
}
.form-loader .loader-logo .circle.circle-3::before,
.form-loader .loader-logo .circle.circle-3::after {
    background-color: #D5B527;
}
.form-loader .loader-msg {
    width: 100%;
    margin-top: 40px;
    text-align: center;
    box-sizing: border-box;
    padding: 8px;
    border-radius: 50px;
    letter-spacing: .3px;
    line-height: 22px;
}
.see-more > .button {
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;
}
.see-more > .button button {
    font-family: "ProximaNova", "Arial", "Calibri", sans-serif;
    font-size: 14px;
    font-weight: bold;
    padding: 5px 15px;
    border-radius: 15px;
    min-width: unset;
}

@keyframes rotate {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 875px) {
    .form-btns {
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 801px) {
    .see-more > .button {
        justify-content: center;
        padding-top: 20px;
        padding-right: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.08);
    }
    .see-more > .button button {
        padding: 8px 23px;
        border-radius: 20px;
    }
}

@media screen and (max-width: 767px) {
    .box-wrap {
        margin-top: 25px;
        padding: 12px 15px;
        overflow-x: scroll;
    }
    .tb-style01 {
        min-width: 600px;
    }
    .tb-style01 th,
    .tb-style01 td {
        font-size: 120%;
    }
    .form .col-5 .field,
    .form .col-4 .field,
    .form .col-3 .field,
    .form .col-3 .field.col-2 {
        width: 49%;
        max-width: none;
        margin-right: 2%;
    }
    .form .col-3 .field:not(:last-of-type):nth-child(even) {
        margin-bottom: 24px;
    }
    .form .col-5 .field:nth-child(even),
    .form .col-4 .field:nth-child(even),
    .form .col-3 .field:nth-child(even) {
        margin-right: 0;
    }
    .form .col-3 .field:nth-child(3n):nth-child(odd) {
        margin-right: 2%;
    }
    .form .col-3 .field:last-child:nth-child(odd) {
        width: 100%;
    }
    .form-btns {
        text-align: center;
    }
}

@media screen and (max-width: 686px) {
    .form-btns.style-4 {
        flex-wrap: wrap;
    }
    .form-btns.style-4 button {
        width: 48%;
        margin-right: 3%;
        margin-left: 0;
    }
    .form-btns.style-4 button:nth-of-type(even) {
        margin-right: 0;
    }
    .form-btns.style-4 button:nth-of-type(3),
    .form-btns.style-4 button:nth-of-type(4) {
        margin-top: 20px;
    }
}

@media screen and (max-width: 640px) {
    .form-btns .btn {
        min-width: 120px;
        padding: 11px 11px;
        font-size: 15px;
    }
}

@media screen and (max-width: 490px) {
    .form {
        padding-bottom: 25px;
        margin-bottom: 25px;
    }
    .form .col-case {
        margin-bottom: 5%;
    }
    .form .col-2 .field,
    .form .col-4 .field,
    .form .col-5 .field,
    .form .col-3 .field,
    .form .col-3 .field.col-2 {
        width: 100%;
        max-width: none;
        margin: 0 0 5% !important;
    }
    .form .col-case .field:last-of-type {
        margin-bottom: 0 !important;
    }
    mat-radio-group {
        margin-bottom: 15px;
    }
    .mat-radio-button:not(:first-of-type) {
        margin-top: 17px;
    }
    .form .field label.rdo-label {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 476px) {
    .form-btns {
        flex-wrap: wrap;
        margin: 30px 0;
    }
    .form-btns button {
        width: 100%;
        margin-top: 13px !important;
        margin-left: 0 !important;
        padding: 13px 11px !important;
    }
    .form-btns.style-1 button {
        width: 100% !important;
    }
    .form-btns button:nth-of-type(1),
    .form-btns button:nth-of-type(2) {
        width: 48%;
        margin-right: 4% !important;
        margin-top: 0 !important;
    }
    .form-btns button:nth-of-type(2),
    .form-btns button:last-of-type {
        margin-right: 0 !important;
    }
}

@media screen and (max-width: 426px) {
    .form .title {
        font-size: 20px;
    }
    .see-more > .button {
        padding-top: 15px;
    }
    .see-more > .button button {
        padding: 7px 18px;
    }
}

@media screen and (max-width: 351px) {
    .form .title {
        font-size: 18px;
    }
}


/* FLEX TABLE */
.flex-table{
    border-collapse: separate;
    border-spacing: 0;
    margin-bottom: 10px;
    letter-spacing: .5px;
}

.flex-table .td{
    font-weight: 600;
    font-size: 16px;
    color: #000;
}

.flex-table .th{
    font-weight: normal;
    vertical-align: middle;
    padding: 0 5px 6px 0;
}

@media screen and (max-width: 767px){
    .flex-table .th, .flex-table .td {
        font-size: 120%;
    }

}