#sub-forgot.account #sub-forgot-form .form-fields h2 {
    margin-bottom: 75px;
}
#sub-forgot #sub-forgot-form .form-fields > p {
    margin-bottom: 30px;
    font-size: 15px;
}
#sub-forgot.account .form-btns {
    margin: 98px 0 10px;
    text-align: center;
}

@media screen and (max-width: 520px) {
    #sub-forgot #sub-forgot-form .form-fields > p {
        text-align: center;
    }
}