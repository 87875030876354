* {
    -webkit-tap-highlight-color: transparent;
}
html {
    font-size: 62.5%;
}
html.no-scroll {
	overflow: hidden;
}
body {
    font-family: "Arial", "Calibri", sans-serif;
	font-size: 16px;
    color: #4A4A4A;
    background: #f8f8f8;
	min-width: 320px;
}
#main-content {
	min-height: calc(100vh - 190px);
    margin-top: 60px;
}
section {
	width: 100%;
	margin: 0 auto 0;
	padding: 0;
}
.container { 
	max-width: 944px; /* 1040px; */
	width: 93%;
	margin: 0 auto;
}
section.section_box { 
	padding: 80px 0;
}
section.section_box:last-of-type {
	padding: 80px 0 100px;
}
.bold {
    font-weight: bold;
}
.bg1 { 
	background: #FFF;
}
.btn {
	border-radius: 5px;
	text-align: center;
	box-sizing: border-box;
	cursor: pointer;
	min-width: 144px;
	padding: 13px 11px;
	border: 1px solid #b11116;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0.04em;
    transition: background-color ease .3s, color ease .3s, border-color ease .3s;
}
.btn:focus {
	outline: 0;
}
.btn1 {
	background-color: #FFF;
	color: #B11116;
}
.btn1:hover {
	background-color: #f0f0f0;
	border-color: #f0f0f0;
}
.btn2 {
	background-color: #B11116;
	color: #FFF;
	border-color: #B11116;
}
.btn2:hover {
	background-color: #8B0F13;
	border-color: #8B0F13;
}
.btn3 {
	background-color: #1B1464;
	color: #FFF;
	border-color: #1B1464;
}
.btn3:hover {
	background-color: #110d36;
	border-color: #110d36;
}
.btn4 {
	background-color: #E0E0E0;
	color: #000;
	border-color: #E0E0E0;
}
.btn4:hover {
	background-color: #a3a3a3;
	border-color: #a3a3a3;
}
.btn5 {
	border: 1px solid #B11116;
	background-color: transparent;
	color: #B11116;
}
.btn5:hover {
	background-color: #B11116;
	color: #FFF;
	border-color: #B11116;
}
.btn6 {
	border: 1px solid #1B1464;
	background-color: transparent;
	color: #1B1464;
}
.btn6:hover {
	background-color: #1B1464;
	color: #FFF;
	border-color: #1B1464;
}
.top-border::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 5px;
    top: 0;
    left: 0;
    border-radius: 3px;
}
.top-border.border1::before {
    background-color: #B11116;
}
.top-border.border2::before {
    background-color: #1B1464;
}
.top-border.border3::before {
    background-color: #219653;
}
.top-border.border4::before {
    background: linear-gradient(270deg, #B11116 0%, #D5B527 100%);
}
.section-title {
    display: flex;
    justify-content: space-between;
}
.section-title h2 {
    font-size: 36px;
    font-family: "Montserrat", "Arial", "Calibri", sans-serif;
    color: #1B1464;
    margin: 30px 0;
    font-weight: 300;
}
.section-title .form-btns {
    margin: 30px 0;
}
.section-sub-title {
    margin: 15px 0;
}
.section-sub-title h3 {
    font-size: 20px;
    font-family: "Montserrat", "Arial", "Calibri", sans-serif;
    color: #000;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
}

agm-map {
    height: 400px;
}

@media screen and (max-width: 875px) {
    .section-title {
        flex-direction: column;
    }
    .section-title h2 {
        margin-bottom: 0;
    }
    .section-title .form-btns {
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }
}

@media screen and (max-width: 767px) {
	section.section_box {
		padding: 40px 0;
	}
	section.section_box:last-of-type {
		padding: 40px 0 60px;
	}
}

@media screen and (max-width: 640px) {
    .section-title h2 {
        font-size: 34px;
    }
}

@media screen and (max-width: 550px) {
	#main-content {
		min-height: calc(100vh - 215.67px);
	}
}

@media screen and (max-width: 476px) {
    .section-title h2 {
        font-size: 30px;
    }
}

@media screen and (max-width: 426px) {
	.btn1:hover {
		background-color: #FFF;
		color: #B11116;
		border-color: unset;
	}
	.btn2:hover {
		background-color: #B11116;
		border-color: #B11116;
	}
	.btn3:hover {
		background-color: #1B1464;
		border-color: #1B1464;
	}
	.btn4:hover {
		background-color: #E0E0E0;
		border-color: #E0E0E0;
	}
	.btn5:hover {
		border: 1px solid #B11116;
		background-color: transparent;
		color: #B11116;
	}
	.btn6:hover {
		border: 1px solid #1B1464;
		background-color: transparent;
		color: #1B1464;
	}
}

.overlay-header {
    color:#1B1464;
}

.osp-overlay{
    max-width: fit-content !important;
}