button.choosePlan {
    min-width: 49%;
    margin-left: 0px !important;
}

.overlay-req-qu .req-qu-proceed {
    margin-left: 6px;
}

.overlay-req-qu .req-qu-msg {
    font-weight: 600;
    margin: 1rem;
}