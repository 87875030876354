
/* Sending Payment Link Options Message Box CSS */

#message-box .payt-link-send-opts {
    width: 96%;
    max-width: 304px;
    margin: 25px auto 0;
}
#message-box .payt-link-send-opts .payt-link-send-opt {
    display: flex;
    cursor: pointer;
    align-items: center;
    background-color: #e8e8e8;
    border-radius: 3px;
    padding: 17px 20px;
    margin-bottom: 10px;
    transition: background-color .3s ease;
}
#message-box .payt-link-send-opts .payt-link-send-opt:last-of-type {
    margin-bottom: 0;
}
#message-box .payt-link-send-opts .payt-link-send-opt:hover {
    background-color: #cfcfcf;
}
#message-box .payt-link-send-opts .payt-link-send-opt p {
    min-width: 55px;
}

/* Payment Element CSS */

#payment .container > .form {
    display: flex;
    justify-content: space-between;
}
#payment #generate-payment-link-btn {
    display: block;
    margin: 30px auto;
    font-size: 14px;
    width: 75%;
    padding: 10px;
}
#payment #gen-link-text {
    text-align: justify;
    padding: 0 25px;
    line-height: 22px;
}
#payment .container>.form.terms {
    padding-bottom: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
#payment .container>.form.terms .col-case {
    display: flex;
}
#payment .purchase-info>div {
    position: relative;
    padding: 40px;
    box-sizing: border-box;
    min-width: 390px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 1px 2px rgba(27, 20, 100, 0.24);
}
#payment .purchase-info>div>div:first-of-type>h2 {
    font-size: 20px;
    font-weight: bold;
    color: #4a4a4a;
    text-transform: uppercase;
    margin-bottom: 17px;
    letter-spacing: .5px;
}
#payment .purchase-info>div .policy {
    display: flex;
}
#payment .purchase-info>div .policy img {
    height: 144px;
    margin-right: 12px;
}
#payment .purchase-info>div .policy .policy-info .info {
    margin-bottom: 12px;
}
#payment .purchase-info>div .policy .policy-info .info p {
    font-size: 14px;
}
#payment .purchase-info>div .policy .policy-info .info p:first-of-type {
    color: #3E3F42;
    margin-bottom: 5px;
}
#payment .purchase-info>div .policy .policy-info .info p:last-of-type {
    font-weight: 600;
    color: #000;
}
#payment .purchase-info>div .policy .policy-info .amount-sp {
    display: none;
}
#payment .purchase-info>div .amounts {
    margin: 20px 0;
    padding: 20px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    border-bottom: 4px solid rgba(0, 0, 0, 0.08);
}
#payment .purchase-info>div .amount {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
#payment .purchase-info>div .amount:last-of-type {
    margin-bottom: 0;
}
#payment .purchase-info>div .amount p:last-of-type {
    color: #000;
    font-size: 15px;
    font-weight: bold;
}
#payment .purchase-form {
    padding: 5px 30px 0;
    width: 100%;
    max-width: 405px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
#payment .terms-form {
    padding: 5px 30px 0;
    width: 100%;
    max-width: 405px;
}
#payment .purchase-form .payment-options h2 {
    font-size: 20px;
    font-family: "Arial", "Calibri", sans-serif;
    color: #000;
    text-transform: uppercase;
}
#payment .purchase-form .payment-options .options {
    display: flex;
    padding-top: 30px;
    flex-wrap: wrap;
    overflow: auto;
    max-height: 240px;
    position: relative;
    right: -25px;
}
#payment .purchase-form .payment-options .options .option {
    margin-top: 25px;
    margin-right: 25px;
    cursor: pointer;
    width: 101px;
}
#payment .purchase-form .payment-options .options .option:nth-of-type(1),
#payment .purchase-form .payment-options .options .option:nth-of-type(2),
#payment .purchase-form .payment-options .options .option:nth-of-type(3) {
    margin-top: 0;
}
#payment .purchase-form .payment-options .options .option:last-of-type {
    margin-right: 0;
}
#payment .purchase-form .payment-options .options .option>div {
    position: relative;
    width: 95px;
    height: 55px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0px 1px 2px rgba(27, 20, 100, 0.24);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #fff;
    transition: border ease .3s;
}
#payment .purchase-form .payment-options .options .option:hover>div {
    border: 3px solid #1B1464;
}
#payment .purchase-form .payment-options .options .option>div img {
    height: 30px;
}
#payment .purchase-form .payment-options .options .option>p {
    font-size: 13px;
    text-align: center;
    margin-top: 10px;
}
#payment .purchase-form .payment-options .options .option.selected>div {
    border: 3px solid #1B1464 !important;
}
#payment .purchase-form .payment-options .options .option.selected>div::before {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: #1B1464;
    border-radius: 50%;
    top: -10px;
    right: -10px;
    background-image: url(../../../../../assets/images/common/icon-check-white.png);
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: center;
}
#payment .purchase-form .payment-options .options .option.selected>p {
    color: #1B1464;
    font-weight: 600;
}
#payment .purchase-form .payment-form {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: center;
}
#payment .purchase-form .payment-form>form>.form {
    padding-bottom: 0;
    border-bottom: unset;
}

#payment .terms-text {
    text-align: justify;
}

/* OLD OTP CSS */

.x-large {
    font-size: x-large;
}
.larger {
    font-size: larger;
}
.otp-processing-container {
    width: 315px;
    display: flex;
    justify-content: center;
    min-height: 20vh;
    align-items: center;
}
.otp-processing-container .processing circle {
    stroke: #B11116;
}
.otp-container .weighted {
    font-weight: 600;
}
.otp-container {
    display: flex;
    justify-content: center;
    width: 315px;
    flex-wrap: wrap;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 95%;
    max-width: 325px;
    min-height: 30vh;
}
.otp-container .icon {
    width: 50%;
    margin-bottom: 10px;
}
.otp-container>div {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    padding: 0 25px;
    text-align: center;
}
.otp-actions {
    justify-content: center;
}
.otp-actions button {
    flex-basis: 100%;
}
.otp-actions button .mat-button-wrapper {
    display: flex;
    justify-content: center;
	padding: 5px 0px;
}
.otp-actions button .mat-button-wrapper circle {
    stroke: white;
}
.otp-processing-container {
    width: 315px;
    display: flex;
    justify-content: center;
    min-height: 20vh;
}
.otp-container .input-container {
    justify-content: space-between;
    margin: 20px 0;
}
.otp-container .input-container form {
    justify-content: space-between;
    display: flex;
}
.otp-container .input-container input {
    border-bottom: solid black 3px;
    flex-basis: 12%;
    font-size: xx-large;
    caret-color: black;
}
.otp-container .resend {
    color: #015692;
}
.otp-container .resend:hover {
    text-decoration: underline;
    cursor: pointer;
}
.otp-container .error-msg {
    font-size: smaller;
    color: red;
}
.otp-message-top {
    margin-top: 25px;
}
.otp-message-bottom {
    margin-bottom: 20px;
}

@media screen and (max-width: 925px) {
    #payment .container>.form {
        flex-direction: column;
    }
    #payment .purchase-info>div {
        display: flex;
    }
    #payment .purchase-info>div>div:first-of-type {
        margin-right: 30px;
        min-width: 400px;
    }
    #payment .purchase-info>div>div:last-of-type {
        width: 65%;
    }
    #payment .purchase-info>div>div .policy {
        border-right: 1px solid rgba(0, 0, 0, 0.08);
    }
    #payment .purchase-info>div>div .policy img {
        margin-right: 15px;
        width: 45%;
        height: unset;
    }
    #payment .purchase-info>div>div .policy .policy-info {
        width: 100%;
    }
    #payment .purchase-info>div .amounts {
        margin-top: 40px;
        padding-top: 0;
        border-top: unset;
    }
    #payment .purchase-info>div .policy .policy-info .info p,
    #payment .purchase-info>div .amount p:first-of-type {
        font-size: 15px;
    }
    #payment .purchase-info>div .amount p:last-of-type {
        font-size: 14px;
    }
    #payment .purchase-form {
        max-width: unset;
        width: unset;
        padding: unset;
        margin-top: 40px;
    }
    #payment .purchase-form .payment-form form {
        width: 100%;
    }
    #payment .purchase-form .payment-options .options {
        justify-content: center;
        position: unset;
    }
    #payment .purchase-form .payment-options .options .option {
        margin-top: 0;
    }
}

@media screen and (max-width: 860px) {
    #payment .purchase-info>div .policy .policy-info .amount-sp {
        display: block;
    }
    #payment .purchase-info>div>div:last-of-type {
        display: none;
    }
    #payment .purchase-info>div>div .policy {
        border-right: unset;
    }
    #payment .purchase-info>div>div .policy img {
        height: 250px;
        margin-right: 25px;
    }
    #payment .purchase-info>div>div:first-of-type {
        width: 100%;
        margin-right: 0;
    }
    #payment .purchase-info>div .amounts {
        margin-top: 20px;
        border-top: 1px solid rgba(0, 0, 0, 0.08);
        padding-top: 20px;
    }
}

@media screen and (max-width: 625px) {
    #payment .purchase-info>div>div .policy {
        flex-direction: column;
        align-items: center;
    }
    #payment .purchase-info>div>div .policy img {
        height: 280px;
        width: 215px;
        margin-right: unset;
        margin-bottom: 40px;
    }
    #payment .purchase-info>div .policy .policy-info .amount-sp {
        display: none;
    }
    #payment .purchase-info>div>div:last-of-type {
        display: block;
        width: 100%;
    }
    #payment .purchase-info>div {
        flex-direction: column;
    }
    #payment .purchase-info>div .policy .policy-info .info:nth-of-type(3) {
        margin-bottom: 0;
    }
    #payment .purchase-info>div .policy .policy-info .info p,
    #payment .purchase-info>div .amount p:first-of-type {
        font-size: 16px;
    }
    #payment .purchase-info>div .amount p:last-of-type {
        font-size: 15px;
    }
    #payment .purchase-info>div>div:first-of-type {
        min-width: unset;
    }
}

@media screen and (max-width: 570px) {
    #payment .purchase-form .payment-options .options {
        justify-content: space-between;
        padding: 30px 30px 0;
    }
    #payment .purchase-form .payment-options .options .option {
        margin-top: 25px;
        margin-right: 0;
        width: 29%;
        min-width: 101px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #payment .purchase-form .payment-options .options .option:nth-of-type(1),
    #payment .purchase-form .payment-options .options .option:nth-of-type(2),
    #payment .purchase-form .payment-options .options .option:nth-of-type(3) {
        margin-top: 0;
    }
}

@media screen and (max-width: 480px) {
    #payment .container>.form {
        padding-bottom: 0;
        margin-bottom: 0;
    }
    #payment .login-bpi-cont {
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 436px) {
    #payment .purchase-form .payment-options .options {
        padding: 30px 5px 0;
    }
}

@media screen and (max-width: 426px) {
    #payment .purchase-form .payment-options .options .option:hover>div {
        border: 3px solid #fff;
    }
    #payment .purchase-info>div {
        padding: 30px 20px;
        min-width: unset;
    }
}

@media screen and (max-width: 400px) {
    #payment .purchase-form .payment-options h2 {
        border-top: 1px solid rgba(0, 0, 0, 0.08);
        padding-top: 25px;
    }
    #payment .purchase-form {
        margin-top: 30px;
    }
    #payment #gen-link-text {
        padding: 0;
    }
}

@media screen and (max-width: 380px) {
    #payment .purchase-info>div .amount {
        margin-bottom: 5px;
    }
    #payment .purchase-info>div .policy .policy-info .info p,
    #payment .purchase-info>div .amount p:first-of-type {
        font-size: 15px;
    }
    #payment .purchase-info>div .amount p:last-of-type {
        font-size: 14px;
    }
}

@media screen and (max-width: 351px) {
    #payment .purchase-form .payment-options .options .option>div {
        width: 85px;
    }
    #payment .purchase-form .payment-options .options .option>div img {
        height: 27px;
    }
    #payment .purchase-form .payment-options .options .option {
        min-width: 91px;
    }
}

@media screen and (max-width: 330px) {
    #payment .purchase-info>div {
        padding: 30px 15px;
    }
}

#payment .credit-debit-form {
    padding-bottom: 15px;
}

#payment .no-boder {
    border: none !important;
}