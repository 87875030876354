#lov-container .mat-table .mat-header-row,
#lov-container .mat-table .mat-row {
    min-height: 30px;
}

#lov-container .mat-table .mat-cell {
    font-size: 12px;
}

#lov-container .mat-table {
    width: 100%;
}

#lov-container .mat-row:nth-child(odd) {
    background-color: whitesmoke;
}

#lov-container {
    background-color: #f8f8f8;
    min-width: 30vw;
}

#lov-container .mat-form-field-wrapper {
    padding-bottom: 1px;
}

#lov-container .mat-table .mat-header-row .mat-header-cell {
    font-weight: 700;
    color: black;
}

#lov-container .mat-table .mat-row {
    cursor: pointer;
}

#lov-container .table-container {
    padding: 5px 0px 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    border-top: 1px solid rgba(0, 0, 0, 0.08);
}

#lov-container .mat-paginator-range-actions {
    width: 100%;
    place-content: flex-end;
}

#lov-container .mat-paginator-range-actions .mat-paginator-range-label {
    margin-left: 0px;
    flex: auto;
}

#lov-container .mat-table .selected {
    background-color: #8f2a2a;
}

#lov-container .mat-table .selected .mat-cell {
    color: white;
}