#renewal {
    padding: 60px 0;
}

#renewal .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

#renewal .container .case {
    position: relative;
    background-color: #fff;
    width: 100%;
    max-width: 650px;
    border-radius: 5px;
    padding: 30px 47px;
    box-sizing: border-box;
    border: 1px solid #E0E0E0;
    border-top: none;
    min-height: 407px;
}

#renewal .case h2 {
    font-size: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 28px;
    margin: 10px 0 25px;
    color: #1B1464;
}

#renewal .form {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
}

#renewal .form .col-case {
    margin-bottom: 16px;
}

#renewal .container .case .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: #F2F2F2;
}

#renewal form .form-btns {
    margin-bottom: 0;
}

#renewal .vehicle-label {
    margin-top: 30px !important;
    text-align: center;
}

#renewal .invoice-box {
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 20px;
}

#renewal .invoice-box p {
    margin-bottom: 6px;
    font-family: "Inter", "Arial", "Calibri", sans-serif;
    font-size: 17px;
    font-weight: bold;
}

#renewal .invoice-box p:last-of-type {
    margin-bottom: 12px;
}

#renewal .attach-preview:first-of-type {
    margin-top: 30px;
}

#renewal .attach-preview .preview {
    position: relative;
}

#renewal .attach-preview .preview .delete {
    position: absolute;
    background-color: #fff;
    right: 10px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    font-size: 14px;
    color: #b11116;
    border: 1px solid #b11116;
    transition: background-color ease .3s;
}

#renewal .attach-preview.pdf-name .preview .delete {
    top: 7px;
}

#renewal .attach-preview .preview .delete:hover {
    background-color: #f0f0f0;
}

#renewal .attach-preview.pdf-name {
    width: 100%;
}

#renewal .attach-preview.pdf-name .preview {
    margin-top: 15px;
    background-color: #fff;
    padding: 12px 50px 12px 12px;
    border-radius: 5px;
    box-shadow: 0px 1px 2px rgb(27 20 100 / 24%);
}

#renewal .attach-preview.pdf-name .preview:first-of-type {
    margin-top: 20px;
}

#renewal .attach-preview.pdf-name .preview .pdf-filename {
    font-size: 15px;
    font-family: "Inter", "Arial", "Calibri", sans-serif;
}

#renewal .form-fields .field-error {
    margin-top: 10px;
    color: #b11116;
    font-size: 14px;
}

#renewal .support {
    margin-top: 30px;
    background: linear-gradient(270deg, #B11116 0%, #D5B527 100%);
    border-radius: unset !important;
    padding: 3px !important;
    min-height: unset !important;
}

#renewal .support .support-container {
    background-color: #f2f2f2;
    display: flex;
    padding: 15px;
}

#renewal .support .support-container .info {
    padding-left: 30px;
}

#renewal .support .support-container .info>h2 {
    margin: 5px 0;
    font-size: 24px;
    text-transform: capitalize;
    font-weight: 600;
    color: #000;
}

#renewal .support .support-container .info>p {
    line-height: 19px;
    font-size: 14px;
}

#renewal .support .support-container .info .sub-info {
    margin-top: 15px;
}

#renewal .support .support-container .info .sub-info>p:first-of-type {
    font-size: 14px;
}

#renewal .support .support-container .info .sub-info>p:last-of-type {
    color: #1B1464;
    margin-top: 5px;
    font-size: 15px;
    font-weight: bold;
}

@media screen and (max-width: 874px) {
    #renewal form .form-btns {
        display: block;
        text-align: right;
    }
}

@media screen and (max-width: 640px) {
    #renewal form .form-btns .btn {
        min-width: 144px;
        padding: 13px 10px;
        font-size: 16px;
    }
}

@media screen and (max-width: 582px) {
    #renewal .container .case {
        padding: 30px 25px;
    }
    #renewal .support .support-container {
        flex-direction: column;
        padding: 20px;
        padding-bottom: 0;
    }
    #renewal .support .support-container .info {
        padding-top: 15px;
        padding-left: 0;
        padding-bottom: 20px;
    }
}

@media screen and (max-width: 550px) {
    #renewal form .form-btns {
        text-align: center;
    }
}

@media screen and (max-width: 476px) {
    #renewal .invoice-box .btn {
        width: 48%;
    }
}

@media screen and (max-width: 398px) {
    #renewal .invoice-box p {
        font-size: 16px;
    }
}

@media screen and (max-width: 380px) {
    #renewal .invoice-box p {
        font-size: 15px;
    }
    #renewal .support .support-container {
        padding: 10px;
    }
}

@media screen and (max-width: 360px) {
    #renewal .invoice-box p {
        font-size: 14px;
    }
}

@media screen and (max-width: 345px) {
    #renewal .invoice-box p {
        font-size: 13px;
    }
}

@media screen and (max-width: 321px) {
    #renewal .support .support-container .info {
        padding-top: 10px;
    }
    #renewal .support .support-container .info>h2 {
        font-size: 22px;
    }
    #renewal .support .support-container .info .sub-info {
        margin-top: 20px;
    }
}